import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { ThrottlingUtils } from '@azure/msal-common';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FunctionGroup } from 'src/app/models/functionGroup.model';
import { FunctionGroupView } from 'src/app/models/functionGroupView.model';
import { Language } from 'src/app/models/language.enum';
import { MissingCause } from 'src/app/models/missingCause.model';
import * as Diacritics from 'diacritics';
import { Responsible } from 'src/app/models/responsible.model';

//FUNCTION
interface ElementNode {
  functionGroup:FunctionGroup;
  children?: ElementNode[];
}

/** Flat node with expandable and level information */
interface ElementFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

 @Component({
  selector: 'app-tree-data',
  templateUrl: './tree-data.component.html',
  styleUrls: ['./tree-data.component.less']
})

export class TreeDataComponent {
  @Input() functionsList?: FunctionGroupView[];
  @Input() currentSelectedFunction?: FunctionGroupView;
  @Input() missingCauseList?: MissingCause[];
  @Input() currentSelectedMissingCause?: MissingCause;
  @Input() responsiblesList?: Responsible[];
  @Input() currentSelectedResponsible?: Responsible;
  @Input() temporaryPlaceholder?: string;

  @Input() isDisabled?: boolean;
  @Output() selectEvent = new EventEmitter<any>();

  treeList: ElementNode[] = [];
  originalTreeList: ElementNode[] = [];
  selectedFunction?:FunctionGroupView | null;
  selectedMissingCause?:MissingCause | null;
  selectedResponsible?:Responsible | null;
  filterInputText:string="";
  private filterChangedSubject = new Subject();

  isFilterInputFocus:boolean = false;
  isBlurCanceled:boolean = false;
  //isBlurDisable:boolean = false;

  isSettingsOpen:boolean = false;
  selectedLevelOption:number = 4;
  showCode:boolean = true;

  //warning
  warningFieldNotVoid:boolean = false;
  @Output() fieldNotVoidEvent = new EventEmitter<any>();

  private _transformer = (node: ElementNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.functionGroup.id + "/" + node.functionGroup.code + " " + this.getDisplayName(node.functionGroup),
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ElementFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor( private translate: TranslateService) {
    this.dataSource.data = [];
  }

  ngOnInit() {
    this.selectedLevelOption = Number(localStorage.getItem("chosenFunctionLevel")) || 4;
    this.showCode = localStorage.getItem("showTreeCode") != null ? localStorage.getItem("showTreeCode") === "true": true;
    this.filterChangedSubject.pipe(debounceTime(500)).subscribe((event:any) => {
      this.filter((event.target as HTMLInputElement).value);
    
      if ((event.target as HTMLInputElement).value){
        this.isSettingsOpen = false;
        this.expandTreeWithLevel((event.target as HTMLInputElement).value);
      }else{
        this.treeControl.collapseAll();
      }

      this.warningFieldNotVoid = this.filterInputText !== "";
      this.fieldNotVoidEvent.emit(this.warningFieldNotVoid);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['functionsList'] != null){
      this.functionsList = changes['functionsList'].currentValue;
      this.setTreeList(this.functionsList!);
      this.originalTreeList = this.treeList;
    }
    if(changes['missingCauseList'] != null){
      this.missingCauseList = changes['missingCauseList'].currentValue;
      this.setTreeList(this.missingCauseList!);
      this.originalTreeList = this.treeList;
    }
    if(changes['responsiblesList'] != null){
      this.responsiblesList = changes['responsiblesList'].currentValue;
      this.setTreeList(this.responsiblesList!);
      this.originalTreeList = this.treeList;
    }
    if(changes['currentSelectedFunction'] != null){
      this.selectedFunction = changes['currentSelectedFunction'].currentValue;
    }
    if(changes['currentSelectedMissingCause'] != null){
      this.selectedMissingCause = changes['currentSelectedMissingCause'].currentValue;
    }
    if(changes['currentSelectedResponsible'] != null){
      this.selectedResponsible = changes['currentSelectedResponsible'].currentValue;
    }
  }

  hasChild = (_: number, node: ElementFlatNode) => node.expandable;

  //Start setTreeList decomposition
  setTreeList(elementList: any[]) {
    if (!elementList) { return; }
  
    // Sort and initialize
    elementList = this.sortElementList(elementList);
    this.treeList = [];
  
    // Determine the type of processing
    if (this.functionsList) {
      this.buildFunctionTree(elementList);
    } else if (this.missingCauseList || this.responsiblesList) {
      this.buildCauseOrResponsibleTree(elementList);
    }
  
    this.dataSource.data = this.treeList;
  }
  
  private buildFunctionTree(elementList: any[]) {
    elementList.forEach(f => {
      const flevel = this.getFunctionLevel(f);
      if(f.parentId == null){
        this.treeList.push({ functionGroup: f, children: []});
      }
      else if(flevel?.level === 2){
        const found:ElementNode = this.treeList.find(x=>x.functionGroup.id === f.parentId)!;
        this.addToTreeList(found, f);
      }
      else if(flevel?.level === 3){
        const found:ElementNode =  this.treeList.find(x=> x.functionGroup.id === flevel?.greatparent?.id)?.children?.find(x=>x.functionGroup.id === f.parentId)!;
        this.addToTreeList(found, f);
      }
      else if(flevel?.level === 4){
        const found:ElementNode =  this.treeList.find(x=> x.functionGroup.id === flevel?.greatgreatparent?.id)?.children?.find(x=>x.functionGroup.id === flevel?.greatparent?.id)?.children?.find(x=>x.functionGroup.id === f.parentId)!;
        this.addToTreeList(found, f);
      }
    });
  }
  
  private buildCauseOrResponsibleTree(elementList: any[]) {
    elementList.forEach(f => {
      if((f.level3 === "" || f.level3 == null) && (f.level2 !== "" && f.level2 != null)){
          this.treeList.push({ functionGroup: f, children: []});
      }
      else if(f.level3 !== "" && f.level3 != null){
        const found:ElementNode = this.treeList.find(x=>x.functionGroup.level2 === f.level2)!;
        this.addToTreeList(found, f);
      } 
    });
  }

  private addToTreeList(elementParent:ElementNode | null, element:any){
    if(elementParent != null){
      elementParent.children?.push({ functionGroup: element, children: []});
    }
    else{
      this.treeList.push({ functionGroup: element, children: []});
    } 
  }
  //End setTreeList decomposition  

  sortElementList(items:any[]) {
    // Create a map where keys are the parentId and values are arrays of items
    const map = new Map();
    const allIds = new Set(items.map(item => item.id));
  
    items.forEach(item => {
      const parentId = item.parentId || null;
      if (!map.has(parentId)) {
        map.set(parentId, []);
      }
      map.get(parentId).push(item);
    });
  
    // Find root elements (those that have a parentId but whose parent doesn't exist)
    const roots = items.filter(item => !allIds.has(item.parentId));

    roots.sort((a: any, b: any) => {
      if (a.code === b.code) {
          return a.id - b.id; // Sort by id if codes are equal
      }
      return a.code - b.code; // Sort by code
    });

    // Recursive function to sort and flatten the hierarchy
    function addChildren(parentId:any) {
      const children = map.get(parentId) || [];
      children.sort((a:any, b:any) => {
        if (a.code === b.code) {
          return a.id - b.id;  // Sort by id if codes are equal
        }
        return a.code - b.code;  // Sort by code
      });
      return children.reduce((acc:any, child:any) => {
        return acc.concat(child, addChildren(child.id));
      }, []);
    }
  
    // Start with root elements
    return roots.reduce((acc, root) => acc.concat(root, addChildren(root.id)), []);
  }

  getFunctionLevel(f:FunctionGroupView){
    if(f == null){ return;}

    if(f.parentId == null){
      return {level:1, parent:null, greatparent:null, greatgreatparent:null};
    }
    else {
      const parent = this.functionsList?.find(x=>x.id === f.parentId);
      if(parent?.parentId == null){
        return {level:2, parent:parent, greatparent:null, greatgreatparent:null};
      }
      else{
        const greatparent = this.functionsList?.find(w => w.id === parent?.parentId);
        if(greatparent?.parentId == null){
          return {level:3, parent:parent, greatparent:greatparent, greatgreatparent:null};
        }
        else{
          const greatgreatparent = this.functionsList?.find(v => v.id === greatparent?.parentId);
          if(greatgreatparent?.parentId == null){
            return {level:4, parent:parent, greatparent:greatparent, greatgreatparent:greatgreatparent};
          }
        }
      }
    }
        
    return;
  }

  //LANGUAGE SETTING
  getDisplayName(f: any): string {
    if (f == null) {
      return "";
    }

    if (this.functionsList) {
        return f.name?.toString() || "";
    }

    if (this.missingCauseList || this.responsiblesList) {
        const languageMapping: { [key: string]: string | undefined } = {
            "fr": f.nameFR,
            "nl": f.nameNL,
            "en": f.nameEN
        };

        return languageMapping[this.translate.currentLang] || "";
    }

    return "";
}

  getDisplayNameWithCode(f:any):string{
    if(f == null){ return "";}

    if(f.code != null){
      return f.code + " " + this.getDisplayName(f);
    }
    else{
      return this.getDisplayName(f);
    }
  }

  selectNode(node:ElementFlatNode){
    if(node == null){ return;}

    const id = node.name.split("/", 1)[0];

    if(this.functionsList != null){
      this.selectedFunction = this.functionsList?.find(x=>x.id === Number(id));
      this.hideTree(); 
      this.resetFilter();
      this.selectEvent.emit(this.selectedFunction?.id);
    } 
    else if (this.missingCauseList != null){
      this.selectedMissingCause = this.missingCauseList?.find(x=>x.id === Number(id));
      this.hideTree(); 
      this.resetFilter();
      this.selectEvent.emit(this.selectedMissingCause?.id);
    }
    else if (this.responsiblesList != null){
      this.selectedResponsible = this.responsiblesList?.find(x=>x.id === Number(id));
      this.hideTree(); 
      this.resetFilter();
      this.selectEvent.emit(this.selectedResponsible?.id);
    }

    this.warningFieldNotVoid = false;
    this.fieldNotVoidEvent.emit(this.warningFieldNotVoid);
    
  }

  filterChanged(event:any) {
    if(this.filterInputText.length !== 1){
      this.filterChangedSubject.next(event);
    }
  }

  //Start filter tree list function
  filter(filterText:string){
    this.reinitializeTreeList();

    if(filterText === ""){
      return;
    }

    const filteredFunctionList = this.getFilteredFunctionList(filterText);

    this.setTreeList(filteredFunctionList);
  }

  private reinitializeTreeList() {
    if (this.functionsList) {
      this.setTreeList(this.functionsList);
    } else if (this.missingCauseList) {
      this.setTreeList(this.missingCauseList);
    } else if (this.responsiblesList) {
      this.setTreeList(this.responsiblesList);
    }
  }

  private getFilteredFunctionList(filterText: string): FunctionGroup[] {
    const filteredFunctionList: FunctionGroup[] = [];
  
    this.treeList.forEach(node => {
      this.collectFilteredNodes(node, filterText, filteredFunctionList);
    });
  
    return filteredFunctionList;
  }

  private collectFilteredNodes(node: any, filterText: string, filteredFunctionList: FunctionGroup[]) {
    if (this.isNodeNameInFilter(node, filterText)) {
      filteredFunctionList.push(...this.getAllChildren(node, true));
    } else {
      if (this.isInFilter(node, filterText)) {
        filteredFunctionList.push(node.functionGroup);
      }
  
      node.children?.forEach((child:any) => {
        this.collectFilteredNodes(child, filterText, filteredFunctionList);
      });
    }
  }
  //End filter tree list function

  getAllChildren(node:ElementNode, addNode:boolean = false):FunctionGroup[]{
    const allChildrenList: FunctionGroup[] = [];

    if(addNode){
      allChildrenList.push(node.functionGroup);
    }
    
    node.children?.forEach(node2 => {
      allChildrenList.push(node2.functionGroup);
      node2.children?.forEach(node3 => {
        allChildrenList.push(node3.functionGroup);
        node3.children?.forEach(node4 => {
          allChildrenList.push(node4.functionGroup);
        });
      });
    });

    return allChildrenList;
  }

  isNodeNameInFilter(node:ElementNode, filterText:string){
    const nodeName:string = node.functionGroup.code + " " + this.getDisplayName(node.functionGroup);

    if(this.notSensitive(nodeName).includes(this.notSensitive(filterText))){
      return true;
    }
    else{
      return false;
    }
  }

  isInFilter(node:ElementNode, filterText:string){
    const nodeName:string = node.functionGroup.code + " " + this.getDisplayName(node.functionGroup);

    if(this.notSensitive(nodeName).includes(this.notSensitive(filterText))){
      return true;
    }
    else{
      if(node.children?.length === 0){
        return false;
      }
      else{
        let isChildrenDisplayed:boolean = false;
        node.children?.forEach(child => {
          if(this.isInFilter(child, filterText)){
            isChildrenDisplayed = true;
          }
        });
        return isChildrenDisplayed;
      }
    }
  }

  notSensitive(s:string | null | undefined){
    if(s==null){ return "";}
    return Diacritics.remove(s).toLowerCase();
  }

  onFocus(){
    this.isFilterInputFocus = true;
  }

  stopFocus(event:FocusEvent){
    if(this.isBlurCanceled){
      this.isFilterInputFocus = true;
      this.isBlurCanceled = false;
      //add focus again to input search
    }
    else{
      this.isFilterInputFocus = false;
    }
  }

  //close tree when click outside component
  inside = false;
  @HostListener("click")
  clicked() {
    this.inside = true;
  }
  @HostListener("document:click") clickedOut() {
    if(!this.inside){
      this.isFilterInputFocus = false;
    }
    this.inside = false;
  }

  cancelBlur(){
    this.isBlurCanceled = true;
  } 
  
  getSelectedFunctionName(){
    if(this.selectedFunction != null){
      return this.selectedFunction?.code + ' ' + this.getDisplayName(this.selectedFunction!);
    }
    else{
      return "";
    }
  }

  //Start getSelectedFullPath() decomposition
  getSelectedFullPath() {
    if (this.selectedFunction) {
      return this.buildFunctionPath(this.selectedFunction);
    } else if (this.selectedMissingCause) {
      return this.buildMissingCausePath(this.selectedMissingCause);
    } else if (this.selectedResponsible) {
      return this.buildResponsiblePath(this.selectedResponsible);
    } else if (this.temporaryPlaceholder) {
      return this.temporaryPlaceholder;
    }
    return "";
  }

  private buildFunctionPath(selectedFunction: any): string {
    const flevel = this.getFunctionLevel(selectedFunction);
  
    return [
      flevel?.greatgreatparent?.name,
      flevel?.greatparent?.name,
      flevel?.parent?.name,
      selectedFunction.name,
    ]
      .filter(name => name != null)
      .join(" > ");
  }

  private buildMissingCausePath(selectedMissingCause: any): string {
    const level2 = this.findMissingCauseObject(selectedMissingCause.level2, 2);
    const level3 = this.findMissingCauseObject(selectedMissingCause.level3, 3);
  
    return this.buildPathWithLevels(level2, level3);
  }
  
  private buildResponsiblePath(selectedResponsible: any): string {
    const level2 = this.findResponsibleObject(selectedResponsible.level2 || "", 2);
    const level3 = this.findResponsibleObject(selectedResponsible.level3 || "", 3);
  
    return this.buildPathWithLevels(level2, level3);
  }
  
  private buildPathWithLevels(level2: any, level3: any): string {
    return [
      this.getDisplayName(level2),
      this.getDisplayName(level3),
    ]
      .filter(displayName => displayName !== "")
      .join(" > ")
      .replace(/^ >+/, "")
      .trim();
  }
  //End getSelectedFullPath() decomposition

  findMissingCauseObject(name:string, level:number): MissingCause | null{
    if(name == null || level == null || name === ""){ return null;}

    if(level === 1){
      return this.missingCauseList?.find(x => x.level1 === name && x.code === name.split(' ', 2)[0])!;}
    else if(level === 2){
      return this.missingCauseList?.find(x => x.level2 === name && x.code === name.split(' ', 2)[0])!;}
    else if(level === 3){
      return this.missingCauseList?.find(x => x.level3 === name && x.code === name.split(' ', 2)[0])!;}
    else{
      return null;}
  }

  findResponsibleObject(name:string, level:number): Responsible | null{
    if(name == null || level == null || name === ""){ return null;}

    if(level === 1){
      return this.responsiblesList?.find(x => x.level1 === name && x.code === name.split(' ', 2)[0])!;}
    else if(level === 2){
      return this.responsiblesList?.find(x => x.level2 === name && x.code === name.split(' ', 2)[0])!;}
    else if(level === 3){
      return this.responsiblesList?.find(x => x.level3 === name && x.code === name.split(' ', 2)[0])!;}
    else{
      return null;}
  }

  clear(){
    if(this.filterInputText !== ""){
      this.resetFilter();
      this.warningFieldNotVoid = false;
      this.fieldNotVoidEvent.emit(this.warningFieldNotVoid);
    } else{
      this.clearSelection();
    }
  }

  resetFilter(){
    this.filterInputText = "";
    this.dataSource.data = this.originalTreeList;
  }

  clearSelection(){
    this.selectedFunction = null;
    this.selectedMissingCause = null;
    this.selectedResponsible = null;
    this.temporaryPlaceholder = "";
    this.selectEvent.emit(null);
  }

  hideTree(){
    this.isFilterInputFocus = false;
  }

  //Start isSelectedPath function
  isSelectedPath(node:any):boolean{
    if(!this.isSelectionValid()){ return false;}
  
    let isSelectedPath = false;
    const nodeId = Number(node.name.split('/', 2)[0]);
  
    if(this.functionsList != null){
      isSelectedPath = this.isSelectedPathForFunction(node, nodeId);
    } else if(this.missingCauseList != null){
      isSelectedPath = this.isSelectedPathForMissingCause(node, nodeId);
    } else if(this.responsiblesList != null){
      isSelectedPath = this.isSelectedPathForResponsible(node, nodeId);
    }
  
    this.expandNodeIfSelected(node, nodeId, isSelectedPath);
      
    return isSelectedPath;
  }
  private isSelectionValid(): boolean {
    return !(
      (this.selectedFunction == null && this.selectedMissingCause == null && this.selectedResponsible == null) || 
      (this.functionsList == null && this.missingCauseList == null && this.responsiblesList == null)
    );
  }
  private isSelectedPathForFunction(node: any, nodeId: number): boolean {
    const flevel = this.getFunctionLevel(this.selectedFunction!);
    if (!flevel) { return false; }
    
    switch (flevel.level) {
      case 1:
        return node.level === 0 && this.selectedFunction?.id === nodeId;
      case 2:
        return this.isSelectedPathForFunctionLevel2(node, nodeId);
      case 3:
        return this.isSelectedPathForFunctionLevel3(node, nodeId, flevel);
      case 4:
        return this.isSelectedPathForFunctionLevel4(node, nodeId, flevel);
      default:
        return false;
    }
  }
  private isSelectedPathForFunctionLevel2(node: any, nodeId: number){
    return ((node.level === 0 && this.selectedFunction?.parentId === nodeId) || (node.level === 1 && this.selectedFunction?.id === nodeId));
  }
  private isSelectedPathForFunctionLevel3(node: any, nodeId: number, flevel:any){
    return (
      (node.level === 0 && flevel?.greatparent?.id === nodeId) ||
      (node.level === 1 && this.selectedFunction?.parentId === nodeId) ||
      (node.level === 2 && this.selectedFunction?.id === nodeId)
    );
  }
  private isSelectedPathForFunctionLevel4(node: any, nodeId: number, flevel:any){
    return (
      (node.level === 0 && flevel?.greatgreatparent?.id === nodeId) ||
      (node.level === 1 && flevel?.greatparent?.id === nodeId) ||
      (node.level === 2 && this.selectedFunction?.parentId === nodeId) ||
      (node.level === 3 && this.selectedFunction?.id === nodeId)
    );
  }
  private isSelectedPathForMissingCause(node: any, nodeId: number): boolean {
    if(node.level === 0){
      return this.missingCauseList?.find(x=> x.level2 === this.selectedMissingCause?.level2)?.id === nodeId; 
    }else if(node.level === 1){
      return this.missingCauseList?.find(x=> x.level3 === this.selectedMissingCause?.level3)?.id === nodeId;
    }
    return false;
  } 
  private isSelectedPathForResponsible(node: any, nodeId: number): boolean {
    if(node.level === 0){
      return this.responsiblesList?.find(x=> x.level2 === this.selectedResponsible?.level2)?.id === nodeId; 
    }else if(node.level === 1){
      return this.responsiblesList?.find(x=> x.level3 === this.selectedResponsible?.level3)?.id === nodeId;
    }
    return false;
  }
  private expandNodeIfSelected(node: any, nodeId: number, isSelectedPath: boolean) {
    if (
      nodeId === this.selectedFunction?.id ||
      nodeId === this.selectedMissingCause?.id ||
      nodeId === this.selectedResponsible?.id
    ) {
      this.treeControl.expandDescendants(node);
    } else if (isSelectedPath) {
      this.treeControl.expand(node);
    }
  }
  //End isSelectedPath function

  //SETTINGS
  changeOptionLevel(event:any){
    this.selectedLevelOption = event.value;
    localStorage.setItem("chosenFunctionLevel", event.value);
  }

  toggleShowCode(event:any){
    this.showCode = event.checked;
    localStorage.setItem("showTreeCode", event.checked);
  }

  getParentNode(node: ElementFlatNode): ElementFlatNode | null {
    const currentLevel = this.treeControl.getLevel(node);
    if (currentLevel < 1) {
      return null; // No parent for root nodes
    }
  
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
  
    for (let i = startIndex; i >= 0; i--) {
      const potentialParent = this.treeControl.dataNodes[i];
      if (this.treeControl.getLevel(potentialParent) < currentLevel) {
        return potentialParent;
      }
    }
  
    return null; // Return null if no parent is found
  }
  
  expandTreeWithLevel(filterText:string){
    const nodes:ElementFlatNode[] = this.treeControl.dataNodes;
    this.treeControl.collapseAll();
    nodes.forEach(node => {
      //if the node is in the filter
      if(this.notSensitive(node.name).includes(this.notSensitive(filterText))){
        // Expand parent until root
        let parentNode:ElementFlatNode | null = this.getParentNode(node);
        while (parentNode) {
          this.treeControl.expand(parentNode);
          parentNode = this.getParentNode(parentNode);
        }
        // Expand node and its children if level is lower than selected level
        const nodesToExpand:ElementFlatNode[] = [ node ].concat(this.treeControl.getDescendants(node));
        nodesToExpand.forEach(n => {
          if (this.treeControl.getLevel(n) < this.selectedLevelOption - 1) {
            this.treeControl.expand(n);
          }
        });
      }
    });
  }

  nodeNameWithoutCode(name:string){
    return name.replace(/^\S+\s*/, '');
  }

}