<div class="page-container" [ngClass]="{'no-product-list' :!isProductListOpen}">
<div class="not-autorized" *ngIf="roleAuth != null && !roleAuth?.readDeviation">
    <div class="message">
        <span>{{"NO ACCESS RIGHTS MESSAGE" | translate}}</span>
    </div> 
</div>
<div class="close-btn" (click)="toggleProductList()" [ngClass]="{'opens' :!isProductListOpen}" *ngIf="(roleAuth == null || roleAuth?.readDeviation)">
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7199 0.862854C15.2502 0.862854 15.6799 1.29263 15.6799 1.82285C15.6799 2.35308 15.2502 2.78285 14.7199 2.78285H1.27995C0.749718 2.78285 0.319946 2.35308 0.319946 1.82285C0.319946 1.29263 0.749718 0.862854 1.27995 0.862854H14.7199ZM14.7199 6.04685C15.2502 6.04685 15.6799 6.47663 15.6799 7.00685C15.6799 7.53708 15.2502 7.96685 14.7199 7.96685H1.27995C0.749718 7.96685 0.319946 7.53708 0.319946 7.00685C0.319946 6.47663 0.749718 6.04685 1.27995 6.04685H14.7199ZM14.7199 11.2309C15.2502 11.2309 15.6799 11.6606 15.6799 12.1909C15.6799 12.7211 15.2502 13.1509 14.7199 13.1509H1.27995C0.749718 13.1509 0.319946 12.7211 0.319946 12.1909C0.319946 11.6606 0.749718 11.2309 1.27995 11.2309H14.7199Z" fill="#333333"/>
    </svg>
</div>
<div class="search-btn" (click)="toggleSearch()" *ngIf="roleAuth == null || roleAuth?.readDeviation" [ngClass]="{'active' :!isProductListOpen}">
    <div class="search-container">
        <input #searchProductListClose tabindex="0" id="search" type="text" [(ngModel)]="searchText" name="search" autocomplete="off" placeholder="{{'SCAN OR SEARCH' | translate}}" (focus)="searchIsFocus = true" (blur)="searchIsFocus = false">
        <svg width="17" height="17" viewBox="0 0 107 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.39551 33.1314C4.39551 28.6855 6.16162 24.4217 9.30532 21.278C12.449 18.1343 16.7128 16.3682 21.1587 16.3682H61.8692V49.8945H21.1587C16.7128 49.8945 12.449 48.1284 9.30532 44.9847C6.16162 41.841 4.39551 37.5772 4.39551 33.1314V33.1314Z" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M16.3684 49.8945L2 76.2366L13.9258 84.7619C14.537 85.1943 15.2319 85.4939 15.9658 85.6415C16.6998 85.7892 17.4565 85.7815 18.1873 85.6191C18.9182 85.4567 19.6069 85.1431 20.2092 84.6985C20.8116 84.254 21.3142 83.6882 21.6847 83.0377L40.3158 49.8945" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M52.2899 49.8945V59.7129C52.2292 62.1708 51.2258 64.5112 49.4872 66.2497C47.7487 67.9882 45.4083 68.9917 42.9505 69.0524H30.7373" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M81.0264 69.0522H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 83.4209H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 93H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 59.4736H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 45.1055H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 35.5264H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 25.9478H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 11.5791H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 2H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</div>
<app-barcode-scanner *ngIf="isCameraScanOpen" (closeEvent)="closeBarcodeScanner($event)"></app-barcode-scanner>
<div class="list-container" *ngIf="roleAuth == null || roleAuth?.readDeviation" [ngClass]="{'active' :isProductListOpen}">
    <div class="title-container" cdkTrapFocus>
        <span class="title">{{ 'PRODUCTS' | translate }}
            <app-demo-button type="btn-icon" svg="barcode_scanner" color="reverse-grey" class="barcodescanner-btn" (clickEvent)="isCameraScanOpen = true"></app-demo-button>
        </span>
        <div class="search-container">
            <input #searchProductListOpen tabindex="0" id="search" type="text" [(ngModel)]="searchText" name="search" autocomplete="off" placeholder="{{'SCAN OR SEARCH' | translate}}" (focus)="searchIsFocus = true" (blur)="searchIsFocus = false">
            <svg width="17" height="17" viewBox="0 0 107 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.39551 33.1314C4.39551 28.6855 6.16162 24.4217 9.30532 21.278C12.449 18.1343 16.7128 16.3682 21.1587 16.3682H61.8692V49.8945H21.1587C16.7128 49.8945 12.449 48.1284 9.30532 44.9847C6.16162 41.841 4.39551 37.5772 4.39551 33.1314V33.1314Z" stroke="black" stroke-width="4" stroke-linejoin="round"/>
                <path d="M16.3684 49.8945L2 76.2366L13.9258 84.7619C14.537 85.1943 15.2319 85.4939 15.9658 85.6415C16.6998 85.7892 17.4565 85.7815 18.1873 85.6191C18.9182 85.4567 19.6069 85.1431 20.2092 84.6985C20.8116 84.254 21.3142 83.6882 21.6847 83.0377L40.3158 49.8945" stroke="black" stroke-width="4" stroke-linejoin="round"/>
                <path d="M52.2899 49.8945V59.7129C52.2292 62.1708 51.2258 64.5112 49.4872 66.2497C47.7487 67.9882 45.4083 68.9917 42.9505 69.0524H30.7373" stroke="black" stroke-width="4" stroke-linejoin="round"/>
                <path d="M81.0264 69.0522H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M81.0264 83.4209H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M81.0264 93H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M81.0264 59.4736H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M81.0264 45.1055H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M81.0264 35.5264H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M81.0264 25.9478H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M81.0264 11.5791H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M81.0264 2H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>

    <div class="sorting">
        <span (click)="sortBy('latest')" (keyup.enter)="sortBy('latest')" [ngClass]="{'active' :filter === 'latest'}" tabindex="0">{{chosenCheckpoint?.name}} {{ 'PLANNING' | translate }}</span>
        <span (click)="sortBy('status')" (keyup.enter)="sortBy('status')" [ngClass]="{'active' :filter === 'status'}" tabindex="0">{{ 'STATUS' | translate }}</span>
        <span (click)="sortBy('AZ')" (keyup.enter)="sortBy('AZ')" [ngClass]="{'active' :filter === 'AZ'}" tabindex="0">{{ 'A-Z' | translate }}</span>
    </div>

    <div class="line-sorting-legend">
        <div class="counter-container" *ngIf="chosenCheckpointData != null">
            <div class="counter">
                <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.9931 0.777145C4.9051 0.777145 0.7771 4.90515 0.7771 9.99314C0.7771 15.0811 4.9051 19.2091 9.9931 19.2091C15.0811 19.2091 19.2091 15.0811 19.2091 9.99314C19.2091 4.90515 15.0811 0.777145 9.9931 0.777145ZM14.7355 7.76595L9.3211 13.1995C9.14833 13.3723 8.8987 13.4875 8.64903 13.4875C8.39936 13.4875 8.14987 13.3915 7.97696 13.1995L5.2507 10.4731C4.86673 10.0892 4.86673 9.49398 5.2507 9.10988C5.63467 8.72591 6.22987 8.72591 6.61397 9.10988L8.64913 11.145L13.3915 6.40264C13.7755 6.01868 14.3707 6.01868 14.7548 6.40264C15.1195 6.78675 15.1195 7.38191 14.7355 7.76591L14.7355 7.76595Z" fill="#00BA5E"/>
                </svg>
                <span>{{"OK" | translate}}: {{chosenCheckpointData.checkOkCount}}</span>
            </div>
            <div class="counter">
                <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99354 0.468567C4.7324 0.468567 0.468628 4.73234 0.468628 9.99348C0.468628 15.2546 4.7324 19.5184 9.99354 19.5184C15.2547 19.5184 19.5185 15.2546 19.5185 9.99348C19.5185 4.73234 15.2547 0.468567 9.99354 0.468567ZM14.4785 13.1286C14.8497 13.4998 14.8497 14.1036 14.4785 14.4748C14.0997 14.8536 13.646 14.9886 13.1322 14.4748L9.99344 11.3398L6.85842 14.4748C6.42341 14.9098 5.79344 14.7561 5.51216 14.4748C5.14091 14.1036 5.14091 13.4998 5.51216 13.1286L8.64718 9.99355L5.51216 6.85853C5.14091 6.48729 5.14091 5.88352 5.51216 5.51227C5.88341 5.14102 6.48718 5.14102 6.85842 5.51227L9.99344 8.64729L13.1285 5.51227C13.4997 5.14102 14.1035 5.14102 14.4747 5.51227C14.846 5.88352 14.846 6.48729 14.4747 6.85853L11.3397 9.99355L14.4785 13.1286Z" fill="#EA2C1A"/>
                </svg>
                <span>{{"NOK" | translate}}: {{chosenCheckpointData.checkNokCount}}</span>
            </div>
        </div>
        <span class="legend-title" *ngIf="chosenCheckpoint != null" title="{{'STATUS PRODUCT CHECKPOINT' | translate}} {{chosenCheckpoint.name}}">{{chosenCheckpoint.name}}</span>       
        <span class="legend-title" *ngIf="chosenCheckpoint != null" title="{{'STATUS GLOBAL' | translate}}">Global</span>
    </div>

    <span *ngIf="errorMessage != ''" class="error-message">{{errorMessage | translate}}</span>

    <div class="list-vi" *skeleton="products == null || isLoadingProducts; repeat: 10; height: '20px'; width: 'calc(100% - 20px)'; margin:'10px'" tabindex="0" id="list-vi">
        <div class="vi active" *ngIf="selectedProductViewNotInList != null && notSensitive(getProductFullName(selectedProductViewNotInList))?.includes(notSensitive(searchText))">
            <span>{{getProductFullName(selectedProductViewNotInList)}}</span>
            <div class="product-states">
                <svg [ngClass]="{'allfixed' : selectedProductViewNotInList.checkState == true}" class="chosen-checkpoint" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.9931 0.777145C4.9051 0.777145 0.7771 4.90515 0.7771 9.99314C0.7771 15.0811 4.9051 19.2091 9.9931 19.2091C15.0811 19.2091 19.2091 15.0811 19.2091 9.99314C19.2091 4.90515 15.0811 0.777145 9.9931 0.777145ZM14.7355 7.76595L9.3211 13.1995C9.14833 13.3723 8.8987 13.4875 8.64903 13.4875C8.39936 13.4875 8.14987 13.3915 7.97696 13.1995L5.2507 10.4731C4.86673 10.0892 4.86673 9.49398 5.2507 9.10988C5.63467 8.72591 6.22987 8.72591 6.61397 9.10988L8.64913 11.145L13.3915 6.40264C13.7755 6.01868 14.3707 6.01868 14.7548 6.40264C15.1195 6.78675 15.1195 7.38191 14.7355 7.76591L14.7355 7.76595Z" fill="#00BA5E"/></svg>
                <svg [ngClass]="{'deviation' : selectedProductViewNotInList.checkState == false}" class="chosen-checkpoint" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99354 0.468567C4.7324 0.468567 0.468628 4.73234 0.468628 9.99348C0.468628 15.2546 4.7324 19.5184 9.99354 19.5184C15.2547 19.5184 19.5185 15.2546 19.5185 9.99348C19.5185 4.73234 15.2547 0.468567 9.99354 0.468567ZM14.4785 13.1286C14.8497 13.4998 14.8497 14.1036 14.4785 14.4748C14.0997 14.8536 13.646 14.9886 13.1322 14.4748L9.99344 11.3398L6.85842 14.4748C6.42341 14.9098 5.79344 14.7561 5.51216 14.4748C5.14091 14.1036 5.14091 13.4998 5.51216 13.1286L8.64718 9.99355L5.51216 6.85853C5.14091 6.48729 5.14091 5.88352 5.51216 5.51227C5.88341 5.14102 6.48718 5.14102 6.85842 5.51227L9.99344 8.64729L13.1285 5.51227C13.4997 5.14102 14.1035 5.14102 14.4747 5.51227C14.846 5.88352 14.846 6.48729 14.4747 6.85853L11.3397 9.99355L14.4785 13.1286Z" fill="#EA2C1A"/></svg>
                <svg [ngClass]="{'allfixed' :!selectedProductViewNotInList.hasOpenDeviations}" class="global" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.9931 0.777145C4.9051 0.777145 0.7771 4.90515 0.7771 9.99314C0.7771 15.0811 4.9051 19.2091 9.9931 19.2091C15.0811 19.2091 19.2091 15.0811 19.2091 9.99314C19.2091 4.90515 15.0811 0.777145 9.9931 0.777145ZM14.7355 7.76595L9.3211 13.1995C9.14833 13.3723 8.8987 13.4875 8.64903 13.4875C8.39936 13.4875 8.14987 13.3915 7.97696 13.1995L5.2507 10.4731C4.86673 10.0892 4.86673 9.49398 5.2507 9.10988C5.63467 8.72591 6.22987 8.72591 6.61397 9.10988L8.64913 11.145L13.3915 6.40264C13.7755 6.01868 14.3707 6.01868 14.7548 6.40264C15.1195 6.78675 15.1195 7.38191 14.7355 7.76591L14.7355 7.76595Z" fill="#00BA5E"/></svg>
                <svg [ngClass]="{'deviation' :selectedProductViewNotInList.hasOpenDeviations}" class="global" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99354 0.468567C4.7324 0.468567 0.468628 4.73234 0.468628 9.99348C0.468628 15.2546 4.7324 19.5184 9.99354 19.5184C15.2547 19.5184 19.5185 15.2546 19.5185 9.99348C19.5185 4.73234 15.2547 0.468567 9.99354 0.468567ZM14.4785 13.1286C14.8497 13.4998 14.8497 14.1036 14.4785 14.4748C14.0997 14.8536 13.646 14.9886 13.1322 14.4748L9.99344 11.3398L6.85842 14.4748C6.42341 14.9098 5.79344 14.7561 5.51216 14.4748C5.14091 14.1036 5.14091 13.4998 5.51216 13.1286L8.64718 9.99355L5.51216 6.85853C5.14091 6.48729 5.14091 5.88352 5.51216 5.51227C5.88341 5.14102 6.48718 5.14102 6.85842 5.51227L9.99344 8.64729L13.1285 5.51227C13.4997 5.14102 14.1035 5.14102 14.4747 5.51227C14.846 5.88352 14.846 6.48729 14.4747 6.85853L11.3397 9.99355L14.4785 13.1286Z" fill="#EA2C1A"/></svg>
            </div>
        </div>
        <div *ngFor="let p of filteredProducts()" id="vi-{{getProductFullName(p)}}">
            <div class="vi" [ngClass]="{'active' :getProductFullName(selectedProduct!) === getProductFullName(p)}" (click)="selectProduct(p)">
                <span>{{getProductFullName(p)}}</span>
                <div class="product-states">
                    <svg [ngClass]="{'allfixed' : p.checkState == true}" class="chosen-checkpoint" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.9931 0.777145C4.9051 0.777145 0.7771 4.90515 0.7771 9.99314C0.7771 15.0811 4.9051 19.2091 9.9931 19.2091C15.0811 19.2091 19.2091 15.0811 19.2091 9.99314C19.2091 4.90515 15.0811 0.777145 9.9931 0.777145ZM14.7355 7.76595L9.3211 13.1995C9.14833 13.3723 8.8987 13.4875 8.64903 13.4875C8.39936 13.4875 8.14987 13.3915 7.97696 13.1995L5.2507 10.4731C4.86673 10.0892 4.86673 9.49398 5.2507 9.10988C5.63467 8.72591 6.22987 8.72591 6.61397 9.10988L8.64913 11.145L13.3915 6.40264C13.7755 6.01868 14.3707 6.01868 14.7548 6.40264C15.1195 6.78675 15.1195 7.38191 14.7355 7.76591L14.7355 7.76595Z" fill="#00BA5E"/>
                    </svg>
                    <svg [ngClass]="{'deviation' : p.checkState == false}" class="chosen-checkpoint" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99354 0.468567C4.7324 0.468567 0.468628 4.73234 0.468628 9.99348C0.468628 15.2546 4.7324 19.5184 9.99354 19.5184C15.2547 19.5184 19.5185 15.2546 19.5185 9.99348C19.5185 4.73234 15.2547 0.468567 9.99354 0.468567ZM14.4785 13.1286C14.8497 13.4998 14.8497 14.1036 14.4785 14.4748C14.0997 14.8536 13.646 14.9886 13.1322 14.4748L9.99344 11.3398L6.85842 14.4748C6.42341 14.9098 5.79344 14.7561 5.51216 14.4748C5.14091 14.1036 5.14091 13.4998 5.51216 13.1286L8.64718 9.99355L5.51216 6.85853C5.14091 6.48729 5.14091 5.88352 5.51216 5.51227C5.88341 5.14102 6.48718 5.14102 6.85842 5.51227L9.99344 8.64729L13.1285 5.51227C13.4997 5.14102 14.1035 5.14102 14.4747 5.51227C14.846 5.88352 14.846 6.48729 14.4747 6.85853L11.3397 9.99355L14.4785 13.1286Z" fill="#EA2C1A"/>
                    </svg>

                    <svg [ngClass]="{'allfixed' :!p.hasOpenDeviations}" class="global" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.9931 0.777145C4.9051 0.777145 0.7771 4.90515 0.7771 9.99314C0.7771 15.0811 4.9051 19.2091 9.9931 19.2091C15.0811 19.2091 19.2091 15.0811 19.2091 9.99314C19.2091 4.90515 15.0811 0.777145 9.9931 0.777145ZM14.7355 7.76595L9.3211 13.1995C9.14833 13.3723 8.8987 13.4875 8.64903 13.4875C8.39936 13.4875 8.14987 13.3915 7.97696 13.1995L5.2507 10.4731C4.86673 10.0892 4.86673 9.49398 5.2507 9.10988C5.63467 8.72591 6.22987 8.72591 6.61397 9.10988L8.64913 11.145L13.3915 6.40264C13.7755 6.01868 14.3707 6.01868 14.7548 6.40264C15.1195 6.78675 15.1195 7.38191 14.7355 7.76591L14.7355 7.76595Z" fill="#00BA5E"/>
                    </svg>
                    <svg [ngClass]="{'deviation' :p.hasOpenDeviations}" class="global" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99354 0.468567C4.7324 0.468567 0.468628 4.73234 0.468628 9.99348C0.468628 15.2546 4.7324 19.5184 9.99354 19.5184C15.2547 19.5184 19.5185 15.2546 19.5185 9.99348C19.5185 4.73234 15.2547 0.468567 9.99354 0.468567ZM14.4785 13.1286C14.8497 13.4998 14.8497 14.1036 14.4785 14.4748C14.0997 14.8536 13.646 14.9886 13.1322 14.4748L9.99344 11.3398L6.85842 14.4748C6.42341 14.9098 5.79344 14.7561 5.51216 14.4748C5.14091 14.1036 5.14091 13.4998 5.51216 13.1286L8.64718 9.99355L5.51216 6.85853C5.14091 6.48729 5.14091 5.88352 5.51216 5.51227C5.88341 5.14102 6.48718 5.14102 6.85842 5.51227L9.99344 8.64729L13.1285 5.51227C13.4997 5.14102 14.1035 5.14102 14.4747 5.51227C14.846 5.88352 14.846 6.48729 14.4747 6.85853L11.3397 9.99355L14.4785 13.1286Z" fill="#EA2C1A"/>
                    </svg>
                </div>
            </div>
        </div>
        <span *ngIf="filteredProducts().length == 0 && searchText != ''" class="message">Press enter to search on all products</span>
    </div>
</div>


<div class="vi-container" *ngIf="roleAuth == null || roleAuth?.readDeviation">
    <div class="head" *skeleton="(selectedProduct == null && !hasNoCorrespondingProduct) || isSelectingProduct; height: '210px'; width: '50%'; margin:'0 auto 27px'; className:'head'">
        <svg *ngIf="hasNoCorrespondingProduct || this.selectedProduct?.locations?.length == 0" width="50" height="50" viewBox="0 0 109 116" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M44.218 12.9597L3.348 89.4997C2.41723 91.2441 1.95406 93.1999 2.00359 95.1765C2.05313 97.1531 2.61369 99.0833 3.63068 100.779C4.64767 102.475 6.08643 103.878 7.80686 104.852C9.52729 105.827 11.4708 106.339 13.448 106.34H94.608C96.5852 106.339 98.5287 105.827 100.249 104.852C101.97 103.878 103.408 102.475 104.425 100.779C105.442 99.0833 106.003 97.1531 106.052 95.1765C106.102 93.1999 105.639 91.2441 104.708 89.4997L63.838 12.9697C62.8471 11.2472 61.417 9.81853 59.6936 8.82943C57.9701 7.84033 56.0151 7.32625 54.028 7.33965C52.0389 7.30949 50.0784 7.81541 48.3523 8.80428C46.6261 9.79315 45.1981 11.2285 44.218 12.9597V12.9597Z" stroke="black" stroke-width="4" stroke-miterlimit="10"/><path d="M59.0786 89.7289C59.0786 87.4968 58.0157 86.4339 56.1024 86.4339H51.9571C49.9376 86.4339 48.8747 87.4968 48.8747 89.7289V92.5987C48.8747 94.9371 49.9376 96 51.9571 96H56.1024C58.0157 96 59.0786 94.9371 59.0786 92.5987V89.7289ZM59.2911 24.5731V24.148C59.2911 22.0222 58.2282 21.0655 56.2087 21.0655H51.8508C49.9376 21.0655 48.7684 22.0222 48.7684 24.148V24.5731L49.9376 72.0847C50.0439 74.4231 51.0005 75.5923 53.02 75.5923H55.2521C57.1653 75.5923 58.0157 74.4231 58.1219 72.0847L59.2911 24.5731Z" fill="black"/></svg>
        <span *ngIf="hasNoCorrespondingProduct">{{"NO CORRESPONDING PRODUCT" | translate}}</span>
        <span>{{getProductFullName(selectedProduct!)}}</span>
        <span *ngIf="this.selectedProduct?.locations?.length == 0" class="desc">{{"NOT ON PRODUCTION LINE" | translate}}</span>
        <div *ngIf="selectedProduct!=null " class="production-trackings">
            <app-production-tracking *ngFor="let location of getProductLocationList()" [product]="selectedProduct" [productLocation]="location" [checkRight]="roleAuth.checkCheckpoint" [onlyLine]="getProductLocationList().length == 1" (checkEvent)="openCheckPopUp(location)"></app-production-tracking>
        </div>
        <app-demo-button 
            *ngIf="roleAuth?.createDeviation && !hasNoCorrespondingProduct && this.selectedProduct?.locations?.length != 0"
            text="{{ 'CREATE NEW DEVIATION' | translate }}"
            type="btn-small"
            (clickEvent)="openDeviationPopUp($event)"
            [disable]="detectionWorkcellList.length === 0"
            tooltipText="{{detectionWorkcellList.length === 0 ? ('NO DETECTION WORKCELL AVAILABLE' | translate) : ''}}"
            tabindex="0">
        </app-demo-button>
    </div>
    
    <div class="legend-deviation" *ngIf="roleAuth?.readDeviation && !isSelectingProduct && this.selectedProduct?.locations?.length != 0 && !hasNoCorrespondingProduct" [ngClass]="{'no-problem-detail' :!currentFactory.deviationProblemDetailEnabled}">
        <span class="desc first">{{ 'FAULT TYPE' | translate }}</span>  
        <span class="desc">{{ 'DETECTION WORKCELL' | translate }}</span>
        <span class="desc">{{ 'FUNCTION' | translate }}</span>
        <span class="desc" *ngIf="currentFactory.deviationProblemDetailEnabled">{{ 'PROBLEM DETAIL' | translate }}</span>
        <span class="desc">{{ 'RESPONSIBLE' | translate }}</span>
        <span class="desc">{{ 'COMMENT' | translate }}</span>
        <span class="desc">{{ 'SOLVED' | translate}}</span>
    </div> 
    
    <div *skeleton="(selectedProduct == null && !hasNoCorrespondingProduct) || isSelectingProduct; repeat: 10; height: '40px'; width: 'calc(100% - 20px)'; margin:'10px'"></div>  

    <div class="list-deviation" *ngIf="roleAuth?.readDeviation && !isSelectingProduct && this.selectedProduct?.locations?.length != 0">    
        <!--CURRENT FACTORY DEVIATIONS-->
        <div *ngFor="let deviation of currentFactoryDeviations()"
            class="deviation" [ngClass]="{'solved' :deviation.resolutionDate !== null, 'no-problem-detail' :!currentFactory.deviationProblemDetailEnabled}"
            (click)="openDeviationPopUp($event, deviation, !((deviation.resolutionDate == null && roleAuth.modifyDeviation) || (deviation.resolutionDate != null && roleAuth.modifySolvedDeviation)))"
            (keyup.enter)="openDeviationPopUp($event, deviation, !((deviation.resolutionDate == null && roleAuth.modifyDeviation) || (deviation.resolutionDate != null && roleAuth.modifySolvedDeviation)))"
            (keyup.o)="openSolvePopUp($event, deviation)"
            tabindex="0">

            <div class="tooltip-container" style="height: 24px;">
                <svg class="icon-solved" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/><path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/></svg>
                <svg class="icon-not-solved" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#FCDDDA"/><path d="M18.9918 7.08571C12.4154 7.08571 7.08569 12.4154 7.08569 18.9919C7.08569 25.5683 12.4154 30.898 18.9918 30.898C25.5683 30.898 30.898 25.5683 30.898 18.9919C30.898 12.4154 25.5683 7.08571 18.9918 7.08571V7.08571ZM24.598 22.9107C25.062 23.3748 25.062 24.1295 24.598 24.5935C24.1245 25.067 23.5574 25.2357 22.9152 24.5935L18.9917 20.6748L15.0729 24.5935C14.5292 25.1373 13.7417 24.9451 13.3901 24.5935C12.9261 24.1295 12.9261 23.3748 13.3901 22.9107L17.3089 18.9919L13.3901 15.0732C12.9261 14.6091 12.9261 13.8544 13.3901 13.3903C13.8542 12.9263 14.6089 12.9263 15.0729 13.3903L18.9917 17.3091L22.9105 13.3903C23.3745 12.9263 24.1293 12.9263 24.5933 13.3903C25.0574 13.8544 25.0574 14.6091 24.5933 15.0732L20.6745 18.9919L24.598 22.9107Z" fill="#EA2C1A"/></svg>
                <div class="tooltip-content display-right">
                    <div class="displayId-container" (click)="copyDirectLink(deviation.displayId, $event)" *ngIf="deviation.displayId != null">
                        <span>{{deviation.displayId}}</span>
                        <mat-icon class="mat-icon-rtl-mirror" class="small">{{'content_copy'}}</mat-icon>
                    </div>
                    <span class="desc">{{getFaultTypeRank2Name(deviation.faultType!)}}</span>
                    <span class="desc">{{deviation.detectionWorkcell?.name}}</span>
                    <span class="desc">{{getFunctionFullPath(deviation)}}</span>
                    <span class="desc" *ngIf="currentFactory.deviationProblemDetailEnabled">{{getProblemDetailFullPath(deviation)}}</span>
                    <span class="desc">{{getResponsibleFullPath(deviation)}}</span>
                    <span class="desc">{{deviation.severity}}</span>
                    <span class="desc">{{deviation.description}}</span>
                </div>
            </div>

            <span class="title">{{getFaultTypeRank2Name(deviation.faultType!)}}</span>
            <span class="desc"><mat-icon class="mat-icon-rtl-mirror small">{{'location_on'}}</mat-icon><span>{{deviation.detectionWorkcell?.name}}</span></span>
            <span class="desc" title="{{getFunctionFullPath(deviation)}}"><mat-icon class="mat-icon-rtl-mirror small">{{'settings'}}</mat-icon><span>{{getFunctionFullPath(deviation)}}</span></span>
            <span class="desc" title="{{getProblemDetailFullPath(deviation)}}" *ngIf="currentFactory.deviationProblemDetailEnabled"><mat-icon class="mat-icon-rtl-mirror small">{{'settings'}}</mat-icon><span>{{getProblemDetailFullPath(deviation)}}</span></span>
            <span class="desc" title="{{getResponsibleFullPath(deviation)}}"><mat-icon class="mat-icon-rtl-mirror small">{{'settings'}}</mat-icon><span>{{getResponsibleFullPath(deviation)}}</span></span>
            <span class="desc"><mat-icon class="mat-icon-rtl-mirror small">{{'chat_bubble'}}</mat-icon><span>{{deviation.description}}</span></span>

            <app-demo-button class="bar-button" text="{{ 'VIEW' | translate }}" color="reverse-grey" type="btn-small" (clickEvent)="openDeviationPopUp($event, deviation, true)" *ngIf="(deviation.resolutionDate == null && (!roleAuth?.modifyDeviation || !roleAuth?.solveDeviation)) || (deviation.resolutionDate != null && !roleAuth?.modifySolvedDeviation)"></app-demo-button>
            <app-demo-button class="bar-button" text="{{ 'EDIT' | translate }}" color="reverse-grey" type="btn-small" (clickEvent)="openDeviationPopUp($event, deviation)" *ngIf="(deviation.resolutionDate == null && roleAuth?.modifyDeviation) || (deviation.resolutionDate != null && roleAuth?.modifySolvedDeviation)" ></app-demo-button>
            <app-demo-button class="bar-button" text="{{ 'SOLVE' | translate }}" color="reverse-secondary" type="btn-small" (clickEvent)="openSolvePopUp($event, deviation)" *ngIf="deviation.resolutionDate == null && roleAuth?.solveDeviation"></app-demo-button>

            <span class="desc solved-date" *ngIf="deviation.resolutionDate != null">{{deviation.resolutionDate | demoDate}}</span>
        </div>

        <!--OTHER FACTORIES DEVIATIONS-->    
        <div class="deviation expandable" (click)="isOtherFactoriesDeviationsExpanded = !isOtherFactoriesDeviationsExpanded" *ngIf="otherFactoriesDeviations().length > 0">
    
            <span class="title no-overflow">{{otherFactoriesDeviationsSolved().length}}</span>
            <svg width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/><path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/></svg>
            <span class="title no-overflow">{{otherFactoriesDeviationsNotSolved().length}}</span>
            <svg width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#FCDDDA"/><path d="M18.9918 7.08571C12.4154 7.08571 7.08569 12.4154 7.08569 18.9919C7.08569 25.5683 12.4154 30.898 18.9918 30.898C25.5683 30.898 30.898 25.5683 30.898 18.9919C30.898 12.4154 25.5683 7.08571 18.9918 7.08571V7.08571ZM24.598 22.9107C25.062 23.3748 25.062 24.1295 24.598 24.5935C24.1245 25.067 23.5574 25.2357 22.9152 24.5935L18.9917 20.6748L15.0729 24.5935C14.5292 25.1373 13.7417 24.9451 13.3901 24.5935C12.9261 24.1295 12.9261 23.3748 13.3901 22.9107L17.3089 18.9919L13.3901 15.0732C12.9261 14.6091 12.9261 13.8544 13.3901 13.3903C13.8542 12.9263 14.6089 12.9263 15.0729 13.3903L18.9917 17.3091L22.9105 13.3903C23.3745 12.9263 24.1293 12.9263 24.5933 13.3903C25.0574 13.8544 25.0574 14.6091 24.5933 15.0732L20.6745 18.9919L24.598 22.9107Z" fill="#EA2C1A"/></svg> 
            
            <span class="title">{{"OTHER FACTORIES DEVIATIONS" | translate}} {{this.otherFactoryWithDeviationsOfSelectedProduct.name}}</span>
            <mat-icon class="mat-icon-rtl-mirror">{{isOtherFactoriesDeviationsExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
    
        </div>
        <div *ngFor="let deviation of otherFactoriesDeviations()"
             class="deviation inside-accordion" [ngClass]="{'solved' :deviation.resolutionDate !== null, 'visible':isOtherFactoriesDeviationsExpanded, 'no-problem-detail' :!currentFactory.deviationProblemDetailEnabled}"
             (click)="openDeviationPopUp($event, deviation, !((deviation.resolutionDate == null && roleAuth.modifyDeviation) || (deviation.resolutionDate != null && roleAuth.modifySolvedDeviation)))"
             (keyup.enter)="openDeviationPopUp($event, deviation, !((deviation.resolutionDate == null && roleAuth.modifyDeviation) || (deviation.resolutionDate != null && roleAuth.modifySolvedDeviation)))"
             (keyup.o)="openSolvePopUp($event, deviation)"
             tabindex="0">

            <div class="tooltip-container" style="height: 24px;">
                <svg class="icon-solved" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/><path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/></svg>
                <svg class="icon-not-solved" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#FCDDDA"/><path d="M18.9918 7.08571C12.4154 7.08571 7.08569 12.4154 7.08569 18.9919C7.08569 25.5683 12.4154 30.898 18.9918 30.898C25.5683 30.898 30.898 25.5683 30.898 18.9919C30.898 12.4154 25.5683 7.08571 18.9918 7.08571V7.08571ZM24.598 22.9107C25.062 23.3748 25.062 24.1295 24.598 24.5935C24.1245 25.067 23.5574 25.2357 22.9152 24.5935L18.9917 20.6748L15.0729 24.5935C14.5292 25.1373 13.7417 24.9451 13.3901 24.5935C12.9261 24.1295 12.9261 23.3748 13.3901 22.9107L17.3089 18.9919L13.3901 15.0732C12.9261 14.6091 12.9261 13.8544 13.3901 13.3903C13.8542 12.9263 14.6089 12.9263 15.0729 13.3903L18.9917 17.3091L22.9105 13.3903C23.3745 12.9263 24.1293 12.9263 24.5933 13.3903C25.0574 13.8544 25.0574 14.6091 24.5933 15.0732L20.6745 18.9919L24.598 22.9107Z" fill="#EA2C1A"/></svg>
                <div class="tooltip-content display-right">
                    <div class="displayId-container" (click)="copyDirectLink(deviation.displayId, $event)" *ngIf="deviation.displayId != null">
                        <span>{{deviation.displayId}}</span>
                        <mat-icon class="mat-icon-rtl-mirror" class="small">{{'content_copy'}}</mat-icon>
                    </div>
                    <span class="desc">{{getFaultTypeRank2Name(deviation.faultType!)}}</span>
                    <span class="desc">{{deviation.detectionWorkcell?.name}}</span>
                    <span class="desc">{{getFunctionFullPath(deviation)}}</span>
                    <span class="desc" *ngIf="currentFactory.deviationProblemDetailEnabled">{{getProblemDetailFullPath(deviation)}}</span>
                    <span class="desc">{{getResponsibleFullPath(deviation)}}</span>
                    <span class="desc">{{deviation.severity}}</span>
                    <span class="desc">{{deviation.description}}</span>
                </div>
            </div>  
            <span class="title">{{getFaultTypeRank2Name(deviation.faultType!)}}</span>
            <span class="desc"><mat-icon class="mat-icon-rtl-mirror small">{{'location_on'}}</mat-icon><span>{{deviation.detectionWorkcell?.name}}</span></span>
            <span class="desc" title="{{getFunctionFullPath(deviation)}}"><mat-icon class="mat-icon-rtl-mirror small">{{'settings'}}</mat-icon><span>{{getFunctionFullPath(deviation)}}</span></span>
            <span class="desc" title="{{getProblemDetailFullPath(deviation)}}" *ngIf="currentFactory.deviationProblemDetailEnabled"><mat-icon class="mat-icon-rtl-mirror small">{{'chat_bubble'}}</mat-icon>{{getProblemDetailFullPath(deviation)}}</span>
            <span class="desc" title="{{getResponsibleFullPath(deviation)}}"><mat-icon class="mat-icon-rtl-mirror small">{{'chat_bubble'}}</mat-icon>{{getResponsibleFullPath(deviation)}}</span>
            <span class="desc"><mat-icon class="mat-icon-rtl-mirror small">{{'chat_bubble'}}</mat-icon>{{deviation.description}}</span>
    
            <app-demo-button class="bar-button" text="{{ 'VIEW' | translate }}" color="reverse-grey" type="btn-small" (clickEvent)="openDeviationPopUp($event, deviation, true)" *ngIf="(deviation.resolutionDate == null && (!roleAuth?.modifyDeviation || !roleAuth?.solveDeviation)) || (deviation.resolutionDate != null && !roleAuth?.modifySolvedDeviation)"></app-demo-button>
            <app-demo-button class="bar-button" text="{{ 'EDIT' | translate }}" color="reverse-grey" type="btn-small" (clickEvent)="openDeviationPopUp($event, deviation)" *ngIf="(deviation.resolutionDate == null && roleAuth?.modifyDeviation) || (deviation.resolutionDate != null && roleAuth?.modifySolvedDeviation)" ></app-demo-button>
            <app-demo-button class="bar-button" text="{{ 'SOLVE' | translate }}" color="reverse-secondary" type="btn-small" (clickEvent)="openSolvePopUp($event, deviation)" *ngIf="deviation.resolutionDate == null && roleAuth?.solveDeviation"></app-demo-button>
    
            <span class="desc solved-date" *ngIf="deviation.resolutionDate != null">{{deviation.resolutionDate | demoDate}}</span>
        </div>
            
    </div>
</div>

<div class="popup-container" [ngClass]="{'active' :hasCheckpointToCheck}">
    <div class="popup" *ngIf="hasCheckpointToCheck">
    
        <div class="close-container">
            <svg (click)="closePopUp()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
            </svg>
        </div>

    

    <!--CHECKPOINT-->
    <div class="checkpoint-popup" *ngIf="hasCheckpointToCheck">

        <span class="title">{{ 'CHECK' | translate }} {{currentProductLocation?.currentWorkcell?.name}}</span>
        <div class="description">
            <span class="text">{{ 'CHECK CHECKPOINT TEXT' | translate }} <strong>{{currentProductLocation?.currentWorkcell?.name}}</strong> {{'CHECK CHECKPOINT STATUS' | translate}} </span>
            <div *skeleton="loadingCheckInformation; height: '16px'; width: '16px';"></div>
            <svg *ngIf="!loadingCheckInformation && isStatusOKAfterCheck()" class="icon-cok" width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/><path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/></svg>
            <svg *ngIf="!loadingCheckInformation && !isStatusOKAfterCheck()" class="icon-cnok" width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#FCDDDA"/><path d="M18.9918 7.08571C12.4154 7.08571 7.08569 12.4154 7.08569 18.9919C7.08569 25.5683 12.4154 30.898 18.9918 30.898C25.5683 30.898 30.898 25.5683 30.898 18.9919C30.898 12.4154 25.5683 7.08571 18.9918 7.08571V7.08571ZM24.598 22.9107C25.062 23.3748 25.062 24.1295 24.598 24.5935C24.1245 25.067 23.5574 25.2357 22.9152 24.5935L18.9917 20.6748L15.0729 24.5935C14.5292 25.1373 13.7417 24.9451 13.3901 24.5935C12.9261 24.1295 12.9261 23.3748 13.3901 22.9107L17.3089 18.9919L13.3901 15.0732C12.9261 14.6091 12.9261 13.8544 13.3901 13.3903C13.8542 12.9263 14.6089 12.9263 15.0729 13.3903L18.9917 17.3091L22.9105 13.3903C23.3745 12.9263 24.1293 12.9263 24.5933 13.3903C25.0574 13.8544 25.0574 14.6091 24.5933 15.0732L20.6745 18.9919L24.598 22.9107Z" fill="#EA2C1A"/></svg>
        </div>
        <app-operator-scanning 
            [currentFactoryId]="currentFactoryId"
            [allowUnknownOperator]="currentFactory != null ? currentFactory.allowUnknownOperator : false"
            [triggerRememberMeEffect]="hasCheckpointToCheck && isSavingData"
            *ngIf="currentFactory != null ? currentFactory.operatorScanningEnabled : false" 
            (userScannedEvent)="userScanned = $event"
            (isScanningEvent)="isScanning = $event">
        </app-operator-scanning>
        <div class="related-deviations-container" *skeleton="loadingCheckInformation; repeat: 4; height: '34px'; width: '300px';">
            <span *ngIf="checkRelatedDeviations.length != 0">{{'RELATED DEVIATIONS' | translate}}</span>
            <div *ngFor="let dev of relatedDeviationsNotOkay()" class="related-deviations">
                <svg *ngIf="dev.resolutionDate == null" class="icon-cnok" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.9918 7.08571C12.4154 7.08571 7.08569 12.4154 7.08569 18.9919C7.08569 25.5683 12.4154 30.898 18.9918 30.898C25.5683 30.898 30.898 25.5683 30.898 18.9919C30.898 12.4154 25.5683 7.08571 18.9918 7.08571V7.08571ZM24.598 22.9107C25.062 23.3748 25.062 24.1295 24.598 24.5935C24.1245 25.067 23.5574 25.2357 22.9152 24.5935L18.9917 20.6748L15.0729 24.5935C14.5292 25.1373 13.7417 24.9451 13.3901 24.5935C12.9261 24.1295 12.9261 23.3748 13.3901 22.9107L17.3089 18.9919L13.3901 15.0732C12.9261 14.6091 12.9261 13.8544 13.3901 13.3903C13.8542 12.9263 14.6089 12.9263 15.0729 13.3903L18.9917 17.3091L22.9105 13.3903C23.3745 12.9263 24.1293 12.9263 24.5933 13.3903C25.0574 13.8544 25.0574 14.6091 24.5933 15.0732L20.6745 18.9919L24.598 22.9107Z" fill="#EA2C1A"/></svg>
                <span>{{getFaultTypeRank2Name(dev.faultType!)}}</span>
                <span>{{dev.detectionWorkcell?.name}}</span>
            </div>
            <div *ngIf="relatedDeviationsSolved().length > 0">
                <div class="related-deviations expandable" (click)="isSolvedRelatedDeviationExpanded = !isSolvedRelatedDeviationExpanded">
                    <svg class="icon-cok" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/></svg>
                    <span>{{relatedDeviationsSolved().length}} {{"SOLVED" | translate}}</span>
                    <mat-icon class="mat-icon-rtl-mirror" class="small">{{isSolvedRelatedDeviationExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>

                    <div class="related-deviations-container inside-accordion" [ngClass]="{'active':isSolvedRelatedDeviationExpanded}">
                        <div *ngFor="let dev of relatedDeviationsSolved()" class="related-deviations">
                            <svg *ngIf="dev.resolutionDate != null" class="icon-cok" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/></svg>
                            <span>{{getFaultTypeRank2Name(dev.faultType!)}}</span>
                            <span>{{dev.detectionWorkcell?.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span *ngIf="checkRelatedDeviations.length == 0">{{"NO RELATED DEVIATION" | translate}}</span>
        </div>
        <span>{{'CHECK CHECKPOINT CONFIRM' | translate}}</span>
        
        
        <div class="button-container" #checkPopupContent (keydown.tab)="onTabKeyDown($event)">
            <app-demo-button text="{{ 'CANCEL' | translate }}" type="btn-small" color="reverse-grey" (clickEvent)="closePopUp()" tabindex="0" id="cancel-check-button" ></app-demo-button>
            <app-demo-button text="{{ 'YES, I CHECK' | translate }}" type="btn-small" color="tertiary" svg="check" [disable]="isSavingData || !hasIdentifiedUser()" [tooltipText]="!hasIdentifiedUser() ? 'MISSING OPERATOR MESSAGE' : '' | translate" (clickEvent)="check()" tabindex="0"></app-demo-button>
        </div>
    </div>
    

    </div>
</div>

    <app-deviation-popup [isPopupOpen]="hasDeviationToChange"
                         [selectedProduct]="selectedProduct"
                         [selectedDeviation]="selectedDeviation"
                         [currentFactoryId]="currentFactoryId"
                         [allowUnknownOperator]="currentFactory != null ? currentFactory.allowUnknownOperator : false"
                         [operatorScanningEnabled]="currentFactory != null ? currentFactory.operatorScanningEnabled : false"
                         [isGenericUser]="isGenericUser"
                         [currentLineId]="currentLineId"
                         [roleAuth]="roleAuth"
                         [activeAccount]="getActiveAccount()"
                         [canActivateSaveAndSolve]="true"
                         [canActivateSaveAndConfirm]="false"
                         [quantityVisible]="true"
                         [problemDetailEnabled]="currentFactory != null ? currentFactory.deviationProblemDetailEnabled : false"
                         [locationEnabled]="currentFactory != null ? currentFactory.deviationLocationEnabled: false"
                         [displayRTAlias]="currentFactory != null ? currentFactory.displayRTAlias: false"
                         (closePopupEvent)="closeProductPopUp($event)"
                         (solvePopupEvent)="openSolvePopUpAfterDeviation($event)"
                         (messagePopupEvent)="displayMessagePopup($event)">
    </app-deviation-popup>

    <app-solve-popup [isPopupOpen]="hasDeviationToSolve"
                     [selectedProduct]="selectedProduct"
                     [selectedDeviation]="deviationToSolve"
                     [currentFactoryId]="currentFactoryId"
                     [operatorScanningEnabled]="currentFactory != null ? currentFactory.operatorScanningEnabled : false"
                     [isGenericUser]="isGenericUser"
                     [solver]=getActiveAccount()?.username
                     [solverId]=getUserId()
                     [allowUnknownOperator]="currentFactory != null ? currentFactory.allowUnknownOperator : false"
                     (closePopupEvent)="closePopUp()"
                     (solvePopupEvent)="solveDeviation($event)"
                     (messagePopupEvent)="displayMessagePopup($event)">
    </app-solve-popup>
</div>

<app-message-popup [type]=messagePopupType
                   title="{{messagePopupTitle | translate}}"
                   content="{{messagePopupText | translate}}"
                   [ngClass]="{'active' :messagePopupTitle != ''}"
                   (closeEvent)="messagePopupTitle = ''">
</app-message-popup>