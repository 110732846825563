import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SupplierQuality } from 'src/app/models/supplierQuality.model';
import { SupplierQualityImpactedProduct } from 'src/app/models/supplierQualityImpactedProduct.model';
import { environment } from 'src/environments/environment';
import { ProductView } from 'src/app/models/productView.model';
import { ProductService } from 'src/app/services/api/product.service';

@Component({
  selector: 'app-impacted-products-list',
  templateUrl: './impacted-products-list.component.html',
  styleUrl: './impacted-products-list.component.less'
})
export class ImpactedProductsListComponent {
  apiUrl:string =  environment.API_URL;
  
  @Input() selectedSupplier:SupplierQuality | null = null;

  //@Input() roleAuth!:Role;
  @Input() errorMessageSelectedCase = "";
  @Input() currentFactoryId = -2;
  @Input() currentCheckpointId = -2;

  //VEHICLES
  inputVehiclesFocused = false;
  vehicleFilter = "";
  warningVehicleMessage = "";
  vehicleList:ProductView[] = [];
  vehicleToRemove:SupplierQualityImpactedProduct | null = null;

  //ERROR
  errorMessageInSearch = "";

  @HostListener('window:keydown.escape') escapeEvent() { this.closePopup(); }
  @ViewChild('addStakeholderInput') addVehicleInput!: ElementRef;

  constructor(
    private httpClient:HttpClient,
    private translate: TranslateService,
    private productService:ProductService) {
  }
  
  ngOnInit(): void {
    this.getVehicleList();
  }

  getVehicleList(){
    if(this.currentFactoryId === -2) {return;}

    this.productService.getProductsByFactoryId(this.currentFactoryId, this.currentCheckpointId).subscribe({
      next: (res:ProductView[]) => {
        this.vehicleList = res;
      },
      error: (e:any) => {
        if(e.status === 404){
          this.errorMessageInSearch = "VEHICULE NOT FOUND";
        }
      }
    });
  }

  searchVehicleOutOfTheList(){
    setTimeout(()=>{
      this.addVehicleInput.nativeElement.focus();
    },100);

    this.productService.getProductByChassisId(this.currentFactoryId, this.vehicleFilter).subscribe({
      next: (res) => {
        if(!this.vehicleList.find(x=>x.id === res.id) && !this.selectedSupplier?.impactedProducts?.find(x=>x.productId === res.id)){
          this.vehicleList.push(res);
        }else{
          this.errorMessageInSearch = "VEHICLE ALREADY SELECTED"
        }
      },
      error: (e) => {
        if(e.status === 404){
          this.errorMessageInSearch = "VEHICLE NOT FOUND";
        }
      }
    });
  }

  filteredVehicleList():ProductView[]{
    return this.vehicleList.filter(x=>  (x.chassisSeries + x.chassisNumber) !== "" &&
                                        (x.chassisSeries + x.chassisNumber).includes(this.vehicleFilter) && 
                                        !this.isAlreadyImpactedVehicle(x.chassisNumber, x.chassisSeries));
  }

  isAlreadyImpactedVehicle(chassisNumber:string, chassisSeries:string):boolean{
    if((this.selectedSupplier == null || this.selectedSupplier.impactedProducts == null || this.selectedSupplier.impactedProducts.length === 0)) {return false;}

    return this.selectedSupplier.impactedProducts.find(x=>x.product != null && x.product.chassisNumber === chassisNumber && x.product.chassisSeries === chassisSeries) != null;
  }

  addVehicleInputFocus(){
    this.inputVehiclesFocused = true;
    this.warningVehicleMessage = "";
  }

  addVehicle(vehicle?:ProductView){
    if(this.selectedSupplier == null || vehicle == null){ return;}

    //if new one not already on the list, don't add it
    if(this.isAlreadyImpactedVehicle(vehicle.chassisNumber, vehicle.chassisSeries)) {return; }

    this.httpClient.post<any>(this.apiUrl + 'SupplierQuality/' + this.selectedSupplier.id + '/ImpactedProduct/' + vehicle.id, null).subscribe(
      {
        next: (res) => {
          this.selectedSupplier?.impactedProducts?.push(res);
        },
        error: (e) => {
          console.error(e);
        }
      }
    );
  }

  openRemoveVehiclePopUp(vehicle:SupplierQualityImpactedProduct){
    this.vehicleToRemove = vehicle;
  }

  removeVehicle(){
    if(this.vehicleToRemove == null || this.selectedSupplier == null){ return;}

    this.httpClient.delete(this.apiUrl + 'SupplierQuality/' + this.selectedSupplier.id + '/ImpactedProduct/' + this.vehicleToRemove.productId ).subscribe(
      {
        next: (res) => {
          this.selectedSupplier?.impactedProducts?.splice(this.selectedSupplier?.impactedProducts?.findIndex(x => this.vehicleToRemove != null && this.vehicleToRemove.productId === x.productId), 1);
          this.closePopup();
        },
        error: (e) => {
          console.log(e);
        }
      }
    );
  }

  closePopup(){
    this.vehicleToRemove = null;
  }
}
