import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Factory } from 'src/app/models/factory.model';
import { ProductionLine } from 'src/app/models/productionLine.model';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AccountInfo } from '@azure/msal-browser';
import { UserRole } from 'src/app/models/userRole.model';
import { FactoryOrValue, Subject, Subscription, takeUntil } from 'rxjs';
import { Page } from 'src/app/models/page.enum';
import { FactoryView } from 'src/app/models/factoryViewModel.model';
import { ProductionLineView } from 'src/app/models/productionLineView.model';
import { WorkcellView } from 'src/app/models/workcellView.model';
import { Workcell } from 'src/app/models/workcell.model';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { Agent } from 'src/app/models/agent.model';
import { LocalstorageDataService } from 'src/app/services/localstorage-data.service';
import { UserRolesService } from 'src/app/services/user-roles.service';
import { Role } from 'src/app/models/role.model';
import { ProductService } from 'src/app/services/api/product.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  apiUrl:string =  environment.API_URL;
  envName:string =  environment.name;

  @Input() loginDisplay = false;
  @Output() logoutEvent = new EventEmitter<any>();
  @Output() loginEvent = new EventEmitter<any>();

  factories: FactoryView[] = [];

  factoriesDeviation: FactoryView[] = [];
  factoriesMissingPart:FactoryView[] = [];
  factoriesSuppliers:FactoryView[] = [];

  currentFactory!: FactoryView;
  currentLine!: ProductionLineView | null;
  currentCheckpoint!: WorkcellView | null;

  openCheckpointSelector = false;

  urlFactoryId = -1;
  urlLineId = -1;
  urlElementId = -1;

  deviationsListUrlName = "deviations-list";
  missingPartsUrlName = "missing-parts";
  suppliersUrlName = "suppliers";

  isBurgerMenuOpen = false;

  browserNotificationsEnabled = false;

  //user scan
  isScanning = false;
  scannedAgent?:Agent | null;

  //Subscriptions  
  public chosenCheckpoint = 'chosen checkpoint unset header';
  private getChosenCheckpointSubscription!: Subscription;

  roleAuth!:Role;
  isGenericUser = false;
  private unsubscribe$ = new Subject<void>();
  
  constructor(
    private httpClient:HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private _headerDataService: HeaderDataService,
    private localStorageService: LocalstorageDataService,
    private roleService: UserRolesService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.roleService.roles$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res) {
        this.roleAuth = res.userRoles;
        this.isGenericUser = res.isGenericUser;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUrlId(callback?: () => void) {
    const url = window.location.href;
    const pathSegments = window.location.pathname.split("/", 5);
  
    if (url.includes("/displayid")) {
      this.handleDisplayIdCase(pathSegments, callback);
    } else if (!this.isExcludedUrl(url)) {
      this.handleStandardUrl(pathSegments, callback);
    } else if (callback) {
      callback();
    }
  }

  private handleDisplayIdCase(pathSegments: string[], callback?: () => void): void {
    const displayId = pathSegments[3];
    if (displayId == null) { return; }
  
    this.productService.getProductByDeviationDisplayId(displayId).subscribe((res: any) => {
      localStorage.setItem("deviationDirectLink", JSON.stringify({ product: res.product, displayId }));
      const baseRoute = this.router.url.split("/")[1];
      this.router.navigateByUrl(`/${baseRoute}/${res.factoryId}/${res.productionLineId}`);
  
      this.urlFactoryId = res.factoryId;
      this.urlLineId = res.productionLineId;
  
      if (callback) {callback();}
    });
  }

  private handleStandardUrl(pathSegments: string[], callback?: () => void): void {
    this.urlFactoryId = Number(pathSegments[2]);
    this.urlLineId = Number(pathSegments[3]);
  
    if (pathSegments.length > 4) {
      this.urlElementId = Number(pathSegments[4]);
    }
  
    if (callback) {callback();}
  }
  
  private isExcludedUrl(url: string): boolean {
    const excludedPaths = ["/deviations-list", "/missing-parts", "/suppliers"];
    return excludedPaths.some(
      (path) => url.endsWith(path) || url.endsWith(`${path}/`)
    );
  }

  getActiveAccount(): AccountInfo | null{
    return this.authService.getActiveAccount();
  }

  hasAuthorizationForUrlFactory():boolean{
    const splittedUrl:string[] = this.router.url.split("/", 4);

    if(splittedUrl[2] == null){ return false;}

    if(this.router.url.includes(this.deviationsListUrlName)){
      return this.factoriesDeviation.find(x => x.id === Number(splittedUrl[2])) !== undefined ? true : false;
    }
    else if (this.router.url.includes(this.missingPartsUrlName)){
      return this.factoriesMissingPart.find(x => x.id === Number(splittedUrl[2])) !== undefined ? true : false;
    }
    else if (this.router.url.includes(this.suppliersUrlName)){
      return this.factoriesSuppliers.find(x => x.id === Number(splittedUrl[2])) !== undefined ? true : false;
    }
    else { return false;}
  }

  getAuthorizedFactories(p?:Page){
    let page:Page;
    if(p != null){
      page = p;
    }
    else if(window.location.href.includes("/deviations-list")){
      page = Page.Deviation;
    }
    else if(window.location.href.includes("/missing-parts")){
      page = Page.MissingPart;
    }
    else if(window.location.href.includes("/suppliers")){
      page = Page.Suppliers;
    }
    else { return;}

    this.browserNotificationsEnabled = localStorage.getItem("browserNotificationsEnabled") != null ? localStorage.getItem("browserNotificationsEnabled") === "true": false;

    this.httpClient.get<FactoryView[]>(this.apiUrl + "Factory/ByPageType/" + page).subscribe(
      (res: FactoryView[]) => {
        if(page === Page.Deviation){
         this.factoriesDeviation = [...res];
        }
        else if(page === Page.MissingPart){
          this.factoriesMissingPart = [...res];
        }
        else if(page === Page.Suppliers){
          this.factoriesSuppliers = [...res];
        }
        
        this.setCurrentFactoryAndLine(page);
      }
    );
  }

  //Start setCurrentFactoryAndLine decomposition 
  setCurrentFactoryAndLine(page: Page): void {
    this.getUrlId(() => {
      const factoryList = this.getFactoryListByPage(page);
  
      if (this.urlFactoryId !== -1) {
        this.setFactoryAndLineFromUrl(factoryList);
        this.setLocalStorageCurrentFactoryAndLine();
        if (page === Page.Deviation) { this.setCurrentCheckpoint();}
      } else {
        this.setFactoryFromLocalStorageOrDefault(factoryList);
      }
  
      this.updateRolesForCurrentFactory();
    });
  }

  private getFactoryListByPage(page: Page): FactoryView[] {
    switch (page) {
      case Page.Deviation:
        return this.factoriesDeviation;
      case Page.MissingPart:
        return this.factoriesMissingPart;
      case Page.Suppliers:
        return this.factoriesSuppliers;
      default:
        throw new Error("Unsupported page type");
    }
  }

  private setFactoryAndLineFromUrl(factoryList: FactoryView[]): void {
    this.currentFactory = factoryList.find((x) => x.id === this.urlFactoryId)!;
    this.currentLine = this.currentFactory.productionLines.find((x) => x.id === this.urlLineId)!;
  }
  
  private setFactoryFromLocalStorageOrDefault(factoryList: FactoryView[]): void {
    const previouslyChosenFactoryId = Number(localStorage.getItem("chosenFactory"));
    const previouslyChosenFactory = factoryList.find((x) => x.id === previouslyChosenFactoryId);
  
    if (previouslyChosenFactoryId != null && previouslyChosenFactory != null) {
      this.selectFactory(previouslyChosenFactory);
    } else {
      this.selectFactory(factoryList[0]);
    }
  }

  private updateRolesForCurrentFactory(): void {
    if (this.currentFactory.id != null && this.getActiveAccount() != null) {
      this.roleService.getRolesByUserAndFactory(
        this.currentFactory.id,
        this.getActiveAccount()?.username!
      );
    }
  }
  //End setCurrentFactoryAndLine decomposition

  setLocalStorageCurrentFactoryAndLine(){
    if(this.currentFactory != null){
      localStorage.setItem("currentFactory", JSON.stringify(this.currentFactory));
    }
    if(this.currentLine != null){
      localStorage.setItem("currentLine", JSON.stringify(this.currentLine));
    }
  }

  //Start setCurrentCheckpoint decomposition 
  setCurrentCheckpoint(){
    const chosenCheckpoint = this.getChosenCheckpoint();

    if(this.currentLine != null && this.currentLine.checkpoints.length === 0){
      this.selectCheckpoint(new WorkcellView("", 1, -1));
    }
    else if(this.currentLine == null && chosenCheckpoint != null){
      this.selectCheckpoint(this.getAllLinesCheckpoints().find(x=> x.id === chosenCheckpoint.id) || this.getAllLinesCheckpoints()[0]);
    }
    else if(this.currentLine == null){
      this.selectCheckpoint(this.getAllLinesCheckpoints()[0]);
    }
    else if(chosenCheckpoint != null){
      this.selectCheckpoint(this.currentLine.checkpoints.find(x=> x.id === chosenCheckpoint.id) || this.currentLine.checkpoints[0]);
    }
    else{
      this.selectCheckpoint(this.currentLine.checkpoints[0]);
    }
  }

  private getChosenCheckpoint(): WorkcellView | null {
    return this.currentLine != null
      ? JSON.parse(localStorage.getItem(`chosenCheckpointForLine${this.currentLine.id}`)!)
      : JSON.parse(localStorage.getItem(`chosenCheckpointForAllLinesOnFactory${this.currentFactory.id}`)!);
  }
  //End SetCurrentCheckpoint decomposition

  getAllLinesCheckpoints(){
    if(this.currentFactory == null){ return [];}
    let allCheckpoints:Workcell[] = []
    this.currentFactory.productionLines.forEach(line => {
      allCheckpoints = allCheckpoints.concat(line.checkpoints);
    });
    return allCheckpoints;
  }

  selectFactory(factory:FactoryView){
    this.currentFactory = factory;
    localStorage.setItem("currentFactory", JSON.stringify(factory));
    localStorage.setItem("chosenFactory", String(factory.id));
    this.urlFactoryId = factory?.id!;

    if(localStorage.getItem("chosenLineForFactory" + this.currentFactory.id) === "-1"){
      this.selectLine();
    }
    else{
      const previouslyChosenLineId = Number(localStorage.getItem("chosenLineForFactory" + this.currentFactory.id));
      const previouslyChosenLine:ProductionLineView | undefined = factory.productionLines.find(x=>x.id === previouslyChosenLineId);

      console.log("previously chosen line", previouslyChosenLine)
        
      if(previouslyChosenLineId != null && previouslyChosenLine != null){
        this.selectLine(previouslyChosenLine);
      }
      else{
        this.selectLine(factory.productionLines[0]);
      }
    }

    this.isBurgerMenuOpen = false;

    if(this.currentFactory.id != null && this.getActiveAccount() != null){
      this.roleService.getRolesByUserAndFactory(this.currentFactory.id, this.getActiveAccount()?.username!);
    }
  }

  selectLine(line?:ProductionLineView){
    if(line != null){
      this.currentLine = line;
      this.urlLineId = line?.id!;
      localStorage.setItem("currentLine", JSON.stringify(line));
      localStorage.setItem("chosenLineForFactory" + this.currentFactory.id, JSON.stringify(line.id)); 
      this.setCurrentCheckpoint();
      this.router.navigateByUrl("/" + this.router.url.split('/')[1] + "/"+ this.currentFactory.id + "/" + this.currentLine.id);
    } else {
      this.currentLine = null;
      this.urlLineId = -1;
      localStorage.setItem("currentLine", JSON.stringify(null));
      localStorage.setItem("chosenLineForFactory" + this.currentFactory.id, JSON.stringify(-1)); 
      this.setCurrentCheckpoint();
      this.router.navigateByUrl("/" + this.router.url.split('/')[1] + "/"+ this.currentFactory.id + "/" + -1);
    }    

    this.isBurgerMenuOpen = false;
  }

  selectCheckpoint(checkpoint:WorkcellView){
    if(checkpoint == null || checkpoint.id == null){ return;}
    this.currentCheckpoint = checkpoint;
    
    this.getCurrentLineId() !== -1 ? 
      localStorage.setItem("chosenCheckpointForLine" + this.getCurrentLineId(), JSON.stringify(checkpoint)) :
      localStorage.setItem("chosenCheckpointForAllLinesOnFactory" + this.currentFactory.id, JSON.stringify(checkpoint));
    
    this._headerDataService.setChosenCheckpoint(this.currentCheckpoint, this.urlFactoryId, this.urlLineId);

    this.isBurgerMenuOpen = false;
  }

  login(){
    this.loginEvent.emit();
  } 

  logout(){
    this.logoutEvent.emit();
  }

  toggleBurgerMenu(){
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }

  getCurrentFactoryId(){
    if(this.currentFactory != null){
      return this.currentFactory.id;
    }

    return -1;
  }

  getCurrentLineId(){
    if(this.currentLine != null){
      return this.currentLine.id;
    }

    return -1;
  }

  getHeaderButtonUrl(pageName:string):string{
    if(this.getCurrentFactoryId() === -1 ){
      this.urlFactoryId = -1;
      this.urlLineId = -1;
      return '/' + pageName;
    }
    else{
      return '/' + pageName + '/' + this.getCurrentFactoryId() + '/' + this.getCurrentLineId();
    }
  }

  getFactoriesArray():FactoryView[]{
    if(window.location.href.includes(this.deviationsListUrlName)){
      return this.factoriesDeviation;
    }
    else if (window.location.href.includes(this.missingPartsUrlName)){
      return this.factoriesMissingPart;
    }
    else if (window.location.href.includes(this.suppliersUrlName)){
      return this.factoriesSuppliers;
    }
    else{
      return this.factories;
    }
  }

  isHomepage(){
    return this.router.url === "" || this.router.url === "/";
  }

  selectNextFactory(){
    let factoriesList:FactoryView[];
    if(window.location.href.includes(this.deviationsListUrlName)){
      factoriesList = this.factoriesDeviation;
    }
    else{
      factoriesList = this.factoriesMissingPart;
    }

    let nextIndex:number = factoriesList.findIndex((x:FactoryView) => x.id === this.currentFactory.id) + 1;

    if(nextIndex === factoriesList.length){
      nextIndex = 0;
    }

    this.selectFactory(factoriesList[nextIndex]);
  }

  selectPreviousFactory(){
    let factoriesList:FactoryView[];
    if(window.location.href.includes(this.deviationsListUrlName)){
      factoriesList = this.factoriesDeviation;
    }
    else{
      factoriesList = this.factoriesMissingPart;
    }

    let prevIndex:number =factoriesList.findIndex((x:FactoryView) => x.id === this.currentFactory.id) - 1;

    if(prevIndex === -1){
      prevIndex = factoriesList.length - 1;
    }

    this.selectFactory(factoriesList[prevIndex]);
  }

  selectNextLine(){
    const nextIndex:number =this.currentFactory.productionLines.findIndex(x => x.id === this.currentLine?.id) + 1;

    if(nextIndex === this.currentFactory.productionLines.length){
      this.selectLine();
    }
    else if(nextIndex === -1){
      this.selectLine(this.currentFactory.productionLines[0]);
    }
    else{
      this.selectLine(this.currentFactory.productionLines[nextIndex]);
    }

    // TODO: This way is cleaner
    // let nextIndex:number = (this.currentFactory.productionLines.findIndex(x => x.id == this.currentLine?.id) + 1) % this.currentFactory.productionLines.length;
    // this.selectLine(this.currentFactory.productionLines[nextIndex]);
  }

  selectPreviousLine(){
    const index:number =this.currentFactory.productionLines.findIndex(x => x.id === this.currentLine?.id);

    if(index === -1){
      this.selectLine(this.currentFactory.productionLines[this.currentFactory.productionLines.length - 1]);}
    else if(index === 0){
      this.selectLine();}
    else{
      this.selectLine(this.currentFactory.productionLines[index - 1]);}
  }

  isLinkVisible(page:string):boolean{
    if('Suppliers'){
      return this.isHomepage() || this.roleAuth?.readSupplierQuality ? true : false;
    }
    return true;
  }

  toggleBrowserNotifications(event:any){
    this.browserNotificationsEnabled = event.checked;
    localStorage.setItem("browserNotificationsEnabled", event.checked);
  }
}
