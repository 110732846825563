import { Component, ElementRef, HostListener, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { visitAll } from '@angular/compiler';
import { Product } from 'src/app/models/product.model';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Deviation, MissingStatus } from 'src/app/models/deviation.model';
import { Workcell } from 'src/app/models/workcell.model';
import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserRole } from 'src/app/models/userRole.model';
import { Role } from 'src/app/models/role.model';
import { FaultType } from 'src/app/models/faultType.model';
import { TranslateService } from '@ngx-translate/core';
import { FunctionGroup } from 'src/app/models/functionGroup.model';
import { Responsible } from 'src/app/models/responsible.model';
import { ActionType, DeviationHistory } from 'src/app/models/deviationHistory.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { THE_FAMILY_ID } from '@azure/msal-common/dist/utils/Constants';
import { ProductionLine } from 'src/app/models/productionLine.model';
import { ProductLocation } from 'src/app/models/productLocation.model';
import { ignoreElements, last, of, Subject, Subscription, takeUntil } from 'rxjs';
import { ProductView } from 'src/app/models/productView.model';
import { Part } from 'src/app/models/part.model';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Language } from 'src/app/models/language.enum';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { ThrottlingUtils } from '@azure/msal-common';
import { Factory } from 'src/app/models/factory.model';
import * as Diacritics from 'diacritics';
import { WorkcellView } from 'src/app/models/workcellView.model';
import { CheckView } from 'src/app/models/checkView.model';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Check } from 'src/app/models/check.model';
import { ProductService } from 'src/app/services/api/product.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserRolesService } from 'src/app/services/user-roles.service';


@Component({
  selector: 'app-deviations-list-page',
  templateUrl: './deviations-list-page.component.html',
  styleUrls: ['./deviations-list-page.component.less']
})
export class DeviationsListPageComponent implements OnInit {

  @HostListener('window:keydown.escape') escapeEvent() { if(!this.hasDeviationToChange && !this.hasDeviationToSolve && !this.isScanning){ this.closePopUp();}}
  @HostListener('window:keydown.enter') enterEvent() {
    if(this.searchIsFocus && !this.hasDeviationToChange){
      this.scrollToProduct(this.searchText);
      this.selectProductByScan();
    }
  }

  @HostListener('window:keyup.s') searchEvent() {
    if(!this.hasDeviationToSolve && !this.hasDeviationToChange && !this.hasCheckpointToCheck){
      this.toggleSearch();
    }
  }

  apiUrl:string =  environment.API_URL;
  roleAuth!:Role;
  isGenericUser = false;
  private unsubscribe$ = new Subject<void>();

  filter:string = "latest";
  products!:ProductView[];
  selectedProduct!:Product | null;
  selectedProductViewNotInList!:ProductView | null;
  detectionWorkcellList:Workcell[] = [];
  currentFactoryId = -1;
  currentFactory!:Factory;
  currentLineId = -1;
  currentLine!:ProductionLine;
  currentProductLocation!:ProductLocation | null;

  public chosenCheckpoint!: WorkcellView | null;
  private getChosenCheckpointSubscription!: Subscription;
  chosenCheckpointData!:Workcell;

  isSavingData = false;
  hasNoCorrespondingProduct = false;
  isSelectingProduct =false;
  isLoadingProducts = false;

  toSelectId: number | null = null;
  toSelectFabId: string | null = null;

  hasDeviationToChange = false;
  isFormDisabled = false;
  selectedDeviation!:Deviation | null;
  isOtherFactoriesDeviationsExpanded = false;
  otherFactoryWithDeviationsOfSelectedProduct!:Factory;

  willOpenSolvePopUP = false;
  hasDeviationToSolve = false;
  deviationToSolve!:Deviation;

  hasCheckpointToCheck = false;
  locationToCheck!:ProductLocation | null;
  cancelClosePopup = false;
  checkRelatedDeviations:Deviation[] = [];
  loadingCheckInformation = false;
  isSolvedRelatedDeviationExpanded = false;

  isProductListOpen = false;

  showLocatePopUp = false;

  //FORMS
  solveForm = new FormGroup({
    solver : new FormControl(''),
    solverId: new FormControl(''),
    solveComment : new FormControl('')
  });

  //useless ?
  @ViewChild('firstSolveInput') firstSolveInput!: ElementRef;

  //SEARCH PRODUCT FEATURE
  searchText = '';
  searchIsFocus = false;
  @ViewChildren('searchProductListOpen') searchOpenInputElement!: QueryList<ElementRef>;
  @ViewChildren('searchProductListClose') searchCloseInputElement!: QueryList<ElementRef>;

  //HANDLING ERRORS
  errorMessage = "";

  screenHeight!: number;
  screenWidth!: number;

  //NOTIFICATION MESSAGE
  messagePopupType = "";
  messagePopupTitle = "";
  messagePopupText = "";

  //USER SCANNED
  userScanned = "";
  isScanning = false;

  //CAMERA SCAN
  isCameraScanOpen = false;

  constructor(
    private httpClient:HttpClient,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private authService: AuthService,
    private translate: TranslateService,
    private _adapter: DateAdapter<any>,
    private clipboard: Clipboard,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _headerDataService: HeaderDataService,
    private productService:ProductService,
    private roleService:UserRolesService) {
      this.getScreenSize();
  }

  //KEYBOARD CONTROL
  @ViewChild('checkPopupContent') checkPopupContent!: ElementRef;

  onTabKeyDown(event:any){
    if (this.hasCheckpointToCheck) {
      const focusables = this.checkPopupContent.nativeElement.querySelectorAll('app-demo-button');
      const firstFocusableEl = focusables[0];
      const lastFocusableEl = focusables[focusables.length - 1];

      if (event.shiftKey && event.target === firstFocusableEl) {
        // if shift key is pressed and focus is on the first button, move focus to the last button
        event.preventDefault();
        lastFocusableEl.focus();
      } else if (!event.shiftKey && event.target === lastFocusableEl) {
        // if shift key is not pressed and focus is on the last button, move focus to the first button
        event.preventDefault();
        firstFocusableEl.focus();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    setTimeout(()=>{
      if(!this.hasDeviationToSolve && !this.hasDeviationToChange && !this.hasCheckpointToCheck){
        this.toggleSearch();
      }
    },100);
  }

  private navTitleToSet: string = 'Any title';

  //DEVIATION PAGE
  ngOnInit() {
    this.setCalendarLocal();
    this.filter = localStorage.getItem('devFilter') || "latest";

    this.activatedRoute.paramMap.subscribe((data:any)=>{
      if(!data.params.displayId){
        this.currentFactoryId = Number(data.params.factoryId);
        this.currentLineId = Number(data.params.lineId);
        this.getCurrentFactory(this.currentFactoryId);
      }
    });

    setTimeout(()=>{
      this.roleService.roles$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        if (res) {
          this.roleAuth = res.userRoles;
          this.isGenericUser = res.isGenericUser;
        }
      });
    }, 0);

    this.getChosenCheckpointSubscription = this._headerDataService.getChosenCheckpoint().subscribe(
      (chosenCheckpoint: [WorkcellView, number, number]) => {
          if(chosenCheckpoint[0].id !== undefined && chosenCheckpoint[1] !== -2 && chosenCheckpoint[2] !== -2 && 
            this.chosenCheckpoint?.id !== chosenCheckpoint[0].id && chosenCheckpoint[1] === this.currentFactoryId && chosenCheckpoint[2] === this.currentLineId){
              this.chosenCheckpoint = chosenCheckpoint[0];
              this.getProducts(this.currentLineId);
              this.getCheckpointData(this.chosenCheckpoint.id);
            }
      });

    this.isProductListOpen = localStorage.getItem('isProductListOpen') === 'true';
  }

  ngOnDestroy() {
    if (this.getChosenCheckpointSubscription) {
      this.getChosenCheckpointSubscription.unsubscribe();
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setCalendarLocal(){
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
  }

  getCurrentFactory(currentId:number){
    if(currentId == null){ return;}
    this.httpClient.get<Factory>(this.apiUrl + "Factory/" + currentId).subscribe(
      (res: any) => {
        this.currentFactory = res
      }
    );
  }

  //USER SCANNED
  hasIdentifiedUser():boolean{
    return this.isGenericUser ? this.userScanned !== "" : true;
  }

  getCheckpointData(checkpointId:number | undefined){
    if(checkpointId == null || Number.isNaN(checkpointId) || checkpointId === -1){ return;}

    this.httpClient.get<Workcell>(this.apiUrl + "Workcell/" + checkpointId).subscribe(
      (res: Workcell) => {
        this.chosenCheckpointData = res;
      }
    );
  }

  getUserId(){
    return this.authService.getUserId();
  }

  getActiveAccount(){
    return this.authService.getActiveAccount();
  }
  
  getProducts(lineId: any, autoselect = true): void {
    if (!lineId || !this.currentFactoryId) { return;}

    const chosenCheckpointId = this.chosenCheckpoint?.id ?? -1;
    this.products = [];
    this.isLoadingProducts = true;

    this.productService.getProducts(lineId, this.currentFactoryId, chosenCheckpointId).subscribe({
        next: (res) => this.handleProductResponse(res, autoselect),
        error: (e) => this.handleProductError(e),
    });
  }

  private handleProductResponse(products: any[], autoselect: boolean): void {
    console.log("product", products);
    this.products = products;
    this.sortBy(this.filter);

    if (localStorage.getItem('deviationDirectLink')) {
        this.handleDirectLink();
    } else if (autoselect) {
        this.handleAutoselect();
    }

    this.isLoadingProducts = false;
  }

  private handleDirectLink(): void {
    const directlink: any = JSON.parse(localStorage.getItem('deviationDirectLink')!);
    if(this.products.find(x=>x.id === directlink.product.id) != null){
      this.selectProductById(directlink.product.id, () => {
        this.openDeviationPopUp(undefined, this.selectedProduct?.deviations.find(x=> x.displayId === directlink.displayId));
        localStorage.removeItem('deviationDirectLink');
      });
    } else {
      this.selectProductByFabId(directlink.product.fabNumber, () => {
        this.openDeviationPopUp(undefined, this.selectedProduct?.deviations.find(x=> x.displayId === directlink.displayId));
        localStorage.removeItem('deviationDirectLink');
      });
    }
  }

  private handleAutoselect(): void {
    const lastSelectedProduct = localStorage.getItem("lastSelectedProduct");

    if (lastSelectedProduct) {
        this.searchText = JSON.parse(lastSelectedProduct);
        this.scrollToProduct(this.searchText);
        this.selectProductByScan();
    } else if (this.products.length > 0) {
        this.selectProduct(this.products[0]!);
    }
  }

  private handleProductError(error: any): void {
      if (error.status === 404) {
          this.errorMessage = "NO PRODUCT MESSAGE";
      } else if (error.status === 403) {
          this.errorMessage = "LINE UNKNOWN MESSAGE";
      }

      this.products = [];
      this.selectedProduct = null;
      this.isLoadingProducts = false;
  }

  filteredProducts(): ProductView[]{
    //special character and space ignore
    return this.products.filter((x:any) => this.getProductFullName(x)?.includes(this.searchText.replace(/[^a-zA-Z0-9]/g, "")));
  }

  currentFactoryDeviations():Deviation[]{
    if(this.selectedProduct == null){ return [];}
    return this.selectedProduct.deviations.filter(x=>x.factoryId === this.currentFactoryId);
  }

  otherFactoriesDeviations():Deviation[]{
    if(this.selectedProduct == null){ return [];}
    return this.selectedProduct.deviations.filter(x=>x.factoryId !== this.currentFactoryId);
  }

  otherFactoriesDeviationsSolved():Deviation[]{
    if(this.selectedProduct == null){ return [];}
    return this.selectedProduct.deviations.filter(x=>x.factoryId !== this.currentFactoryId && x.resolutionDate != null);
  }

  otherFactoriesDeviationsNotSolved():Deviation[]{
    if(this.selectedProduct == null){ return [];}
    return this.selectedProduct.deviations.filter(x=>x.factoryId !== this.currentFactoryId && x.resolutionDate == null);
  }

  getOtherFactoryName(id:number){
    if(id == null){ return;}
    this.httpClient.get<Factory>(this.apiUrl + "Factory/" + id).subscribe(
      (res: any) => {
        this.otherFactoryWithDeviationsOfSelectedProduct = res;
      }
    );
  }

  solveDeviation(deviation:Deviation){
        if(this.selectedProduct != null && this.selectedProduct.id === deviation.productId){
          this.selectedProduct.deviations.splice(this.selectedProduct.deviations.findIndex(x => x.id === deviation.id), 1, deviation);
          this.selectedProduct.deviations = [...this.selectedProduct.deviations];
        }
        //update status icon
        this.updateStatusIconProductList(deviation.productId);
        this.closePopUp();
        this.solveForm.reset();
        this.isSavingData = false;
  }

  updateStatusIconProductList(productId:number){
    if(productId == null){ return;}

    const impactedProduct = this.products.find(x=>x.id === productId);
    if(impactedProduct != null && this.selectedProduct?.id === productId){
      impactedProduct.hasOpenDeviations = this.hasOpenDeviation(this.selectedProduct);
    }else if(this.selectedProductViewNotInList?.id === productId && this.selectedProduct != null){
      this.selectedProductViewNotInList.hasOpenDeviations = this.hasOpenDeviation(this.selectedProduct)
    }
    this.products = [...this.products];
  }

  sortBy(sorting: string): void {
    switch (sorting) {
        case "status":
            this.products.sort(this.compareByStatus);
            break;
        case "AZ":
            this.products.sort(this.compareByName.bind(this));
            break;
        case "latest":
            this.products.sort(this.compareByLatest.bind(this));
            break;
    }

    this.filter = sorting;
    localStorage.setItem('devFilter', sorting);

    if (this.selectedProduct) {
        this.scrollToProduct(this.getProductFullName(this.selectedProduct)!);
    }
  }

  private compareByStatus(a:any, b:any): number {
    if (a.hasOpenDeviations && !b.hasOpenDeviations) {
        return -1;
    } else if (!a.hasOpenDeviations && b.hasOpenDeviations) {
        return 1;
    }
    return 0;
  }

  private compareByName(a: any, b: any): number {
    const nameA = this.getProductFullName(a)!;
    const nameB = this.getProductFullName(b)!;

    if (nameA < nameB) {return -1;}
    if (nameA > nameB) {return 1;}
    return 0;
  }

  private compareByLatest(a: any, b: any): number {
    // Both have check dates
    if (a.checkDate && b.checkDate) {
      return a.checkDate < b.checkDate ? -1 : 1;
    }

    // Both missing check dates: compare assembly dates and rank
    if (!a.checkDate && !b.checkDate) {
      return this.compareAssemblyDateAndRank(a, b);
    }

    // One has a check date, the other doesn't
    return a.checkDate ? -1 : 1;
  }

  private compareAssemblyDateAndRank(a: any, b: any):number{
    if (!a.assemblyDate) {return -1;}
    if (!b.assemblyDate) {return 1;}

    if (a.assemblyDate > b.assemblyDate) {return 1;}
    if (a.assemblyDate < b.assemblyDate) {return -1;}

    // If assembly dates are the same, compare rank
    return a.rank - b.rank;
  }

  selectProduct(selected:ProductView){
    this.selectedProductViewNotInList = null;
    this.selectProductById(selected.id);
  }

  selectProductByScan(){
    this.searchText = this.searchText.replace(/[^a-zA-Z0-9\s]/g, '');
    if(this.searchText.length > 11 && this.searchText[0].toUpperCase() === 'C'){
      this.searchText = this.searchText.substring(1);
    }
    this.searchText = this.searchText.replace(/\s/g, '');
    const tmpFilteredProducts:ProductView[] = this.filteredProducts();
    if(tmpFilteredProducts.length === 1){
      this.selectProductById(tmpFilteredProducts[0].id);
    }else{
      const toSelect:ProductView = this.products.find(p=>this.getProductFullName(p) === this.searchText)!;
    
      if(toSelect != null){
        this.selectProductById(toSelect.id);
      }else{
        this.selectProductByFabId(this.searchText);
      }
    }
    
    this.searchText = "";
  }

  selectProductById(toSelectId:number, callback?:() => void){
    if(toSelectId == null || this.currentFactoryId == null || this.currentLineId == null || toSelectId === this.toSelectId) { return; }
    this.isSelectingProduct = true;
    this.toSelectId = toSelectId;
    this.toSelectFabId = null;
    this.productService.getProductById(toSelectId, this.currentFactoryId, this.currentLineId).subscribe(
      (res: any) => {
        if (this.toSelectId !== toSelectId) { return; }
        console.log("selected product", res);
        this.handleSingleProductResponse(res, callback);
        this.toSelectId = null;
      }
    );
  }

  selectProductByFabId(toSelectFabId:string, callback?:() => void){
    if(toSelectFabId == null || this.currentFactoryId == null || this.currentLineId == null || toSelectFabId === this.toSelectFabId) { return; }
    this.isSelectingProduct = true;
    this.toSelectId = null;
    this.toSelectFabId = toSelectFabId;
    this.productService.getProductByFabNumber(toSelectFabId, this.currentFactoryId, this.currentLineId).subscribe(
      {
        next: (res: any) => {
          if (this.toSelectFabId !== toSelectFabId) { return; }
          console.log("selected product by fab id", res);
         
          const productChosenCheck:Check | undefined = res.checks.find((x:Check) => x.checkpointId === this.chosenCheckpoint?.id);
          this.selectedProductViewNotInList = new ProductView(res.fabNumber, res.controlLetter, res.id, this.hasOpenDeviation(res), res.assemblyDate, res.rank, res.chassisSeries, res.chassisNumber, productChosenCheck != null ? productChosenCheck.state: undefined, productChosenCheck != null ? productChosenCheck.checkDate: undefined);
          
          this.handleSingleProductResponse(res, callback);
          this.toSelectFabId = null;
        },
        error: (e) => {
          if (this.toSelectFabId !== toSelectFabId) { return; }
          this.selectedProduct = null;
          this.selectedProductViewNotInList = null;
          this.hasNoCorrespondingProduct = true;
          this.isSelectingProduct = false;
          this.toSelectFabId = null;
        }
      }
    );
  }

  handleSingleProductResponse(res: any, callback?:() => void) {
    this.selectedProduct = res;
    this.currentProductLocation = res.locations.find((x:ProductLocation) => x.currentProductionLineId === this.currentLineId);
    this.orderProductLocation(this.selectedProduct!);
    this.getProductLocationList();
    if(this.otherFactoriesDeviations().length > 0){
      this.getOtherFactoryName(this.otherFactoriesDeviations()[0].factoryId!);
    }
    this.hasNoCorrespondingProduct = false;
    this.isSelectingProduct = false;
    
    if(this.screenWidth <= 1100){
      this.isProductListOpen = false;
    }
    this.getDetectionWorkcellsList();

    localStorage.setItem("lastSelectedProduct", JSON.stringify(this.selectedProduct?.fabNumber))

    if(callback){ callback();}
  }

  getDetectionWorkcellsList( callback?:() => void){
    if(this.selectedProduct == null || this.selectedProduct.id == null || this.currentFactoryId == null || this.currentLineId == null) { return;}

    this.detectionWorkcellList = [];

    this.productService.getProductDetectionWorkcells(this.selectedProduct.id, this.currentFactoryId, this.currentLineId).subscribe(
      (res: Workcell[]) => {
        this.detectionWorkcellList = res;
        if(callback){ callback();}
      }
    );
  }

  hasOpenDeviation(product:Product){
    let hasOpenDeviation: boolean = false;
    product.deviations.forEach(dev => {
      if(dev.resolutionDate == null){
        hasOpenDeviation = true;
      }
    });

    return hasOpenDeviation;
  }

  openSolvePopUp(event:Event | null, d:Deviation, canSolve:boolean = false){
    if(event != null){
      event.stopPropagation();
    }

    if(d.resolutionDate != null || !this.roleAuth?.solveDeviation){ return;}

    this.hasDeviationToSolve = true;
    this.deviationToSolve = Object.assign({}, d);

    this.isSavingData = false;
  }

  openCheckPopUp(l:ProductLocation){
    if(!this.roleAuth.checkCheckpoint || this.selectedProduct == null || this.selectedProduct.id == null || l.currentProductionLineId == null){ return;}
  
    this.hasCheckpointToCheck = true;
    this.locationToCheck = l;
    this.isSavingData = false;
    this.loadingCheckInformation = true;

    this.productService.getProductCheckImpactingDeviations(this.selectedProduct.id, l.currentProductionLineId).subscribe(
      (res: Deviation[]) => {
        this.checkRelatedDeviations = res;
        this.loadingCheckInformation = false;
      }
    );

    setTimeout(()=>{
      this.checkPopupContent.nativeElement.querySelector('#cancel-check-button').focus();
    },100);
  }

  closePopUp(){
    if(!this.cancelClosePopup){
      this.hasDeviationToSolve = false;
      this.hasDeviationToChange = false;
      this.isFormDisabled = false;
      this.hasCheckpointToCheck = false;
      this.locationToCheck = null;
      this.selectedDeviation = null;
      this.solveForm.enable();
      this.isSolvedRelatedDeviationExpanded = false;
      setTimeout(()=>{
        this.toggleSearch();
      },100);
      this.closeMessagePopup();
    } else {
      this.cancelClosePopup = false;
    }
  }

  cancelClosePopUp(){
    this.cancelClosePopup =  true;
  }

  closeProductPopUp(deviation:Deviation | null){
    console.log("page creation deviation", deviation);
    if(deviation != null){
      const modifiedDeviationIndex = this.selectedProduct?.deviations.findIndex(x=>x.id === deviation.id);

      //if modification of the deviation
      if(modifiedDeviationIndex != null && modifiedDeviationIndex !== -1){
        this.selectedProduct?.deviations.splice(modifiedDeviationIndex, 1, deviation);
      }
      //if creation of the deviation
      else{
        console.log("page creation deviation 2", deviation);
        this.selectedProduct?.deviations.push(deviation);
        this.updateStatusIconProductList(deviation.productId);
      }

    }

    this.closePopUp();
  }

  isStatusOKAfterCheck(){
    for (let index = 0; index < this.checkRelatedDeviations.length; index++) {
      if(this.checkRelatedDeviations[index].resolutionDate == null){
        return false;
      }
    }
    return true;
  }

  relatedDeviationsNotOkay():Deviation[]{
    return this.checkRelatedDeviations.filter(x=>x.resolutionDate == null);
  }

  relatedDeviationsSolved():Deviation[]{
    return this.checkRelatedDeviations.filter(x=>x.resolutionDate != null);
  }

  check(){
    if(this.selectedProduct == null || this.locationToCheck == null || this.locationToCheck.currentProductionLineId == null || this.locationToCheck.currentWorkcellId == null || !this.roleAuth.checkCheckpoint || !this.hasIdentifiedUser() || this.isSavingData){ return;}

    this.isSavingData = true;
    
    this.productService.putProductCheck(this.selectedProduct.id, this.locationToCheck.currentProductionLineId, this.locationToCheck.currentWorkcellId, this.userScanned).subscribe(
    {
      next: (res:any) => {
        this.selectProductById(this.selectedProduct?.id!);
        this.getCheckpointData(this.chosenCheckpoint?.id);
        console.log("check res", res);

        const impactedProduct = this.products.find(x=>x.id === res.productId);
        if(impactedProduct != null && this.chosenCheckpoint != null && res.checkpointId === this.chosenCheckpoint.id){
          impactedProduct.checkState = res.state;
          impactedProduct.checkDate = res.checkDate;
        }
        else if(this.selectedProductViewNotInList != null && this.selectedProductViewNotInList.id === res.productId && this.selectedProduct != null && this.chosenCheckpoint && res.checkpointId === this.chosenCheckpoint.id){
          this.selectedProductViewNotInList.checkState = res.state;
          this.selectedProductViewNotInList.checkDate = res.checkDate;
        }
          
        this.products = [...this.products];
        this.sortBy(this.filter);

        this.scrollToProduct(this.getProductFullName(this.selectedProduct!)!);
        this.closePopUp();
        this.isSavingData = false;
      }
    });
  }

  //focus the search bar
  toggleSearch(){
    if(this.isProductListOpen){
      this.searchOpenInputElement.first.nativeElement.focus();
    }
    else{
      this.searchCloseInputElement.first.nativeElement.focus();
    }
  }


  openDeviationPopUp(event?:Event, deviation?:Deviation, disableForm:boolean = false){
    event?.stopPropagation();
    if(this.detectionWorkcellList.length === 0 && deviation == null){return;}

    this.hasDeviationToChange = true;

    console.log("open popup deviation",deviation);

    if(deviation?.resolutionDate != null && !this.roleAuth.modifySolvedDeviation){
      this.isFormDisabled = true;
    }
    else{
      this.isFormDisabled = disableForm;
    }

    this.selectedDeviation = deviation!;

    this.isSavingData = false;
  }

  openSolvePopUpAfterDeviation(event:any){
    this.openSolvePopUp(null, event.deviation);
  }

  orderProductLocation(product:Product){
    if(product == null){ return;}
    
    product.locations.sort((a:any, b:any) => {
      if (a.order > b.order) {
          return 1;
      }
      else if (a.order < b.order) {
          return -1;
      }
      return 0;
    });
  }

  getProductLocationList():ProductLocation[]{
    if(this.selectedProduct == null){
      return [];
    }
    
    if(this.currentLineId === -1){
      return this.selectedProduct.locations;
    }
    else{
      const productLocation:ProductLocation = this.selectedProduct.locations.find(x=>x.currentProductionLineId === this.currentLineId)!;
      return productLocation != null ? [productLocation] : [];
    }
  }

  notSensitive(s:string | null | undefined){
    if(s==null){ return "";}
    return Diacritics.remove(s).toLowerCase();
  }

  //LANGUAGE SETTING
  getFaultTypeRank2Name(faultType:FaultType | null){
    if(faultType == null){ return "";}
    if(this.translate.currentLang === Language.French){
      return faultType.rank2NameFR;}
    else if(this.translate.currentLang === Language.Dutch){
      return faultType.rank2NameNL;}
    else{
      return faultType.rank2NameEN;}
  }

  getFunctionFullPath(dev:Deviation){
    if(dev == null){ return "";}
    return (this.getTreeElementName(dev.functionGroupLevel1!) + (dev.functionGroupLevel2 != null ? " > " : "") +
             this.getTreeElementName(dev.functionGroupLevel2!) + (dev.functionGroupLevel3 != null ? " > " : "") +
             this.getTreeElementName(dev.functionGroupLevel3!) + (dev.functionGroupLevel4 !=  null ? " > " : "") +
             this.getTreeElementName(dev.functionGroupLevel4!)).replace(/^ >+/, '').trim();
  }

  getProblemDetailFullPath(dev:Deviation){
    if(dev == null){ return "";}
    return (this.getTreeElementName(dev.problemDetailLevel1!) + (dev.problemDetailLevel2 != null ? " > " : "") +
             this.getTreeElementName(dev.problemDetailLevel2!) + (dev.problemDetailLevel3 != null ? " > " : "") +
             this.getTreeElementName(dev.problemDetailLevel3!) + (dev.problemDetailLevel4 !=  null ? " > " : "") +
             this.getTreeElementName(dev.problemDetailLevel4!)).replace(/^ >+/, '').trim();
  }

  getResponsibleFullPath(dev:Deviation){
    if(dev == null){ return "";}
    return (this.getTreeElementName(dev.responsibleLevel2!) + (dev.responsibleLevel3 !=  null ? " > " : "") +
             this.getTreeElementName(dev.responsibleLevel3!)).replace(/^ >+/, '').trim();
  }

  //LANGUAGE SETTING
  getTreeElementName(f:FunctionGroup | Responsible){
    if(f == null){ return "";}

    if(this.translate.currentLang === Language.French){
      return f?.nameFR;}
    else if(this.translate.currentLang === Language.Dutch){
      return f?.nameNL;}
    else{
      return f?.nameEN;}
  }

  getFunctionNameWithCode(f:FunctionGroup):string{
    if(f == null){ return "";}

    if(f.code != null){
      return f.code + " " + this.getTreeElementName(f);}
    else{
      return this.getTreeElementName(f) || "";}
  }

  getPopUpTitle(){
    if(this.selectedDeviation == null){
      return "CREATE DEVIATION" ;}
    else if(this.selectedDeviation?.resolutionDate !== null){
      return "SOLVED DEVIATION INFO";}
    else if(!this.isFormDisabled){
      return "EDIT DEVIATION";}
    else if(this.isFormDisabled){
      return "DEVIATION INFO";}
    else{
      return "";}
  }

  toggleProductList(){
    this.isProductListOpen = !this.isProductListOpen;
    localStorage.setItem("isProductListOpen", JSON.stringify(this.isProductListOpen));
    if(!this.isProductListOpen){
      setTimeout(()=>{
        this.searchCloseInputElement.first.nativeElement.focus();
      },200);
    }     
  }

  scrollToProduct(productName:string){
    setTimeout(()=>{
      const elementInScrollableDiv = document.getElementById("vi-" + productName);
      const positionFromTopOfScrollableDiv = elementInScrollableDiv?.offsetTop;
      const scrollableDivElement = document.getElementById('list-vi');

      if(positionFromTopOfScrollableDiv != null && scrollableDivElement != null){
        scrollableDivElement.scrollTo({
          top: positionFromTopOfScrollableDiv - scrollableDivElement?.offsetTop,
          behavior: 'smooth'
        });
      }
      
    },100);
  }

  getProductFullName(p:Product | ProductView){
    if(p == null){ return;}

    return p.fabNumber + p.controlLetter;
  }

  //MESSAGE POPUP
  displayMessagePopup(event:any){
    this.messagePopupType = event.type;
    this.messagePopupTitle = event.title;
    this.messagePopupText = event.text;
  }

  closeMessagePopup(){
      setTimeout(() => {
        this.messagePopupTitle = '';
      }, 1000);
  }

  //CLIPBOARD
  copyDirectLink(displayId:string, event?:any){
    event?.stopPropagation();
    const httpName = window.location.href.split("/", 5)[2].includes("localhost") ? 'http://' : 'https://';
    const link = httpName + window.location.href.split("/", 5)[2] + '/deviations-list/displayid/' + displayId;
    this.clipboard.copy(link);
  }

  //CAMERA SCAN 
  closeBarcodeScanner(event:any){
    this.isCameraScanOpen = false;
    if(event){
      this.searchText = event;
      this.searchOpenInputElement.first.nativeElement.focus();
      this.scrollToProduct(this.searchText);
      this.selectProductByScan();
    }
  }
}
