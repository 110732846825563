import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccountInfo } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { Factory } from 'src/app/models/factory.model';
import { Language } from 'src/app/models/language.enum';
import { PartViewModel } from 'src/app/models/partViewModel.model';
import { Role } from 'src/app/models/role.model';
import { Supplier } from 'src/app/models/supplier.model';
import { SupplierQuality, SupplierQualityStatus } from 'src/app/models/supplierQuality.model';
import { UserRole } from 'src/app/models/userRole.model';
import { environment } from 'src/environments/environment';
import * as Diacritics from 'diacritics';
import { SupplierQualityStep, SupplierQualityStepTitle } from 'src/app/models/supplierQualityStep.model';
import { SupplierQualityFile } from 'src/app/models/supplierQualityFile.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { SupplierQualityHistoryLine, SupplierQualityHistoryType } from 'src/app/models/supplierQualityHistoryLine.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserRolesService } from 'src/app/services/user-roles.service';
import { Subject, takeUntil } from 'rxjs';

const apiUrlSupplierFile = 'SupplierQuality/File/';

@Component({
  selector: 'app-suppliers-page',
  templateUrl: './suppliers-page.component.html',
  styleUrls: ['./suppliers-page.component.less']
})
export class SuppliersPageComponent {

  @HostListener('window:keydown.escape') escapeEvent() {
      this.closePopUp();
  }
  
  apiUrl:string =  environment.API_URL;
  roleAuth!:Role;
  isGenericUser = false;
  private unsubscribe$ = new Subject<void>();  
  
  isProductListOpen:boolean = true;

  filters:number[] = [];
  suppliers:SupplierQuality[] = [];
  filteredSuppliers!:any[];
  selectedSupplier!:SupplierQuality | null;

  currentFactory!:Factory;
  currentFactoryId = -1;

  currentLineId = -1;
  urlSupplierCaseId = -1;

  viewProblemSolving = false;
  hasSupplierToCreate = false;
  hasSupplierToEdit = false;
  hasSupplierToAssign = false;
  hasSupplierToClose = false;

  cancelClosePopup = false;

  isCreatingSupplier = false;
  isSavingData = false;
  isSavingStep = -1;
  isLoadingData = false;

  //FORMS
  supplierForm = new FormGroup({
    title: new FormControl(),
    partId : new FormControl(''),
    supplierParmaCode: new FormControl(''),
    comment: new FormControl(),
  });

  @ViewChild('firstMPInput') firstMPInput!: ElementRef;

  //SEARCH PRODUCT FEATURE
  searchText = '';
  searchIsFocus = false;
  @ViewChildren('search') searchInputElement!: QueryList<ElementRef>;

  //HANDLING ERRORS
  errorMessage = "";
  errorMessageSelectedSQ = "";

  screenHeight!: number;
  screenWidth!: number; 

  //NOTIFICATION MESSAGE
  messagePopupType = "";
  messagePopupTitle = "";
  messagePopupText = "";

   //PROBLEM SOLVING
   SupplierQualityStepTitle = SupplierQualityStepTitle;

   //FILES
   fileToRemove:any = null;

  constructor(
    private httpClient:HttpClient,
    private activatedRoute:ActivatedRoute,
    private authService:AuthService,
    private roleService:UserRolesService,
    private translate: TranslateService,
    private clipboard: Clipboard,
    ) {
      this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((data:any)=>{
      this.currentFactoryId = Number(data.params.factoryId);
      this.urlSupplierCaseId = Number(data.params.supplierCaseId);
      this.getSupplierQualities(this.currentFactoryId, this.urlSupplierCaseId == null || Number.isNaN(this.urlSupplierCaseId));
      this.getMissingPartOptions();
    })    
    
    this.roleService.roles$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res) {
        this.roleAuth = res.userRoles;
        this.isGenericUser = res.isGenericUser;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getActiveAccount(): AccountInfo | null{
    return this.authService.getActiveAccount();
  }

  getSupplierQualities(factoryId:any, autoselect = true){
    if(this.currentFactoryId == null){ return;}

    this.httpClient.get<SupplierQuality[]>(this.apiUrl + 'SupplierQuality/ByFactoryId/' + factoryId).subscribe(
      {
        next: (res) => {
          console.log("suppliers", res);
          this.suppliers = res;
          this.filters = localStorage.getItem('sqFilter') != null ? JSON.parse(localStorage.getItem('sqFilter')!) : [0, 2, 3];           
          this.filterSuppliers(autoselect);
      
          if(this.urlSupplierCaseId !== -1 && this.urlSupplierCaseId != null && !Number.isNaN(this.urlSupplierCaseId)){
            if(this.suppliers.find(x=>x.id === this.urlSupplierCaseId)){
              this.selectSupplierCaseById(this.urlSupplierCaseId);
            }
            else{
              this.errorMessageSelectedSQ = "SUPPLIER QUALITY CASE NOT ON THIS FACTORY"
            }
            window.history.replaceState("", "", "/suppliers/" + this.currentFactoryId + "/" + this.currentLineId);
          }
        },
        error: (e) => {
          if(e.status === 404){
            this.errorMessage = "NO MISSING PART MESSAGE";
          }
          this.suppliers = [];
          this.filteredSuppliers = [];
          this.selectedSupplier = null;
        }
      }
    );
  }
  
  selectSupplierCase(selected:SupplierQuality){
    this.selectedSupplier = selected;
    this.selectSupplierCaseById(selected.id!)
  }

  selectSupplierCaseById(toSelectId:number){
    this.errorMessageSelectedSQ = '';
    this.httpClient.get<SupplierQuality>(this.apiUrl + 'SupplierQuality/'+ toSelectId).subscribe(
      (res: any) => {
        console.log("supplier selected", res);
        this.selectedSupplier = res;

        console.log(this.screenWidth)
        if(this.screenWidth <= 1100){
          this.isProductListOpen = false;
        }
      }
    );
  }

  setFilter(filterStatusIndex:number){
    if(this.suppliers == null){ return;}

    if(this.filters.includes(filterStatusIndex)){
      this.filters.splice(this.filters.indexOf(filterStatusIndex), 1);
    }
    else{
      this.filters.push(filterStatusIndex);
    }
    
    localStorage.setItem("sqFilter", JSON.stringify(this.filters));
    this.filterSuppliers(true);
  }

  filterSuppliers(autoselect = false){
    if(this.suppliers == null){ return;}

    if(this.filters.length === 0){
      this.filteredSuppliers = this.suppliers;
    }
    else{
      this.filteredSuppliers = [];
      this.filters.forEach(filter => {
        this.filteredSuppliers = this.filteredSuppliers.concat(this.suppliers.filter(sq => sq.status === filter));
      });
    }

    this.orderFilteredSuppliers();

    if(autoselect && this.filteredSuppliers.length !== 0){
      this.selectSupplierCase(this.filteredSuppliers[0]);
    }
  }

  orderFilteredSuppliers(){
    this.filteredSuppliers.sort((a:SupplierQuality, b:SupplierQuality) => {        
      if(a.creationDate == null){
        return -1;
      }
      else if(b.creationDate == null){
        return 1;
      }
      else if (a.creationDate >= b.creationDate) {
          return -1;
      }
      else if (a.creationDate < b.creationDate) {
          return 1;
      }
      return 0;
    });    
  }

  
  closePopUp(){
    if(!this.cancelClosePopup){
      this.viewProblemSolving = false;
      this.hasSupplierToCreate = false;
      this.hasSupplierToEdit = false;
      this.hasSupplierToAssign = false;
      this.hasSupplierToClose = false;
      this.selectedPartOption = null;
      this.fileToRemove = null;
      this.showSupplierQualityHistory = false;
      this.inputPartFocused = false;
      this.toBeSelectedPartOption = null;
      this.supplierForm.enable();
      this.closeMessagePopup();
    }else{
      this.cancelClosePopup = false;
    }
  }

  cancelClosePopUp(){
    this.cancelClosePopup =  true;
  }

  openSupplierPopUp(supplier?:SupplierQuality){
    if(supplier != null && supplier.partId != null){
      this.hasSupplierToEdit = true;
      this.getSuppliers(supplier.partId, this.currentFactoryId);
    }
    else{
      this.hasSupplierToCreate = true;
      this.selectedPartPossibleSuppliers = [];
    }

    this.setFormValues(supplier);
    this.isCreatingSupplier=false;
    

    setTimeout(()=>{
      this.firstMPInput.nativeElement.focus();
    },100);
  }

  openProblemSolvingPopUp(){
    this.viewProblemSolving = true;
  }

  onSupplierFormSubmit() {
    if(this.hasSupplierToCreate){
      this.createSupplierPart();
    }
    else if(this.hasSupplierToEdit){
      this.modifySupplier();
    }
  }

  createSupplierPart(){
    if(this.isCreatingSupplier){ return;}

    const newSupplier:SupplierQuality = {
      "comment":this.supplierForm.value.comment || null,
      "part":null,
      "partId": this.supplierForm.value.partId!,
      "title":this.supplierForm.value.title == null || this.supplierForm.value.title === "" ? this.supplierForm.value.partId! : this.supplierForm.value.title!,
      "factoryId":Number(this.currentFactoryId),
      "supplierParmaCode": this.supplierForm.value.supplierParmaCode || null,
      "supplier":null,
      "ownerEmployeeId": null,
      "ownerName": null,
      "ownerEmail": null,
      "steps":null,
      "stakeholders":null,
      "files":null,
      "impactedProducts":null,
      "workloads":null,
    }

    this.isCreatingSupplier = true;

    console.log("supplier creation", newSupplier);
    
    this.httpClient.post<SupplierQuality>(this.apiUrl + 'SupplierQuality', newSupplier).subscribe(
      {
        next: (res) => {
          this.closePopUp();
          this.selectedSupplier = res;
          this.suppliers.push(this.selectedSupplier);

          if(!this.filters.includes(SupplierQualityStatus.Potential)){
            this.setFilter(SupplierQualityStatus.Potential);}
          else{
            this.filterSuppliers(true);}
          
          this.isCreatingSupplier = false;
          this.displayMessagePopup({type:"success", title:"SAVED", text:"SAVED CORRECTLY"});
        },
        error: (e) => {
          this.isCreatingSupplier = false;
          this.displayMessagePopup({type:"error", title:"SOMETHING WRONG", text:"PLS RETRY"});
        }
      }
    );
  }

  modifySupplier(){
    if(this.selectedSupplier == null){ return;}

    this.isSavingData = true;

    if(this.supplierForm.value.partId != null){
      this.selectedSupplier.partId = this.supplierForm.value.partId;}

    this.selectedSupplier.title = this.supplierForm.value.title!;
   
    this.selectedSupplier.supplierParmaCode = this.supplierForm.value.supplierParmaCode || null;
    this.selectedSupplier.supplier = null;

    this.selectedSupplier.comment = this.supplierForm.value.comment || null;


    const elementToSend = Object.assign({}, this.selectedSupplier);
    elementToSend.steps = null;

    console.log("send new supplier quality", elementToSend, this.isSavingData);
    this.httpClient.put<SupplierQuality>(this.apiUrl + 'SupplierQuality', elementToSend).subscribe(
      {
        next: (res) => {
          this.selectedSupplier = res;
          
          // this.updateStatusIconMissingPartList(res.id!, res.missingPartStatus, res.title, res.partId);

          if(this.selectedSupplier?.id === res.id){
            this.selectedSupplier = res;
          }

          this.isSavingData = false;
          this.closePopUp();
          this.displayMessagePopup({type:"success", title:"SAVED", text:"SAVED CORRECTLY"});
        },
        error: (e) => {
          this.isSavingData = false;
          this.displayMessagePopup({type:"error", title:"SOMETHING WRONG", text:"PLS RETRY"});
          console.error(e);
        }
      }
    );
  }
  
  toggleProductList(){
    this.isProductListOpen = !this.isProductListOpen;
  }

  //MESSAGE POPUP
  displayMessagePopup(event:any){
    this.messagePopupType = event.type;
    this.messagePopupTitle = event.title;
    this.messagePopupText = event.text;
  }
  
  closeMessagePopup(){
    setTimeout(() => {
      this.messagePopupTitle = '';
    }, 1000);
  }

  //TRANSLATION
  getStateTranslation(stateId:number){
    switch (stateId) {
      case 0:
        return "NEW";
      case 1:
        return "ASSIGNED";
      case 2:
        return "CLOSED";
      default:
        return "";
    }
  }

  getPopUpButtonText(){
    if(this.hasSupplierToCreate){
      return "CREATE SUPPLIER CASE";
    }
    
    return "SAVE";
  }

  getSupplierStatusTranslation(status:SupplierQualityStatus | undefined):string{
    if(status == null) {return "";}

    switch (status) {
      case SupplierQualityStatus.Potential:
        return "POTENTIAL";
      case SupplierQualityStatus.ToBeConfirmed:
        return "TO BE CONFIRMED";
      case SupplierQualityStatus.InProgress:
        return "IN PROGRESS";
      case SupplierQualityStatus.Closed:
          return "CLOSED";
      default:
        return "test";
    }
  }

  getDescriptionTranslation(){
    if(this.selectedSupplier == null || this.selectedSupplier.part == null){ return "";}

    switch (this.translate.currentLang) {
      case Language.French:
        return this.selectedSupplier.part.descriptionFR;
      case Language.Dutch:
        return this.selectedSupplier.part.descriptionNL;
      default:
        return this.selectedSupplier.part.descriptionEN;
    }
  }

  setFormValues(supplier?:SupplierQuality){
    this.supplierForm.reset();
    
    if(supplier != null && this.selectedSupplier != null){
      this.supplierForm.setValue({
        partId: this.selectedSupplier.partId,
        title: this.selectedSupplier.title,
        supplierParmaCode : this.selectedSupplier.supplierParmaCode || "",
        comment : this.selectedSupplier.comment,
      });

      this.findPartOption();

      if(this.selectedSupplier.status === SupplierQualityStatus.Closed){
        this.supplierForm.controls['partId'].disable();
      }
    }else{
      this.supplierForm.setValue({
        partId: "",
        title: "",
        supplierParmaCode:"",
        comment : ""
      });
    }

  }

  findPartOption(){
    this.selectedPartOption = this.partOptions.find(x => x.partId === this.selectedSupplier?.partId)!;
  }

  //PART ID FIELD
  inputPartFocused = false;
  toBeSelectedPartOption:PartViewModel | null = null;
  partOptions:PartViewModel[] = [];
  selectedPartOption:PartViewModel | null = null;
  optionsLimit = 100;
  isPartDuplicate = false;

  //POSSIBLE SUPPLIERS
  selectedPartPossibleSuppliers:Supplier[] = [];
  suppliersLoaded = true;

  getMissingPartOptions(){
    if(this.currentFactoryId == null){ return;}
    const lang = localStorage.getItem('language') != null ? localStorage.getItem('language') : Language.English;

    this.httpClient.get<PartViewModel[]>(this.apiUrl + 'MissingPart/Parts/ByFactoryId/' + this.currentFactoryId + "/" + lang).subscribe(
      (res: PartViewModel[]) => {
        this.partOptions = res;
      }
    );
  }

  isSelectedPartOptionFullyDisplayed():boolean{
    return this.selectedPartOption != null && 
            this.selectedPartOption.rtAlias != null && this.selectedPartOption.rtAlias.trim() !== "" &&
            this.selectedPartOption.description != null && this.selectedPartOption.description.trim() !== "";
  }

  blurPartField(){
    this.inputPartFocused = false;
    this.toBeSelectedPartOption = null;
    const mp:PartViewModel = this.partOptions.find(x => x.partId === this.supplierForm.value.partId)!;

    if(mp != null){
      this.selectedPartOption = mp;
      this.getSuppliers(mp.partId, this.currentFactoryId);
    }
    else {
      this.selectedPartOption = null;
      this.selectedPartPossibleSuppliers = [];
    }
  }

  filteredPartOptions(): PartViewModel[]{
    if(this.supplierForm.value.partId == null || this.supplierForm.value.partId === ""){ return this.partOptions;}
    return this.partOptions.filter((x:any) => 
              this.notSensitive(x.partId).includes(this.notSensitive(this.supplierForm.value.partId)) ||
              this.notSensitive(x.rtAlias).includes(this.notSensitive(this.supplierForm.value.partId)) ||
              this.notSensitive(x.description).includes(this.notSensitive(this.supplierForm.value.partId)));
  }

  removeOptionsLimit(){
    this.optionsLimit = Infinity;
  }

  notSensitive(s:string | null | undefined){
    if(s==null){ return "";}
    return Diacritics.remove(s).toLowerCase();
  }

  selectPreviousOption(){
    const allOptions: PartViewModel[] = this.filteredPartOptions();

    if(this.toBeSelectedPartOption == null){
      this.toBeSelectedPartOption = allOptions[allOptions.length - 1];
    }
    else{
      const index:number = allOptions.findIndex(x => x.partId === this.toBeSelectedPartOption?.partId);

      if(index - 1 >= 0){
        this.toBeSelectedPartOption = allOptions[index - 1];
      }
      else{
        this.toBeSelectedPartOption = allOptions[allOptions.length - 1];
      }
    }
  }

  selectNextOption(){
    const allOptions: PartViewModel[] = this.filteredPartOptions();

    if(this.toBeSelectedPartOption == null){
      this.toBeSelectedPartOption = allOptions[0];
    }
    else{
      const index:number = allOptions.findIndex(x => x.partId === this.toBeSelectedPartOption?.partId);

      if(index + 1 < allOptions.length){
        this.toBeSelectedPartOption = allOptions[index + 1];
      }
      else{
        this.toBeSelectedPartOption = allOptions[0];
      }
    }      
  }


  chooseOption(event:Event){
    event.stopPropagation();

    if(this.toBeSelectedPartOption != null){
      this.selectPartIdOption(this.toBeSelectedPartOption);
      this.blurPartField();
    }
  }

  selectPartIdOption(el:PartViewModel){
    this.supplierForm.controls['partId'].setValue(el.partId);
    
    if(this.supplierForm.value.title == null || this.supplierForm.value.title === ""){
      this.supplierForm.controls['title'].setValue(el.description);
      this.supplierForm.controls['title'].updateValueAndValidity();
    }

    this.selectedPartOption = el;
  }

  //SUPPLIERS OF A PART 
  getSuppliers(partId:string, factoryId:number){
    if(partId == null || factoryId == null){ return;}
    this.selectedPartPossibleSuppliers = [];
    this.suppliersLoaded = false;

    this.httpClient.get<any>(this.apiUrl + 'MissingPart/Suppliers/ByPartAndFactory/' + partId + '/' + factoryId).subscribe(
    {
      next: (res:Supplier[]) => {
        if(this.selectedSupplier?.supplierParmaCode != null && this.selectedSupplier.supplier != null
          && res.find(x=> x.parmaCode === this.selectedSupplier?.supplierParmaCode) == null){
            res.push(this.selectedSupplier.supplier);
        }

        this.selectedPartPossibleSuppliers = this.sortSuppliers(res);
        this.setSupplierField();      
        this.suppliersLoaded = true;
      },
      error: (e) => {
        this.suppliersLoaded = true;
      }
    }
    );
  }

  sortSuppliers(suppliers:Supplier[]):Supplier[]{
    return suppliers.sort((a:Supplier, b:Supplier) => {
      if(a.POB != null && b.POB != null){
        return this.compareByPOBAndDate(a,b);}
      else if(a.POB == null && b.POB == null){
        return this.compareByDate(a,b);}
      else{
        return a.POB == null ? 1 : -1;} 
    });
  }

  private compareByPOBAndDate(a: Supplier, b: Supplier): number {
    if (a.POB !== b.POB) {
        return a.POB! > b.POB! ? -1 : 1;
    }
    return this.compareByDate(a, b);
  }

  private compareByDate(a: Supplier, b: Supplier): number {
      return a.modificationDate > b.modificationDate ? -1 : 1;
  }

  setSupplierField(){
  }
//END PART ID FIELD

//Stakeholder
  assignedStakeholder(event:any){
    const modifiedSupplierIndex = this.suppliers.findIndex(x=>x.id === this.selectedSupplier?.id);
    if(modifiedSupplierIndex != null && modifiedSupplierIndex !== -1){
      this.suppliers?.splice(modifiedSupplierIndex, 1, event.case);
    }

    this.selectSupplierCase(this.selectedSupplier!);
    this.filterSuppliers();
    this.isSavingData =false;
  }

  //ASSIGN TO ME
  assignSupplierQuality(event:any){
    if(this.selectedSupplier == null || this.translate.currentLang == null){ return;}
    this.isSavingData = true;

    this.httpClient.put<SupplierQuality>(this.apiUrl + 'SupplierQuality/AssignToMe/' + this.selectedSupplier.id, null).subscribe(
      res => {
        this.closePopUp();
        
        const modifiedSupplierIndex = this.suppliers.findIndex(x=>x.id === this.selectedSupplier?.id);
        if(modifiedSupplierIndex != null && modifiedSupplierIndex !== -1){
          this.suppliers?.splice(modifiedSupplierIndex, 1, res);
        }

        this.filterSuppliers();
        this.selectedSupplier = res;

        this.isSavingData = false;
      }
    );
  }

  openAssignPopUp(){
    this.hasSupplierToAssign = true;
    this.isSavingData = false;
  }

  openClosePopUp(){
    if(!this.canCloseSupplier()){ return; }
    this.hasSupplierToClose = true;
    this.isSavingData = false;
  }

  canCloseSupplier():boolean{
    return !this.isSavingData && this.isOwner();
  }

  isOwner():boolean{
    return this.selectedSupplier != null ? this.selectedSupplier.ownerEmployeeId === this.getActiveAccount()?.idTokenClaims?.['mailNickName'] : false;
  }

  closeSupplierQuality(){
    console.log(this.selectedSupplier == null || !this.canCloseSupplier());
    if(this.selectedSupplier == null || !this.canCloseSupplier()){ return;}

    this.isSavingData = true;
    
    this.httpClient.put<SupplierQuality>(this.apiUrl + 'SupplierQuality/Close/' + this.selectedSupplier.id, null).subscribe(
      {
        next: (res) => {
          this.closePopUp();
          console.log("closing",res);

          this.updateStatusIconSupplierQualityList(res.id!, res.status!);
          if(this.selectedSupplier?.id === res.id){
            this.selectedSupplier = res;
          }

          this.isSavingData =false;
        },
        error: (e) => {
          
        }
      }
    );
  }

  updateStatusIconSupplierQualityList(supplierQualityId:number, newStatus:SupplierQualityStatus, newTitle?:string, newPartId?:string){
    const impactedSQ = this.suppliers.find(x=>x.id === supplierQualityId);

    if(impactedSQ != null){
      impactedSQ.status = newStatus;

      if(newTitle != null && newPartId != null){
        impactedSQ.title = newTitle;
        impactedSQ.partId = newPartId;
      }
      
    }

    this.suppliers = [...this.suppliers];
  }



  //PROBLEM SOLVING STATUS
  isPreviousStepClosed(index:number):boolean{
    if(index === 0){
      return true;
    }
    else if(this.selectedSupplier != null && this.selectedSupplier.steps != null && this.selectedSupplier.steps[index - 1] != null){
      return this.selectedSupplier?.steps[index - 1].closed!;
    }
    else{
      return true;
    }
  }
  
  isNextStepClosed(index:number):boolean{
    if(this.selectedSupplier == null || this.selectedSupplier.steps == null){
      return false;
    }
    else if(this.selectedSupplier.steps[index - 1] != null){
      return this.selectedSupplier.steps[index - 1].closed;
    }
    else{
      return false;
    }
  }
  
  callSaveStep(step:SupplierQualityStep, callback?:() => void) {
    this.httpClient.put<SupplierQualityStep>(this.apiUrl + 'SupplierQuality/Step', step).subscribe(
    {
      next: (res) => {
        this.isSavingStep = -1;
        if (callback) {
          callback();
        }
      },
      error: (e) => {
        console.error(e);   
      }
    });
  }
  
  saveStep(event:any, step:SupplierQualityStep){
    this.isSavingStep = step.stepIndex;
    step.comment = event.comment === "" ? null : event.comment;
    this.callSaveStep(step);
  }
  
  callCloseStep(step:SupplierQualityStep, callback?:() => void) {
    this.httpClient.put<SupplierQualityStep>(this.apiUrl + 'SupplierQuality/Step/Close', step).subscribe(
    {
      next: (res) => {
        this.isSavingStep = -1;
        if(this.selectedSupplier != null && this.selectedSupplier.id === res.supplierQualityId && this.selectedSupplier.steps != null){
          this.selectedSupplier.steps[res.stepIndex - 1] = res;
        }
        if (callback) {
          callback();
        }
      },
      error: (e) => {
        console.error(e)
      }
    });
  }
  
  closeStep(event:any, step:SupplierQualityStep){
    this.isSavingStep = step.stepIndex;
    step.comment = event.comment === "" ? null : event.comment;
    this.callCloseStep(step); 
  }
  
  reOpenStep(event:any, step:SupplierQualityStep){
      if(this.selectedSupplier == null || step == null){ return;}
  
      this.isSavingStep = step.stepIndex;
  
      this.httpClient.put<any>(this.apiUrl + 'SupplierQuality/Step/Reopen/' + this.selectedSupplier.id + '/' + step.stepIndex, null).subscribe(
        {
          next: (res) => {
            this.isSavingStep = -1;
            if(this.selectedSupplier != null && this.selectedSupplier.id === res.supplierQualityId && this.selectedSupplier.steps != null){
              this.selectedSupplier.steps[res.stepIndex - 1] = res;
            }
          },
          error: (e) => {
            console.error(e);
            this.isSavingStep = -1;
          }
        }
      );
    }

    addFileToStep(event:any, step:SupplierQualityStep){
      if(this.selectedSupplier == null || this.selectedSupplier.id == null || step == null){ return;}
      const files: File[] = event.target.files;
      const formData:FormData = new FormData();
  
      for(let i = 0; i < files.length; i++){
        formData.append('files', files[i]);
      }
  
      this.httpClient.post<any[]>(this.apiUrl + 'SupplierQuality/Step/File/' + this.selectedSupplier.id + '/' + step.stepIndex, formData).subscribe(
        res => {
          for(let j = 0; j < res.length; j++){
            step.files = [...step.files!, res[j]];
          }
        }
      );
    }

    //Supplier Quality files
    getSelectedSupplierFile():SupplierQualityFile[]{
      return this.selectedSupplier != null && this.selectedSupplier.files != null ? this.selectedSupplier.files : [];
    }

    addFiles(event:any){
      if(this.selectedSupplier == null || this.selectedSupplier.id == null){ return;}
      
      const files: File[] = event.target.files;
      const formData:FormData = new FormData();
  
      for(let i = 0; i < files.length; i++){
        formData.append('files', files[i]);
      }
  
      this.httpClient.post<any[]>(this.apiUrl + apiUrlSupplierFile + this.selectedSupplier.id, formData).subscribe(
        res => {
          if(this.selectedSupplier != null){
            for(let j = 0; j < res.length; j++){
              this.selectedSupplier.files = [...this.selectedSupplier.files!, res[j]];
            }
          }
        }
      );
    }

    downloadFile(file:SupplierQualityFile){
      if(file == null || file.fileName == null){ return;}
      const token = 'my JWT';
      const headers = new HttpHeaders().set('authorization','Bearer '+token);

      this.isSavingData = true;
  
      this.httpClient.get(this.apiUrl + apiUrlSupplierFile + file.fileName,{headers, responseType: 'blob' as 'json'}).subscribe(
          (response: any) =>{
            this.isSavingData = false;
              const dataType = response.type;
              const binaryData = [];
              binaryData.push(response);
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              if (file.displayName){
                  downloadLink.setAttribute('download', file.displayName);
              }
              document.body.appendChild(downloadLink);
              downloadLink.click();
          }
      );
    }

    openRemoveConfirm(file:any){
      this.fileToRemove = file;
    }

    removeFile(){
      if(this.fileToRemove == null || this.fileToRemove.fileName == null){ return;}
      this.isSavingData = true;
  
      this.httpClient.delete(this.apiUrl + apiUrlSupplierFile + this.fileToRemove.fileName).subscribe(
        res => {
          this.isSavingData = false;
          if(this.selectedSupplier != null && this.selectedSupplier.files != null){
            this.selectedSupplier.files.splice(this.selectedSupplier.files.indexOf(this.fileToRemove), 1);
          }
          this.closePopUp();
        }
      );
    }

  //CLIPBOARD
  copyDirectLink(id:string | number, event?:any){
    event?.stopPropagation();
    const httpName = window.location.href.split("/", 5)[2].includes("localhost") ? 'http://' : 'https://';
    const link = httpName + window.location.href.split("/", 5)[2] + '/suppliers/' + this.currentFactoryId + '/' + this.currentLineId + '/' + id;
    this.clipboard.copy(link);
  }

  //HISTORY
  showSupplierQualityHistory = false;
  loadingSupplierQualityHistory = false;
  supplierQualityHistories:SupplierQualityHistoryLine[] = [];

  showHistory(){
    this.showSupplierQualityHistory = !this.showSupplierQualityHistory;

    if(this.showSupplierQualityHistory){
      this.getSupplierQualityHistory(this.selectedSupplier?.id);
    }
  }

  getSupplierQualityHistory(sqId:number | undefined){
    if(sqId == null || this.translate.currentLang == null){ return;}

    this.loadingSupplierQualityHistory = true;
    this.errorMessage = "";

    this.httpClient.get<SupplierQualityHistoryLine[]>(this.apiUrl + 'SupplierQualityHistory/BySupplierQualityId/' + sqId + '/' + this.translate.currentLang).subscribe(
      {
        next: (res) => {
          this.loadingSupplierQualityHistory = false;
          this.supplierQualityHistories = res;
        },
        error: (e) => {
          if(e.status === 404 || e.status === 403){
            this.supplierQualityHistories = [];
            this.loadingSupplierQualityHistory = false;
            this.errorMessage = "NO HISTORY AVAILABLE";
          }
        }
      }
    );
  }

  getHistoryValue(value:string, property:string){
    if(value == null || value === ""){
      return "ø";
    }
      
    return value;
  }

  isHistoryValueDate(property:string, value:string){
    if((value == null || value !== "") && 
      (property === "PlannedMissingDate" || property === "PlannedArrival")){ return true;}

    return false;
  }
  
  isCreation(actionType:number):boolean{
    return actionType === SupplierQualityHistoryType.Creation;
  }

  isUpdate(actionType:number):boolean{
    return actionType === SupplierQualityHistoryType.Update;
  }

  isAddition(actionType:number):boolean{
    return actionType === SupplierQualityHistoryType.Addition;
  }

  isDeletion(actionType:number):boolean{
    return actionType === SupplierQualityHistoryType.Deletion;
  }

  getHistoryPropertyName(property:string){
    const prefixRemovedProperty = property.includes('/') ? property.split('/', 2)[1] : property;

    const propertyMapping: { [key: string]: string } = {
      "MaterialControllerEmployeeId":"MATERIAL CONTROLLER EMPLOYEE ID",
      "MaterialControllerName":"MATERIAL CONTROLLER NAME",
      "PartId": "PARTID",
      "Title": "TITLE",
      "Comment": "COMMENT",
      "SupplierParmaCode":"SUPPLIER PARMA CODE",
      "ImpactedProduct":"IMPACTED PRODUCT",
      "Stakeholder":"STAKEHOLDER",
      "OwnerEmployeeId":"OWNER EMPLOYEE ID",
      "OwnerName":"OWNER NAME",
      "OwnerEmail":"OWNER EMAIL",
      "Status":"STATUS",
      "File":"FILE",
      "Workload":"WORKLOAD",
    };

    return propertyMapping[prefixRemovedProperty] || prefixRemovedProperty;
  }

  getHistoryPrefixName(property:string){
    if(!property.includes('/')){ return ''; }

    const prefix = property.split('/', 2)[0] || '';
    const match = (property.split('/', 2)[0] || '').match(/([a-zA-Z]+)(\d+)/);

    const propertyMapping: { [key: string]: string } = {
      "Step":"STEP",
      "Workload":"WORKLOAD",
      "File":"FILE",
    };

    return match != null ? propertyMapping[match[1]] : (propertyMapping[prefix] || prefix);
  }

  getHistoryPrefixIndex(property:string){
    if(!property.includes('/')){ return ''; }

    const match = (property.split('/', 2)[0] || '').match(/([a-zA-Z]+)(\d+)/);
    const number = match != null && match[2] ? parseInt(match[2], 10) : '';

    return " " + number + " / ";
  }
}
