import { Component, EventEmitter, HostListener, Inject, Input, Output, SimpleChanges} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AccountInfo } from '@azure/msal-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MissingPartStepFile } from 'src/app/models/missingPartStepFile.model';
import { MissingCause } from 'src/app/models/missingCause.model';
import { MissingPartDelivered } from 'src/app/models/missingPartDelivered.model';
import { MissingPart } from 'src/app/models/missingPart.model';
import { MissingPartHistory } from 'src/app/models/missingPartHistory.model';
import { MissingPartOrdered } from 'src/app/models/missingPartOrdered.model';
import { DateFormatDisplay, Language } from 'src/app/models/language.enum';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Supplier } from 'src/app/models/supplier.model';
import { SupplierQuality } from 'src/app/models/supplierQuality.model';
import { BaseStepFile } from 'src/app/models/baseStepFile.model';
import { SupplierQualityStepFile } from 'src/app/models/supplierQualityStepFile.model';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-problem-solving-step',
  templateUrl: './problem-solving-step.component.html',
  styleUrls: ['./problem-solving-step.component.less']
})
export class ProblemSolvingStepComponent {

  @HostListener('window:keydown.escape') escapeEvent() { 
      this.closePopup(); 
  }

  apiUrl:string =  environment.API_URL;

  @Input() title = "No text"; //any string you want
  @Input() employeeId = ""; //any string you want
  @Input() name  = ""; //any string you want
  @Input() closed = false; //closed (true) or not close (false)
  @Input() comment = ""; //any string you want
  @Input() stepIndex = -1;
  @Input() previousStepClosed = true;
  @Input() nextStepClosed = false;
  @Input() files?:BaseStepFile[] = [];
  
  @Input() missingCauses?:MissingCause[];
  @Input() missingCauseLastChildRequired?:boolean;
  @Input() selectedMissingCauseId: number | null | undefined;

  @Input() suppliers?:Supplier[];

  @Input() partsDelivered?:MissingPartDelivered[];
  @Input() partsOrdered?:MissingPartOrdered[];
  @Input() currentMissingPart:MissingPart | null | undefined;
  @Input() currentSupplierQuality:SupplierQuality | null | undefined;
  @Input() missingPartHistories:MissingPartHistory[] | null | undefined;
  @Input() partLabel?:string;
  @Input() isSavingData = false;
  @Input() disabled = false; //disable the form, the button, the import of files

  @Output() saveEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() reOpenEvent = new EventEmitter<any>();
  @Output() addEvent = new EventEmitter<any>();

  isCloseConfirmOpen = false;
  isRemoveConfirmOpen = false;
  isDeletePartDeliveredOpen = false;
  isPartDeliveredOpen = false;
  partDeliveredToEdit?:MissingPartDelivered | null;

  isDeletePartOrderedOpen = false;
  isPartOrderedOpen = false;
  partOrderedToEdit?:MissingPartOrdered | null;

  fileToRemove:any = null;
  isLoadingFile = false;

  errorMessage = "";
  

  stepForm = new FormGroup({
    comment : new FormControl(''),
    cause : new FormControl(''),
    supplier : new FormControl('')
  });

  partDeliveredForm = new FormGroup({
    amountDelivered : new FormControl(),
    deliveryAddress: new FormControl('')
  });

  partOrderedForm = new FormGroup({
    amountOrdered : new FormControl(),
    promisedDeliveryDate : new FormControl(),
    promisedDeliveryHour : new FormControl(),
    promisedDeliveryMinute : new FormControl(),
    promisedDeliveryPeriod : new FormControl(),
    orderedBy : new FormControl('')
  });

  constructor(
    private authService:AuthService,
    private httpClient:HttpClient,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ){}

  ngOnInit(): void {
    this.setFormValues();
    this._locale = localStorage.getItem('language')!;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['files']) {
        this.isLoadingFile = false;
    }
  }

  getActiveAccount(): AccountInfo | null{
    return this.authService.getActiveAccount();
  }

  ngAfterViewInit(){
    this.setFormValues();
  }

  getDateFormatString(): string {
    if (this._locale === Language.English){
      return DateFormatDisplay.English;}
    else if (this._locale === Language.French){
      return DateFormatDisplay.French;}
    else if(this._locale === Language.Dutch){
      return DateFormatDisplay.Dutch;}

    return '';
  }

  is12HourFormat(): boolean {
    return (this._locale === Language.English);
  }

  getHoursList(): number[] {
    return this.is12HourFormat() ? Array.from({ length: 12 }, (_, i) => i + 1) : Array.from({ length: 24 }, (_, i) => i);
  }

  getMinutesList(): number[] {
    return Array.from({ length: 60 }, (_, i) => i);
  }

  setFormValues(){
    this.stepForm.setValue({
      comment : this.comment,
      cause : "",
      supplier : this.suppliers != null && this.suppliers.length === 1 ? this.suppliers[0].parmaCode :this.currentMissingPart?.supplierParmaCode || "",
    });

    if(this.closed || this.disabled){
      this.stepForm.disable();
    }
  }

  canCloseStep():boolean{
    return !this.closed && this.previousStepClosed && this.isOwner()
            && (this.missingCauses == null || (this.selectedMissingCauseId != null && this.hasCauseFilledOut()))
            && (this.suppliers == null || this.suppliers.length === 0 || (this.stepForm.value.supplier != null && this.stepForm.value.supplier !== ""));
  }

  isOwner():boolean{
    return this.employeeId === this.getActiveAccount()?.idTokenClaims?.['mailNickName'];
  }

  isMissingPartOwner():boolean{
    if(this.currentMissingPart != null){
      return this.currentMissingPart.ownerEmployeeId === this.getActiveAccount()?.idTokenClaims?.['mailNickName'];}
    if(this.currentSupplierQuality != null){
      return this.currentSupplierQuality.ownerEmployeeId === this.getActiveAccount()?.idTokenClaims?.['mailNickName'];}

    return false;
  }

  hasCauseFilledOut(){
    if(this.selectedMissingCauseId == null && this.missingCauses != null){ return false;}

    const selectedMissingCause = this.missingCauses?.find(x=>Number(x.id) === this.selectedMissingCauseId);

    if(selectedMissingCause == null){ return false;}

    if(!this.missingCauseLastChildRequired){ return true;}

    if(this.isCauseLevel(1, selectedMissingCause)){
      return this.missingCauses?.find(x=>x.level1 === selectedMissingCause?.level1 && x.id !== selectedMissingCause.id) == null;}
    else if(this.isCauseLevel(2, selectedMissingCause)){
      return this.missingCauses?.find(x=>x.level2 === selectedMissingCause?.level2 && x.id !== selectedMissingCause.id) == null;}
    else if(this.isCauseLevel(3,selectedMissingCause)){
      return true;}
    else{
      return false;}
  }

  isCauseLevel(level:number, cause:MissingCause){
    const levels = [cause.level1, cause.level2, cause.level3];

    // Ensure levels up to the given level are non-empty
    const isValid = levels.slice(0, level).every(l => l != null && l !== "");
    // Ensure levels beyond the given level are empty
    const isBeyondEmpty = levels.slice(level).every(l => l == null || l === "");

    return isValid && isBeyondEmpty;
  }

  saveStep(){
    this.comment = this.stepForm.value.comment || "";
    if(this.missingCauses != null){
      this.saveEvent.emit({comment:this.comment, missingCauseId:this.selectedMissingCauseId, supplier:this.stepForm.value.supplier || ""});}
    else{
      this.saveEvent.emit({comment:this.comment, supplier:this.stepForm.value.supplier || ""});}
  }

  openCloseConfirm(){
    if(this.canCloseStep()){
      this.isCloseConfirmOpen = true;
    }
  }

  closeStep(){
    this.comment = this.stepForm.value.comment || "";
    if(this.missingCauses != null){
      this.closeEvent.emit({comment:this.comment, missingCauseId:this.selectedMissingCauseId, supplier:this.stepForm.value.supplier || ""});}
    else{
      this.closeEvent.emit({comment:this.comment, supplier:this.stepForm.value.supplier || ""});}
    this.isCloseConfirmOpen = false;
  }

  canReOpenStep():boolean{
    return this.closed && !this.nextStepClosed && this.isOwner();
  }

  reOpenStep(){
    if((this.currentMissingPart == null && this.currentSupplierQuality == null) || !this.isMissingPartOwner() || this.stepIndex === -1){ return;}

    this.reOpenEvent.emit();
  }

  addFiles(event:any){    
    this.addEvent.emit(event);
    this.isLoadingFile = true;
  }

  downloadFile(file:BaseStepFile){
    if(file == null || file.fileName == null){ return;}
    const token = 'my JWT';
    const headers = new HttpHeaders().set('authorization','Bearer '+token);

    const url = (file as MissingPartStepFile).missingPartId != null ? 'MissingPart/Step/File/' : 'SupplierQuality/Step/File/';

    this.httpClient.get(this.apiUrl + url + file.fileName,{headers, responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (file.displayName){
                downloadLink.setAttribute('download', file.displayName);
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    );
  }

  openRemoveConfirm(file:any){
    this.isRemoveConfirmOpen = true;
    this.fileToRemove = file;
  }

  closePopup(){
    this.isCloseConfirmOpen = false;
    this.isRemoveConfirmOpen = false;
    this.isPartDeliveredOpen = false;
    this.isPartOrderedOpen = false;
    this.isDeletePartDeliveredOpen = false;
    this.isDeletePartOrderedOpen = false;
    this.fileToRemove = null;
    this.errorMessage = "";
  }

  removeFile(){
    if(this.fileToRemove == null || this.fileToRemove.fileName == null){ return;}

    const url = (this.fileToRemove as MissingPartStepFile).missingPartId != null ? 'MissingPart/Step/File/' : 'SupplierQuality/Step/File/';

    this.httpClient.delete(this.apiUrl + url + this.fileToRemove.fileName).subscribe(
      res => {
        this.files?.splice(this.files?.indexOf(this.fileToRemove), 1);
        this.closePopup();
      }
    );
  }

  getMissingCauseFromList(id:number):MissingCause{
    return this.missingCauses?.find((x:MissingCause) => Number(x.id) === id)!;
  }

  setSelectedMissingCause(missingCauseId:number){
    this.selectedMissingCauseId = missingCauseId;
  }

  //Part delivered
  sumMissingPartDelivered(){
    let sum:number = 0;
    this.partsDelivered?.forEach(part => {
      sum += part.deliveredAmount;
    });
    return sum;
  }

  openDeletePartDeliveredPopup(partDelivered:MissingPartDelivered | any | null = null){
    this.isDeletePartDeliveredOpen = true;
    this.partDeliveredToEdit = partDelivered;
  }

  openPartDeliveredPopup(partDelivered:MissingPartDelivered | any | null = null){
    this.isPartDeliveredOpen = true;
    this.partDeliveredToEdit = partDelivered;
    this.setPartDeliveredForm();
  }

  openDeletePartOrderedPopup(partOrdered:MissingPartOrdered | any | null = null){
    this.isDeletePartOrderedOpen = true;
    this.partOrderedToEdit = partOrdered;
  }

  openPartOrderedPopup(partOrdered:MissingPartOrdered | any | null = null){
    this.isPartOrderedOpen = true;
    this.partOrderedToEdit = partOrdered;
    this.setPartOrderedForm();
  }

  setPartDeliveredForm(){
    this.partDeliveredForm.reset();
    if(this.partDeliveredToEdit != null){
      this.partDeliveredForm.setValue({
        amountDelivered: this.partDeliveredToEdit.deliveredAmount,
        deliveryAddress: this.partDeliveredToEdit.deliveryAddress
      });
    }
  }
  setPartOrderedForm(){
    this.partOrderedForm.reset();
    if(this.partOrderedToEdit != null){
      
      const currentTimezone = JSON.parse(localStorage.getItem("currentFactory")!)?.timeZoneId;
      const d = new Date(moment.tz(this.partOrderedToEdit.promisedDeliveryDate, 'UTC').tz(currentTimezone).format('YYYY-MM-DDTHH:mm:ss[Z]'));
      const timeZoneDifference = (d.getTimezoneOffset() / 60) * -1; //convert to positive value.
      d.setTime(d.getTime() - (timeZoneDifference * 60) * 60 * 1000);

      const pdd = d;

      this.partOrderedForm.setValue({
        amountOrdered: this.partOrderedToEdit.orderedAmount,
        promisedDeliveryDate: this.partOrderedToEdit.promisedDeliveryDate,
        promisedDeliveryHour: this.is12HourFormat() ? this.convertTo12Hour(pdd.getHours()).hour : pdd.getHours(),
        promisedDeliveryMinute: pdd.getMinutes(),
        promisedDeliveryPeriod: this.is12HourFormat() ? this.convertTo12Hour(pdd.getHours()).period : null,
        orderedBy:this.partOrderedToEdit.orderedBy
      });
    } else {
      this.partOrderedForm.patchValue({
        promisedDeliveryHour: this.is12HourFormat() ? 12 : 0,
        promisedDeliveryMinute: 0,
        promisedDeliveryPeriod: this.is12HourFormat() ? 'AM' : null
      });
    }
  }

  submitPartDeliveredForm(){
    if(this.partDeliveredToEdit == null){
      this.createPartDelivered();
    }
    else{
      this.modifyPartDelivered();
    }
  }

  submitPartOrderedForm(){
    if(this.partOrderedToEdit == null){
      this.createPartOrdered();
    }
    else{
      this.modifyPartOrdered();
    }
  }

  createPartDelivered(){
    if(this.currentMissingPart == null || Number.isNaN(this.partDeliveredForm.value.amountDelivered)){ return;}

    if(Number(this.partDeliveredForm.value.amountDelivered) <= 0){
      this.errorMessage = "ERROR DELIVERY PART AMOUNT ZERO";
      return;
    }else if(Number(this.partDeliveredForm.value.amountDelivered) > this.currentMissingPart.totalMissingPartsToBeDeliveredAmount!){
      this.errorMessage = "ERROR DELIVERY PART AMOUNT TOO HIGH";
      return;
    }

    const newMissingPartDelivered:MissingPartDelivered = {
      'missingPartId': Number(this.currentMissingPart.id),
      'deliveredAmount':Number(this.partDeliveredForm.value.amountDelivered),
      'deliveryAddress':this.partDeliveredForm.value.deliveryAddress || "",
    };

    this.isSavingData = true;

    this.httpClient.post<any>(this.apiUrl + 'MissingPart/Delivered', newMissingPartDelivered).subscribe(
      {
        next: (res) => {
          this.partsDelivered?.push(res.missingPartDelivered);
          if(this.currentMissingPart != null){
            this.currentMissingPart.totalMissingPartsToBeDeliveredAmount = res.totalMissingPartsToBeDeliveredAmount;}
          this.isSavingData = false;
          this.closePopup();
        },
        error: (e) => {
          console.error(e);
          this.errorMessage = "SOMETHING WENT WRONG";
          this.isSavingData = false;
        }
      }
    );
  }
  createPartOrdered(){
    if(this.currentMissingPart == null || Number.isNaN(this.partOrderedForm.value.amountOrdered)
     || (this.partOrderedForm.value.promisedDeliveryDate === "" || this.partOrderedForm.value.promisedDeliveryDate == null)){ return;}

    const newMissingPartOrdered:MissingPartOrdered = {
      'missingPartId': Number(this.currentMissingPart.id),
      'orderedAmount':Number(this.partOrderedForm.value.amountOrdered),
      'promisedDeliveryDate':new Date(this.partOrderedForm.value.promisedDeliveryDate),
      'orderedBy':this.partOrderedForm.value.orderedBy || ''
    };

    let hours = Number(this.partOrderedForm.value.promisedDeliveryHour);
    if (this.is12HourFormat()) {
      hours = this.convertTo24Hour(hours, this.partOrderedForm.value.promisedDeliveryPeriod);
    }
    const minutes = Number(this.partOrderedForm.value.promisedDeliveryMinute);
    newMissingPartOrdered.promisedDeliveryDate.setHours(hours, minutes);

    const currentTimezone = JSON.parse(localStorage.getItem("currentFactory")!)?.timeZoneId;
    newMissingPartOrdered.promisedDeliveryDate = new Date(moment(newMissingPartOrdered.promisedDeliveryDate)
                                                .parseZone()
                                                .tz(currentTimezone, true)
                                                .toISOString(true))

    this.isSavingData = true;

    this.httpClient.post<any>(this.apiUrl + 'MissingPart/Ordered', newMissingPartOrdered).subscribe(
      {
        next: (res) => {
          this.partsOrdered?.push(res);
          this.isSavingData = false;
          this.closePopup();
        },
        error: (e) => {
          console.error(e);
          this.isSavingData = false;
        }
      }
    );
  }

  deletePartDelivered(){
    if(this.partDeliveredToEdit == null || this.partDeliveredToEdit.id == null){ return;}
    this.isSavingData = true;
    this.httpClient.delete(this.apiUrl + 'MissingPart/Delivered/' + this.partDeliveredToEdit.id).subscribe(
      res => {
        this.isSavingData = false;
        if(this.partsDelivered != null){
          this.partsDelivered.splice(this.partsDelivered.findIndex(x => x.id === this.partDeliveredToEdit?.id), 1);}
        if(this.currentMissingPart != null){
          this.currentMissingPart.totalMissingPartsToBeDeliveredAmount = Number(res);}
        this.closePopup();
      }
    );
  }
  deletePartOrdered(){
    if(this.partOrderedToEdit == null || this.partOrderedToEdit.id == null){ return;}
    this.isSavingData = true;
    this.httpClient.delete(this.apiUrl + 'MissingPart/Ordered/' + this.partOrderedToEdit.id).subscribe(
      res => {
        this.isSavingData = false;
        if(this.partsOrdered != null){
          this.partsOrdered.splice(this.partsOrdered.findIndex(x => x.id === this.partOrderedToEdit?.id), 1);}
        this.closePopup();
      }
    );
  }

  modifyPartDelivered(){
    if(this.partDeliveredToEdit == null){ return;}

    if(Number(this.partDeliveredForm.value.amountDelivered) <= 0){
      this.errorMessage = "ERROR DELIVERY PART AMOUNT ZERO";
      return;
    }

    const sum = this.currentMissingPart?.partsDelivered?.reduce((acc, p) => p.id !== this.partDeliveredToEdit?.id ? acc + p.deliveredAmount : acc, 0) || 0;

    if(this.currentMissingPart != null && (Number(this.partDeliveredForm.value.amountDelivered) + sum) > this.currentMissingPart.totalMissingPartsAmount!){
      this.errorMessage = "ERROR DELIVERY PART AMOUNT TOO HIGH";
      return;
    }

    const modifiedPartDelivered = Object.assign({}, this.partDeliveredToEdit);

    modifiedPartDelivered.deliveredAmount = Number(this.partDeliveredForm.value.amountDelivered);
    modifiedPartDelivered.deliveryAddress = this.partDeliveredForm.value.deliveryAddress || "";
    this.isSavingData = true;

    this.httpClient.put<any>(this.apiUrl + 'MissingPart/Delivered', modifiedPartDelivered).subscribe(
      {
        next: (res) => {
          this.partsDelivered?.splice(this.partsDelivered.findIndex(x=>x.id === res.missingPartDelivered.id), 1, res.missingPartDelivered);
          if(this.currentMissingPart != null){
            this.currentMissingPart.totalMissingPartsToBeDeliveredAmount = res.totalMissingPartsToBeDeliveredAmount;}
          this.isSavingData = false;
          this.closePopup();
        },
        error: (e) => {
          console.error(e);
          this.errorMessage = "SOMETHING WENT WRONG";
          this.isSavingData = false;
        }
      }
    );
  }
  modifyPartOrdered(){
    if(this.partOrderedToEdit == null || (this.partOrderedForm.value.promisedDeliveryDate === "" || this.partOrderedForm.value.promisedDeliveryDate == null)){ return;}

    const modifiedPartOrdered = Object.assign({}, this.partOrderedToEdit);

    modifiedPartOrdered.orderedAmount = Number(this.partOrderedForm.value.amountOrdered);
    modifiedPartOrdered.promisedDeliveryDate = new Date(this.partOrderedForm.value.promisedDeliveryDate);
    
    let hours:number = Number(this.partOrderedForm.value.promisedDeliveryHour);
    if (this.is12HourFormat()) {
      hours = this.convertTo24Hour(hours, this.partOrderedForm.value.promisedDeliveryPeriod);
    }
    const minutes = Number(this.partOrderedForm.value.promisedDeliveryMinute);
    modifiedPartOrdered.promisedDeliveryDate.setHours(hours, minutes);

    const currentTimezone = JSON.parse(localStorage.getItem("currentFactory")!)?.timeZoneId;
    modifiedPartOrdered.promisedDeliveryDate = new Date(moment(modifiedPartOrdered.promisedDeliveryDate)
                                                .parseZone()
                                                .tz(currentTimezone, true)
                                                .toISOString(true))

    modifiedPartOrdered.orderedBy = this.partOrderedForm.value.orderedBy || "";
    this.isSavingData = true;

    this.httpClient.put<any>(this.apiUrl + 'MissingPart/Ordered', modifiedPartOrdered).subscribe(
      {
        next: (res) => {
          this.partsOrdered?.splice(this.partsOrdered.findIndex(x=>x.id === res.id), 1, res);
          this.isSavingData = false;
          this.closePopup();
        },
        error: (e) => {
          console.error(e);
          this.isSavingData = false;
        }
      }
    );
  }

  convertTo12Hour(hour: number): { hour: number, period: 'AM' | 'PM' } {
    const period = hour >= 12 ? 'PM' : 'AM';
    const adjustedHour = hour % 12 || 12;
    return { hour: adjustedHour, period };
  }

  convertTo24Hour(hour: number, period: 'AM' | 'PM'): number {
    if (period === 'PM' && hour < 12) {
      return hour + 12;
    }
    if (period === 'AM' && hour === 12) {
      return 0;
    }
    return hour;
  }

  partsPopupTitle(type:string){
    if(this.partDeliveredToEdit == null && this.partOrderedToEdit == null){
      return "ADD PARTS " + type;
    }
    else if(this.isDeletePartDeliveredOpen || this.isDeletePartOrderedOpen){
      return "DELETE PARTS " + type;
    }
    else{
      return "EDIT PARTS " + type;
    }
  }

  //History
  getCommentHistories(){
    if(this.missingPartHistories == null){ return [];}

    const commentHistories: MissingPartHistory[] = [];

    this.missingPartHistories.forEach(historyEl => {
      if(historyEl.propertyName.includes("Comment")){
        commentHistories.push(historyEl);
      }
    });

    return commentHistories;
  }
}
