import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Deviation, MissingStatus } from 'src/app/models/deviation.model';
import { ActionType, DeviationHistory } from 'src/app/models/deviationHistory.model';
import { FaultType } from 'src/app/models/faultType.model';
import { Language } from 'src/app/models/language.enum';
import { PartViewModel } from 'src/app/models/partViewModel.model';
import { Product } from 'src/app/models/product.model';
import { Responsible } from 'src/app/models/responsible.model';
import { Role } from 'src/app/models/role.model';
import { Workcell } from 'src/app/models/workcell.model';
import { environment } from 'src/environments/environment';
import * as Diacritics from 'diacritics';
import { FunctionGroupView } from 'src/app/models/functionGroupView.model';
import { FunctionGroup } from 'src/app/models/functionGroup.model';
import { ProductView } from 'src/app/models/productView.model';
import { DeviationLocation } from 'src/app/models/deviationLocation.model';
import { LocatePopupComponent } from '../locate-popup/locate-popup.component';
import { MatSelect } from '@angular/material/select';
import { finalize } from 'rxjs';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';
import { ProductService } from 'src/app/services/api/product.service';
import { TagsListComponent } from '../tag/tags-list/tags-list.component';
import { DeviationTagLink } from 'src/app/models/deviationTagLink.model';

@Component({
  selector: 'app-deviation-popup',
  templateUrl: './deviation-popup.component.html',
  styleUrls: ['./deviation-popup.component.less']
})
export class DeviationPopupComponent {
  @HostListener('window:keydown.escape') escapeEvent() { if(this.isPopupOpen && !this.showLocatePopUp && !this.isScanning) { this.closePopUp(); } }

  apiUrl:string =  environment.API_URL;

  @Input() selectedProduct!:Product | null;
  @Input() selectedDeviation!:Deviation | null;
  @Input() isPopupOpen = false;
  @Input() currentFactoryId = -2;
  @Input() allowUnknownOperator = false;
  @Input() operatorScanningEnabled = false;
  @Input() isGenericUser = false;
  @Input() currentLineId = -2;
  @Input() activeAccount!:any;
  @Input() roleAuth!:Role;
  @Input() canActivateSaveAndSolve = false;
  @Input() canActivateSaveAndConfirm = false;
  @Input() quantityVisible = false;
  @Input() problemDetailEnabled = false;
  @Input() locationEnabled = false;
  @Input() displayRTAlias = false;
  @Input() showLoadingUntilDataUpdated = false; // after saving deviation : false will show the spinner until it's saved, true will show the spinner until the parent decide
  @Input() isParentDataUpdated = false; // after saving deviation : false will show the spinner until it's saved, true will show the spinner until the parent decide
  
  @Output() closePopupEvent = new EventEmitter<any>();
  @Output() confirmPopupEvent = new EventEmitter<any>();
  @Output() solvePopupEvent = new EventEmitter<any>();
  @Output() messagePopupEvent = new EventEmitter<any>();

  faultTypes:FaultType[] = [];
  faultTypesLoaded = false;
  responsibles:Responsible[] = [];
  responsiblesLoaded = false;

  showDeviationHistory = false;
  loadingDeviationHistory = false;
  deviationHistories!:DeviationHistory[];

  workcellsList:Workcell[] = [];
  detectionWorkcellList:Workcell[] = [];
  isLoadingDetectionWorkcells = false;

  errorMessage = "";
  isSavingData = false;
  isFormDisabled = false;
  isFormValid = true;

  solveForm = new FormGroup({
    solver : new FormControl(''),
    solverId: new FormControl(''),
    solveComment : new FormControl('')
  });

  deviationForm = new FormGroup({
    creator: new FormControl(''),
    detectionWorkcell : new FormControl(),
    comment : new FormControl(''),
    responsible : new FormControl(),
    faultType : new FormControl(),
    function : new FormControl(''),
    problemDetail : new FormControl(''),
    missingParts : new FormControl(''),
    quantity : new FormControl(),
    severity:new FormControl(),
    solver : new FormControl(''),
    solvedComment : new FormControl('')
  });

  @ViewChild('firstDeviationInput') firstDeviationInput!: MatSelect;
  @ViewChild('afterTreeInput') afterTreeInput!: ElementRef;

  @ViewChild(LocatePopupComponent) locatePopupElement!:LocatePopupComponent;
  @ViewChild(TagsListComponent) tagListComponent!: TagsListComponent;

  //MISSING PART
  bomOptions:PartViewModel[] = [];
  missingPartOptions:PartViewModel[] = [];
  inputMpFocused = false;
  selectedMissingPartOption!:PartViewModel | null;
  toBeSelectedMissingPartOption!:PartViewModel | null;
  isInputMissingPartInBOM = false;
  isInputMissingPartInParts = false;
  cancelMissingPartBlur = false;

  //SEARCH FUNCTION
  functionsViewOfLine!:FunctionGroupView[];
  selectedFunction!: number | null;
  functionsView: FunctionGroupView[] = [];
  lineFunctionsLoaded = false;
  functionsLoaded = true;
  functionsList = [{id:1, name:"test"}];

  selectedFunctionId!:number | null;

  selectedProblemDetail!: number | null;
  selectedProblemDetailId!:number | null;

  showLocatePopUp:boolean = false;
  selectedLocations!:DeviationLocation[] | null;

  //RESPONSIBLE
  selectedResponsibleId!:number | null;

  //WARNING
  warningFunctionField = false;
  warningProblemDetailField = false;
  warningResponsibleField = false;


  //USER SCANNED
  userScanned = "";
  isScanning = false;

  constructor(
    private httpClient:HttpClient,
    private translate: TranslateService,
    private productService: ProductService) {
  }

  ngOnInit(){
    this.getProductionLineFunctionList(this.currentLineId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isPopupOpen'] && changes['isPopupOpen'].currentValue) {
        this.openPopUp(null, this.selectedDeviation);
    }

    if(changes['isParentDataUpdated'] && !changes['isParentDataUpdated'].currentValue){
        this.closePopUp(null, false);
        this.isSavingData = false;
    }
    
  }

  closePopUp(deviation?:Deviation | null, emitEvent = true){
    this.isPopupOpen = false;
    this.selectedDeviation = null;
    this.detectionWorkcellList = [];
    this.faultTypes = [];
    this.missingPartOptions = [];
    this.bomOptions = [];
    this.responsibles = [];
    this.functionsList = [];
    this.functionsView = [];
    this.faultTypesLoaded = false;
    this.responsiblesLoaded = false;
    this.showDeviationHistory = false;
    this.inputMpFocused = false;
    this.toBeSelectedMissingPartOption = null;
    this.warningFunctionField = false;
    this.warningProblemDetailField = false;
    this.warningResponsibleField = false;
    this.isFormValid = true;
    this.deviationForm.reset();
    if(emitEvent){
      this.closePopupEvent.emit(deviation);
    }
  }

  closeLocatePopUp(event:any){
    this.selectedLocations = event.locations;
    this.showLocatePopUp = false;
  }

  openLocatePopUp(){
    if(this.deviationForm.controls['detectionWorkcell'].value == null || this.deviationForm.controls['detectionWorkcell'].value === '') { return; }
    this.showLocatePopUp=true;
  }

  openPopUp(event?:Event | null, deviation?:Deviation | null, disableForm = false){
    event?.stopPropagation();
    this.isPopupOpen = true;
     
    if(!((deviation?.resolutionDate == null && this.roleAuth.modifyDeviation) || (deviation?.resolutionDate != null && this.roleAuth.modifySolvedDeviation))){
      this.isFormDisabled = true;
    }
    else {
      this.isFormDisabled = disableForm;
    }

    this.selectedDeviation = deviation!;

    this.setFormValues();

    this.getDetectionWorkcellsList(() => {
      const enabledDetectionWorkcells:Workcell[] = this.detectionWorkcellList.filter(w => !w.disabled);
      if(this.isPopupOpen && this.selectedDeviation == null && enabledDetectionWorkcells.length === 1){
        this.deviationForm.controls['detectionWorkcell'].setValue(enabledDetectionWorkcells[0].id);
        this.deviationForm.controls['detectionWorkcell'].clearValidators();
        this.deviationForm.controls['detectionWorkcell'].updateValueAndValidity();

        this.setDeviationLists(false);
      }

      setTimeout(()=>{
        if(!this.isScanning) { this.firstDeviationInput.focus(); }
      },100);
    });

    this.getMissingPartOptions();

    this.isSavingData = false;

  }

  setFormValues(){
    this.deviationForm.reset();
    this.solveForm.reset();
    this.deviationForm.enable();
    this.solveForm.enable();
    this.locatePopupElement.resetLocation();
    if(this.selectedDeviation != null){
      this.deviationForm.setValue({
        creator:this.selectedDeviation.creator,
        detectionWorkcell : this.selectedDeviation.detectionWorkcellId,
        comment : this.selectedDeviation.description,
        responsible : this.selectedDeviation.responsibleId,
        faultType : this.selectedDeviation.faultTypeId,
        function : this.selectedDeviation.functionGroupId?.toString() || "",
        problemDetail : this.selectedDeviation.problemDetailId?.toString() || "",
        missingParts : this.selectedDeviation.missingParts,
        quantity : this.selectedDeviation.missingPartsAmount,
        severity:this.selectedDeviation.severity,
        solver : this.selectedDeviation.solver,
        solvedComment : this.selectedDeviation.resolutionComment
      });
      this.solveForm.setValue({
        solver:this.selectedDeviation.solver,
        solverId:this.selectedDeviation.solverEmployeeId,
        solveComment : this.selectedDeviation.resolutionComment
      });
      this.selectedFunction = this.selectedDeviation.functionGroupId;
      this.selectedFunctionId = this.selectedDeviation.functionGroupId;
      this.selectedProblemDetail = this.selectedDeviation.problemDetailId;
      this.selectedProblemDetailId = this.selectedDeviation.problemDetailId;

      this.selectedResponsibleId = this.selectedDeviation.responsibleId;

      this.selectedLocations = this.selectedDeviation.locations || null;

      this.findMissingPartOption();

      if(this.isFormDisabled){
        this.deviationForm.disable();
        this.solveForm.disable();
      }else if(!this.isWorkcellCurrentPossibleDetectionWorkcell(this.selectedDeviation.detectionWorkcell!)){
        this.deviationForm.controls['detectionWorkcell'].disable();
      }
    }else{
      this.deviationForm.setValue({
        creator:this.activeAccount?.username!,
        detectionWorkcell : "",
        comment : "",
        responsible : "",
        faultType : "",
        function : "",
        problemDetail : "",
        missingParts : "",
        quantity : "",
        severity:null,
        solver : "",
        solvedComment : ""
      });
      this.selectedFunction = null;
      this.selectedFunctionId = null;
      this.selectedProblemDetail = null;
      this.selectedProblemDetailId = null;
      this.selectedResponsibleId = null;
      this.selectedLocations = [];
      if(this.detectionWorkcellList.length === 1){
        this.deviationForm.controls['detectionWorkcell'].setValue(this.detectionWorkcellList[0].id);
        this.deviationForm.controls['detectionWorkcell'].clearValidators();
        this.deviationForm.controls['detectionWorkcell'].updateValueAndValidity();
      }
    }
    this.setDeviationLists(false);

    this.checkMissingPartInBOM();
    this.checkMissingPartInParts(Number(this.deviationForm.value.missingParts));
  }

  findMissingPartOption(){
    this.selectedMissingPartOption = this.missingPartOptions.find(x => x.partId === this.selectedDeviation?.missingParts)!;
    if(this.selectedMissingPartOption == null){
      this.selectedMissingPartOption = this.bomOptions.find(x => x.partId === this.selectedDeviation?.missingParts)!;
    }
  }

  isWorkcellCurrentPossibleDetectionWorkcell(workcell:Workcell):boolean{
    return this.detectionWorkcellList.find(x => x.id === workcell.id) != null;
  }

  onDeviationSubmit(callback?:(deviation:Deviation) => void) {
    if(!this.deviationForm.valid || this.isSavingData || !this.hasIdentifiedUser() || !this.isFormValid) { return; }

    if(this.selectedDeviation == null){
      this.createDeviation((deviation:Deviation) => { if(callback != null) { callback(deviation);} });
    }
    else{
      this.modifyDeviation((deviation:Deviation) => { if(callback != null) { callback(deviation);} });
    }
  }

  createDeviation(callback?:(deviation:Deviation) => void){
    this.isSavingData = true;
    const newdeviation:Deviation = {
      "description": this.deviationForm.value.comment!,
      "detectionWorkcell":null,
      "detectionWorkcellId":Number(this.deviationForm.value.detectionWorkcell)!,
      "detectionDate": new Date(),
      "resolutionDate": null,
      "productId": this.selectedProduct?.id!,
      "creator":this.activeAccount?.username || "",
      "creatorEmployeeId":this.getUserId()!,
      "missingPartId":null,
      "missingParts":this.deviationForm.value.missingParts?.trim()!,
      "missingPartTitle":this.selectedMissingPartOption?.description || "",
      "missingPartStatus":MissingStatus.ToBeConfirmed,
      "missingPartValidationDate":null,
      "resolutionWorkcell":null,
      "resolutionWorkcellId":null,
      "resolutionComment":"",
      "solver":"",
      "solverEmployeeId":"",
      "severity":this.deviationForm.value.severity,
      "faultTypeId":Number(this.deviationForm.value.faultType) || null,
      "faultType":null,
      "responsibleId":this.selectedResponsibleId || null,
      "responsible":null,
      "responsibleLevel1":null,
      "responsibleLevel1Id":null,
      "responsibleLevel2":null,
      "responsibleLevel2Id":null,
      "responsibleLevel3":null,
      "responsibleLevel3Id":null,
      "functionGroup":null,
      "functionGroupId":this.selectedFunctionId || null,
      "problemDetail":null,
      "problemDetailId":this.selectedProblemDetailId || null,
      "missingPartsAmount":Number(this.deviationForm.value.quantity) || 1,
      "locations":this.selectedLocations || null,
      "operatorId":this.userScanned !== "" ? this.userScanned : null,
      "tagLinks":null,
    }
    this.setTagList(newdeviation);
    this.setDeviationFunctionGroup(newdeviation);
    this.setDeviationProblemDetail(newdeviation);
    this.setDeviationResponsible(newdeviation);
    console.log("new deviation", newdeviation);
    this.httpClient.post<Deviation>(this.apiUrl + 'Deviation', newdeviation).subscribe(
      {
        next: (res) => {
          this.closePopUp(res);
          this.isSavingData = false;
          this.messagePopupEvent.emit({type:"success", title:"SAVED", text:"SAVED CORRECTLY"});

          if(callback){ callback(res);}
        },
        error: (e) => {
          this.messagePopupEvent.emit({type:"error", title:"SOMETHING WRONG", text: e.status.toString().startsWith('4') ? e.error.split(' - ')[0] : "PLS RETRY"});
          console.error(e);
          this.isSavingData = false;
        }
      }
    );
    
  }

  modifyDeviation(callback?:(deviation:Deviation) => void){
    if(this.selectedDeviation == null){ return; }

    this.isSavingData = true;
    this.selectedDeviation.description = this.deviationForm.value.comment!;

    this.selectedDeviation.responsibleId = this.deviationForm.value.responsible != null && this.deviationForm.value.responsible !== "" ? Number(this.deviationForm.value.responsible) : null;
    this.selectedDeviation.responsible = null;

    this.setDeviationFunctionGroup(this.selectedDeviation);
    if(this.problemDetailEnabled){
      this.setDeviationProblemDetail(this.selectedDeviation);
    }

    this.setDeviationResponsible(this.selectedDeviation);

    this.selectedDeviation.faultTypeId = Number(this.deviationForm.value.faultType) || null;
    this.selectedDeviation.faultType = null;

    this.selectedDeviation.missingParts = this.deviationForm.value.missingParts!;
    if(this.faultTypes.find(x=> x.id === this.deviationForm.value.faultType)?.rank1Code !== "006"){
      this.selectedDeviation.missingPartId = null;
    }

    this.selectedDeviation.severity = this.deviationForm.value.severity;

    if(this.quantityVisible){
      this.selectedDeviation.missingPartsAmount = this.deviationForm.value.quantity;
    }

    this.selectedDeviation.operatorId = this.userScanned !== "" ? this.userScanned : null;

    //difference between solved/not solved deviation form
    if((this.selectedDeviation?.detectionWorkcellId == null || this.detectionWorkcellList.findIndex(x => x.id === this.selectedDeviation?.detectionWorkcellId) !== -1)
      && this.deviationForm.value.detectionWorkcell !== undefined){
      this.selectedDeviation.detectionWorkcellId = Number(this.deviationForm.value.detectionWorkcell);
      this.selectedDeviation.detectionWorkcell = this.workcellsList.find(x=>x.id === this.selectedDeviation?.detectionWorkcellId);
    }

    if(this.selectedDeviation.resolutionDate != null){
      this.selectedDeviation.resolutionComment = this.solveForm.value.solveComment || "";
    }

    this.selectedDeviation.locations = this.selectedLocations || null;

    this.selectedDeviation.missingPartTitle = this.selectedMissingPartOption?.description || "";

    this.selectedDeviation.tagLinks = [...this.tagListComponent.tagList];

    this.httpClient.put<Deviation>(this.apiUrl + 'Deviation', this.selectedDeviation).subscribe(
      {
        next: (res) => {
          res.responsible = this.responsibles.find(x=>x.id === res.responsibleId) || null;
          console.log("edited deviation", res);

          //to keep the spinner running while the parent component udpates its data 
          if(!this.showLoadingUntilDataUpdated){
            this.closePopUp(res);
            this.isSavingData = false;
          }else{
            this.closePopupEvent.emit(res);
          }
          this.messagePopupEvent.emit({type:"success", title:"SAVED", text:"SAVED CORRECTLY"});

          if(callback){ callback(res);}
        },
        error: (e) => {
          this.messagePopupEvent.emit({type:"error", title:"SOMETHING WRONG", text:e.status.toString().startsWith('4') ? e.error.split(' - ')[0] : "PLS RETRY"});
          console.error(e);
          this.isSavingData = false;
        }
      }
    );
  }

  getElementParents(elId:number | null, list:any[]):number[]{
    const parents:number[] = [];

    let parent = list.find((x:any)=>x.id === elId);
    while(parent != null && parent.id != null){
      parents.push(parent.id);
      parent = list.find((x:any)=>x.id === parent?.parentId);
    }

    return parents;    
  }

  setDeviationFunctionGroup(deviation:Deviation | null){
    if(deviation == null || (this.selectedDeviation != null && deviation.functionGroupId === this.selectedFunctionId)) { return;}

    const parents:number[] = this.getElementParents(this.selectedFunctionId, this.functionsView);

    deviation.functionGroupId = this.selectedFunctionId;
    deviation.functionGroupLevel1Id = parents[parents.length - 1] || null;
    deviation.functionGroupLevel2Id = parents[parents.length - 2] || null;
    deviation.functionGroupLevel3Id = parents[parents.length - 3] || null;
    deviation.functionGroupLevel4Id = parents[parents.length - 4] || null;

    deviation.functionGroup = null;
    deviation.functionGroupLevel1 = null;
    deviation.functionGroupLevel2 = null;
    deviation.functionGroupLevel3 = null;
    deviation.functionGroupLevel4 = null;
  }

  setDeviationProblemDetail(deviation:Deviation | null){
    if(deviation == null || (this.selectedDeviation != null && deviation.problemDetailId === this.selectedProblemDetailId)) { return;}

    const parents:number[] = this.getElementParents(this.selectedProblemDetailId, this.functionsView);

    deviation.problemDetailId = this.selectedProblemDetailId;
    deviation.problemDetailLevel1Id = parents[parents.length - 1] || null;
    deviation.problemDetailLevel2Id = parents[parents.length - 2] || null;
    deviation.problemDetailLevel3Id = parents[parents.length - 3] || null;
    deviation.problemDetailLevel4Id = parents[parents.length - 4] || null;

    deviation.problemDetail = null;
    deviation.problemDetailLevel1 = null;
    deviation.problemDetailLevel2 = null;
    deviation.problemDetailLevel3 = null;
    deviation.problemDetailLevel4 = null;
  }

  setDeviationResponsible(deviation:Deviation | null){
    if(deviation == null || (this.selectedDeviation != null && deviation.responsibleId === this.selectedResponsibleId)) { return;}

    const parents:number[] = this.getElementParents(this.selectedResponsibleId, this.responsibles);

    deviation.responsibleId = this.selectedResponsibleId;
    deviation.responsibleLevel1Id = parents[parents.length - 1] || null;
    deviation.responsibleLevel2Id = parents[parents.length - 2] || null;
    deviation.responsibleLevel3Id = parents[parents.length - 3] || null;

    deviation.responsible = null;
    deviation.responsibleLevel1 = null;
    deviation.responsibleLevel2 = null;
    deviation.responsibleLevel3 = null;
  }

  setTagList(deviation:Deviation){
    if(deviation == null) {return;}

    const tagListSendable:DeviationTagLink[] = [];

    this.tagListComponent.tagList.forEach(tag => {
      tagListSendable.push(new DeviationTagLink(deviation.id ?? 0, null, tag.tagId));
    });

    deviation.tagLinks = tagListSendable;
  }

  getUserId(){
    return this.activeAccount?.idTokenClaims?.['mailNickName']?.toString() || "";
  }

  getWorkcellsList(){
    this.workcellsList = [];

      this.selectedProduct?.locations.forEach(location => {
        location.currentProductionLine?.productionZones.forEach(zone => {
          zone?.workcells.forEach(workcell => {
            this.workcellsList.push(workcell);
          });
        });
      });
  }

  getDetectionWorkcellsList( callback?:() => void){
    if(this.selectedProduct == null || this.selectedProduct.id == null || this.currentFactoryId == null || this.currentLineId == null) { return;}

    this.detectionWorkcellList = [];
    this.isLoadingDetectionWorkcells = true;

    this.productService.getProductDetectionWorkcells(this.selectedProduct.id, this.currentFactoryId, this.currentLineId).subscribe(
      (res:Workcell[]) => {
        this.detectionWorkcellList = res;
        this.isLoadingDetectionWorkcells = false;
        if (this.selectedDeviation?.detectionWorkcell?.id != null){
          const currentDetectionWorkcell = this.detectionWorkcellList.find(x => x.id === this.selectedDeviation?.detectionWorkcell?.id);
          if (currentDetectionWorkcell){
            this.selectedDeviation.detectionWorkcell.disabled = currentDetectionWorkcell.disabled;
          }
        }

        if(this.selectedDeviation != null && !this.isWorkcellCurrentPossibleDetectionWorkcell(this.selectedDeviation.detectionWorkcell!)) {
          this.deviationForm.controls['detectionWorkcell'].disable();
        } else if(!this.isFormDisabled) {
          this.deviationForm.enable();
        }

        if(callback){ callback();}
      }
    );
  }

  getMissingPartOptions(){
    if(this.selectedProduct == null || this.selectedProduct.id == null || this.currentFactoryId == null) { return;}

    const lang = localStorage.getItem('language') ?? Language.English;;

    this.productService.getProductParts(this.selectedProduct.id, this.currentFactoryId, lang).subscribe({
      next: (res:any) => {
        this.bomOptions = res.bomParts;
        this.missingPartOptions = res.missingParts;

        let mp:PartViewModel = this.missingPartOptions.find(x => x.partId === this.deviationForm.value.missingParts)!;
        if(mp == null){
          mp = this.bomOptions.find(x => x.partId === this.deviationForm.value.missingParts)!;
        }
      
        if(mp != null){
          this.selectedMissingPartOption = mp;
        }
        else{
          this.selectedMissingPartOption = null;
        }
      
        this.checkMissingPartInBOM();
        this.checkMissingPartInParts(Number(this.deviationForm.value.missingParts));

        if(localStorage.getItem('currentDeviation')){
          this.findMissingPartOption();
        }
      }
    });
  }

  setDeviationLists(clearField = true){
    if(this.deviationForm.controls['detectionWorkcell'].disabled && this.selectedDeviation != null){
      this.getFaultTypes(this.selectedDeviation.detectionWorkcellId);
      this.setFunctionList(this.selectedDeviation.detectionWorkcellId);
    }else{
      this.getFaultTypes(Number(this.deviationForm.value.detectionWorkcell));
      this.setFunctionList(Number(this.deviationForm.value.detectionWorkcell));
    }
    this.setResponsibles(clearField);
  }

  getFaultTypes(workcellId:number){
    if(workcellId == null || Number.isNaN(workcellId) || workcellId === 0){
      this.faultTypes = [];
      this.faultTypesLoaded = true;
      return;
    };

    this.faultTypesLoaded = false;
    this.httpClient.get<FaultType[]>(this.apiUrl + 'FaultType/ByWorkcellId/' + workcellId).subscribe(
      (res: any) => {
        this.faultTypes = res.sort((a:FaultType, b:FaultType) => (a.rank2Code < b.rank2Code) ? -1 : 1);
        this.faultTypesLoaded = true;
        if (this.selectedDeviation?.faultType?.id != null && !this.faultTypes.find(x => x.id === this.selectedDeviation?.faultType?.id)) {
          this.selectedDeviation.faultType.disabled = true;
        }

        if(this.isMissingPartType() && this.selectedDeviation != null &&
           this.deviationForm.value.missingParts !== this.selectedDeviation.missingParts && !this.deviationForm.controls['missingParts'].dirty){
          this.deviationForm.controls['missingParts'].setValue(this.selectedDeviation.missingParts!);
          this.checkMissingPartInBOM();
          this.checkMissingPartInParts(Number(this.selectedDeviation.missingParts));
        }
      }
    );
  }

  setFunctionList(detectionWorkcellId:number){
    if(detectionWorkcellId === 0 || detectionWorkcellId == null) { return;}

    if(this.currentLineId === -1 || this.functionsViewOfLine == null){
      this.getFunctionsList(detectionWorkcellId);
    }
    else{
      this.functionsView = this.functionsViewOfLine;
    }
  }

  showHistory(){
    this.showDeviationHistory = !this.showDeviationHistory;

    if(this.showDeviationHistory){
      if(this.selectedDeviation == null || this.selectedDeviation.id == null || this.translate.currentLang == null) { return;}

      this.loadingDeviationHistory = true;
      this.errorMessage = "";
      this.httpClient.get<DeviationHistory[]>(this.apiUrl + 'DeviationHistory/ByDeviationId/' + this.selectedDeviation?.id + '/' + this.translate.currentLang).subscribe(
        {
          next: (res) => {
            this.loadingDeviationHistory = false;
            this.deviationHistories = res;
          },
          error: (e) => {
            if(e.status === 404 || e.status === 403){
              this.deviationHistories = [];
              this.loadingDeviationHistory = false;
              this.errorMessage = "NO HISTORY AVAILABLE";
            }
          }
        }
      );
    }
  }
  getShowHistoryText(){
    if(!this.showDeviationHistory){ 
      return "SEE HISTORY";
    }
    else { 
      return "HIDE HISTORY";
    }
  }
  getHistoryValue(value:string, property:string){
    if(property === "MissingPartStatus") {
      return value === MissingStatus[MissingStatus.ToBeConfirmed] ? "NOT CONFIRMED" : "CONFIRMED";
    }

    if(value == null || value === "") { return "ø";}

    return value;
  }
  translateHistoryValue(property:string){
    if(property === "MissingPartStatus"){ return true;}

    return false;
  }

  isHistoryValueDate(property:string){
    if(property === "ResolutionDate" || property === "MissingPartValidationDate"){ 
      return true;
    }

    return false;
  }

  getPopUpTitle(){
    if(this.selectedDeviation == null){
      return "CREATE DEVIATION" ;}
    else if(this.showDeviationHistory){
      return "HISTORY"}
    else if(this.selectedDeviation?.resolutionDate !== null){
      return "SOLVED DEVIATION INFO";}
    else if(!this.isFormDisabled){
      return "EDIT DEVIATION";}
    else if(this.isFormDisabled){
      return "DEVIATION INFO";}
    else {
      return "";}
  }
  getProductFullName(p:Product | ProductView){
    if(p == null) { return "" };

    return p.fabNumber + p.controlLetter;
  }

  isCreation(actionType:number):boolean{
    if(actionType === ActionType.Creation){
      return true;}
    else{
      return false;}
  }

  isUpdate(actionType:number):boolean{
    if(actionType === ActionType.Update){
      return true;}
    else{
      return false;}
  }

  getHistoryPropertyName(property:string){
    const propertyMapping: { [key: string]: string } = {
      "DetectionWorkcellId": "DETECTION WORKCELL",
      "ResolutionWorkcellId": "RESOLUTION WORKCELL",
      "Comment": "COMMENT",
      "ResponsibleId": "RESPONSIBLE",
      "FaultTypeId": "FAULT TYPE",
      "FunctionGroupId": "FUNCTION",
      "ProblemDetailId": "PROBLEM DETAIL",
      "MissingParts": "MISSING PARTS",
      "Solver": "SOLVER",
      "ResolutionComment": "SOLVE COMMENT",
      "ResolutionDate": "SOLVE DATE",
      "Severity": "SEVERITY",
      "MissingPartStatus": "CONFIRMED AS MISSING PART"
    };

    return propertyMapping[property] || property;
  }

  isSelectedDWInList():boolean{
    return this.selectedDeviation != null && this.selectedDeviation.detectionWorkcell != null && this.detectionWorkcellList.findIndex(x => x.id === this.selectedDeviation?.detectionWorkcell?.id) !== -1;
  }

  //FOR DEVIATION PAGE ONLY
  saveAndSolveDeviation(){
    if(!this.deviationForm.valid || this.isSavingData || !this.hasIdentifiedUser() || !this.isFormValid) { return;}
    this.onDeviationSubmit((deviation:Deviation) => {
      this.solvePopupEvent.emit({'deviation':deviation});
    });
  }

  //FOR MISSING PART PAGE ONLY
  saveAndConfirmDeviation(){
    if(!this.deviationForm.valid || this.isSavingData || !this.hasIdentifiedUser() || !this.isFormValid) { return;}
    this.onDeviationSubmit((deviation:Deviation) => {
      this.confirmPopupEvent.emit({'deviation':deviation, 'product':this.selectedProduct});
    });
  }

  //FAULT TYPE FIELD
  setResponsibles(clearField = true){
    if(this.deviationForm.controls['detectionWorkcell'].disabled && this.selectedDeviation != null){
      this.getResponsibles(this.selectedDeviation.detectionWorkcellId, Number(this.deviationForm.value.faultType));
    }
    else if(this.deviationForm.value.detectionWorkcell != null && this.deviationForm.value.faultType != null && this.deviationForm.value.detectionWorkcell !== "" && this.deviationForm.value.faultType !== ""){
      this.getResponsibles(Number(this.deviationForm.value.detectionWorkcell), Number(this.deviationForm.value.faultType));
      this.deviationForm.controls['responsible'].enable();
    } else {
      this.responsibles = [];
      this.responsiblesLoaded = true;
      this.deviationForm.controls['responsible'].disable();
    }

    if(clearField && this.responsibles.find(x => x.id === this.selectedDeviation?.detectionWorkcellId)){
      this.deviationForm.controls['responsible'].setValue("");
      this.deviationForm.controls['responsible'].clearValidators();
      this.deviationForm.controls['responsible'].updateValueAndValidity();
    }
  }

  getResponsibles(workcellId:number, faultTypeId:number){
    if(workcellId == null || faultTypeId == null) { return;}
    this.responsiblesLoaded = false;
    this.httpClient.get<Responsible[]>(this.apiUrl + "Responsible/ByWorkcellAndFaultType/" + workcellId + "/" + faultTypeId).subscribe(
      (res: Responsible[]) => {
        this.responsibles=res;
        this.responsiblesLoaded = true;
        if (this.selectedDeviation?.responsible?.id != null && !this.responsibles.find(x => x.id === this.selectedDeviation?.responsible?.id)) {
          this.selectedDeviation.responsible.disabled = true;
        }
        if(this.responsibles.length === 1){
          this.deviationForm.controls['responsible'].setValue(this.responsibles[0].id);
          this.deviationForm.controls['responsible'].clearValidators();
          this.deviationForm.controls['responsible'].updateValueAndValidity();
        }
      }
    );
  }

  //LANGUAGE SETTING
  getFaultTypeRank2Name(faultType:FaultType | null){
    if(faultType == null) { return "";}
    if(this.translate.currentLang === Language.French){
      return faultType.rank2NameFR;}
    else if(this.translate.currentLang === Language.Dutch){
      return faultType.rank2NameNL;}
    else{
      return faultType.rank2NameEN;}
  }

  //MISSING PART FIELD
  isMissingPartType(){
    if(this.faultTypes.find(x=> x.id === this.deviationForm.value.faultType)?.rank1Code === "006"){
      this.deviationForm.controls['missingParts'].setValidators([Validators.required, noWhitespaceValidator()]);
      this.deviationForm.controls['missingParts'].updateValueAndValidity();
      return true;
    }
    this.deviationForm.controls['missingParts'].setValue("");
    this.deviationForm.controls['missingParts'].clearValidators();
    this.deviationForm.controls['missingParts'].updateValueAndValidity();
    return false;
  }

  blurMissingPartField(){
    this.inputMpFocused = false;
    this.toBeSelectedMissingPartOption = null;

    if(this.cancelMissingPartBlur){
      this.cancelMissingPartBlur = false;
    }
    else {
      const BOMOptions = this.filteredBOMOptions();
      if(BOMOptions.length === 1){
        this.selectMissingPart(BOMOptions[0]);
      }
    }

    let mp:PartViewModel = this.missingPartOptions.find(x => x.partId === this.deviationForm.value.missingParts)!;
    if(mp == null){
      mp = this.bomOptions.find(x => x.partId === this.deviationForm.value.missingParts)!;
    }

    if(mp != null){
      this.selectedMissingPartOption = mp;
      if(this.selectedDeviation != null){
        this.setQuantityField(this.selectedMissingPartOption.partId, this.selectedDeviation.productId);}
      else if (this.selectedProduct != null){
        this.setQuantityField(this.selectedMissingPartOption.partId, this.selectedProduct.id);}
    }
    else {
      this.selectedMissingPartOption = null;
      this.deviationForm.controls['quantity'].setValue(1);
      this.deviationForm.controls['quantity'].clearValidators();
      this.deviationForm.controls['quantity'].updateValueAndValidity();
    }

    this.checkMissingPartInBOM();
    this.checkMissingPartInParts(Number(this.deviationForm.value.missingParts));
  }

  setQuantityField(partId:string, productId:number){
    if(productId == null || partId == null) { return;}
    this.httpClient.get<any>(this.apiUrl + 'MissingPart/AmountByProductId/' + partId + '/' + encodeURIComponent(productId)).subscribe(
      res => {
        this.deviationForm.controls['quantity'].setValue(res === 0 ? 1 : res);
        this.deviationForm.controls['quantity'].clearValidators();
        this.deviationForm.controls['quantity'].updateValueAndValidity();
      }
    );
  }

  checkMissingPartInBOM(){
    this.isInputMissingPartInBOM = this.filteredBOMOptions().length > 0 || this.deviationForm.value.missingParts === "" ? true : false;
  }

  checkMissingPartInParts(missingPartId:number){
    if(missingPartId == null || missingPartId === 0 || isNaN(missingPartId) || this.translate.currentLang == null) { 
      this.isInputMissingPartInParts = false;
      return;
    }

    this.httpClient.get<PartViewModel>(this.apiUrl + 'MissingPart/Part/' + encodeURIComponent(missingPartId) + '/' + this.translate.currentLang).subscribe(
      res => {
        this.isInputMissingPartInParts = res != null || this.deviationForm.value.missingParts === "" ? true : false;
        if(res != null){
          this.selectMissingPart(res);
        }
      }
    );
  }

  chooseOption(event:Event){
    event.stopPropagation();

    if(this.toBeSelectedMissingPartOption != null){
      this.selectMissingPart(this.toBeSelectedMissingPartOption);
      this.blurMissingPartField();
    }
  }
  selectNextOption(){
    const allOptions: PartViewModel[] = this.filteredMissingPartOptions().concat(this.filteredBOMOptions());

    if(this.toBeSelectedMissingPartOption == null){
      this.toBeSelectedMissingPartOption = allOptions[0];
    }
    else{
      const index:number = allOptions.findIndex(x => x.partId === this.toBeSelectedMissingPartOption?.partId);

      if(index + 1 < allOptions.length){
        this.toBeSelectedMissingPartOption = allOptions[index + 1];
      }
      else{
        this.toBeSelectedMissingPartOption = allOptions[0];
      }
    }
  }
  selectPreviousOption(){
    const allOptions: PartViewModel[] = this.filteredMissingPartOptions().concat(this.filteredBOMOptions());

    if(this.toBeSelectedMissingPartOption == null){
      this.toBeSelectedMissingPartOption = allOptions[allOptions.length - 1];
    }
    else{
      const index:number = allOptions.findIndex(x => x.partId === this.toBeSelectedMissingPartOption?.partId);

      if(index - 1 >= 0){
        this.toBeSelectedMissingPartOption = allOptions[index - 1];}
      else{
        this.toBeSelectedMissingPartOption = allOptions[allOptions.length - 1];}
    }
  }
  isSelectedMissingPartOptionFullyDisplayed():boolean{
    if(this.selectedMissingPartOption != null && this.selectedMissingPartOption.rtAlias != null && this.selectedMissingPartOption.description != null){
      return true;
    } else {
      return false;
    }
  }
  filteredBOMOptions(): PartViewModel[]{
    if(this.deviationForm.value.missingParts == null || this.deviationForm.value.missingParts === "") { return this.bomOptions;}
    return this.bomOptions.filter((x:any) =>
              this.notSensitive(x.partId).includes(this.notSensitive(this.deviationForm.value.missingParts)) ||
              this.notSensitive(x.rtAlias).includes(this.notSensitive(this.deviationForm.value.missingParts)) ||
              this.notSensitive(x.description).includes(this.notSensitive(this.deviationForm.value.missingParts)) ||
              this.notSensitive(x.demarcation).includes(this.notSensitive(this.deviationForm.value.missingParts)));
  }

  filteredMissingPartOptions(): PartViewModel[]{
    if(this.deviationForm.value.missingParts == null || this.deviationForm.value.missingParts === "") { return this.missingPartOptions;}
    return this.missingPartOptions.filter((x:any) =>
              this.notSensitive(x.partId).includes(this.notSensitive(this.deviationForm.value.missingParts)) ||
              this.notSensitive(x.rtAlias).includes(this.notSensitive(this.deviationForm.value.missingParts)) ||
              this.notSensitive(x.description).includes(this.notSensitive(this.deviationForm.value.missingParts)) ||
              this.notSensitive(x.demarcation).includes(this.notSensitive(this.deviationForm.value.missingParts)));
  }
  notSensitive(s:string | null | undefined){
    if(s==null) { return "";}
    return Diacritics.remove(s).toLowerCase();
  }
  selectMissingPart(el:PartViewModel){
    this.deviationForm.controls['missingParts'].setValue(el.partId);
    this.deviationForm.controls['missingParts'].updateValueAndValidity();
    this.selectedMissingPartOption = el;
    this.cancelMissingPartBlur = true;

    this.setDefaultResponsible(el.partId);
  }

  setDefaultResponsible(partId:string){
    this.httpClient.get<number | null>(this.apiUrl + 'MissingPart/DefaultResponsible/' + this.currentFactoryId + "/" + partId)
    .pipe(
      finalize(() => {
        this.setLatestDeviation(partId);
      })
    )
    .subscribe(
      {
        next: (res: number | null) => {
          if(res != null && this.selectedResponsibleId == null){
            this.selectedResponsibleId = res;
          }
        },
        error: (e) => {
          console.error(e);
        }
      }
    );
  }

  setLatestDeviation(partId:string){
    const updateFieldIfNull = <T>(field: T | null, value: T | null, updateAction: (val: T) => void): void => {
      if ((field == null || field === "") && value != null) {
        updateAction(value);
      }
    };

    this.httpClient.get<Deviation>(`${this.apiUrl}Deviation/LatestByPartId/${this.currentFactoryId}/${partId}`).subscribe(
      (res: Deviation) => {
        if(res != null){
          updateFieldIfNull(this.selectedFunctionId, res.functionGroupId, (val) => {
            this.selectedFunction = val;
            this.selectedFunctionId = val;
          });

          updateFieldIfNull(this.selectedProblemDetailId, res.problemDetailId, (val) => {
            this.selectedProblemDetail = val;
            this.selectedProblemDetailId = val;
          });

          updateFieldIfNull(this.selectedResponsibleId, res.responsibleId, (val) => {
            this.selectedResponsibleId = val;
          });

          updateFieldIfNull(this.deviationForm.value.severity, res.severity, (val) => {
            this.deviationForm.controls['severity'].setValue(val)
          });
          
          updateFieldIfNull(this.deviationForm.value.comment, res.description, (val) => {
            this.deviationForm.controls['comment'].setValue(val ?? "")
          });
        }
      }
    );
  }

  selectFirstMissingPart(){
    if(this.filteredMissingPartOptions().length > 0){
      this.selectMissingPart(this.filteredMissingPartOptions()[0])}
    else if(this.filteredBOMOptions().length > 0){
      this.selectMissingPart(this.filteredBOMOptions()[0])}
  }

  //FUNCTION
  getFunctionsList(workcellId:number){
    if(workcellId == null || this.translate.currentLang == null) { return;}
    this.functionsList = [];
    this.functionsLoaded = false;

    this.httpClient.get<FunctionGroupView[]>(this.apiUrl + 'FunctionGroup/ByWorkcellId/' + workcellId + "/" + this.translate.currentLang).subscribe(
      (res: FunctionGroupView[]) => {
        this.functionsLoaded = true;
        this.functionsView = res;

        //LANGUAGE SETTING
        res.forEach(f => {
          if(this.translate.currentLang === Language.French && f!=null && f.id!=null && f.name!=null){
            this.functionsList.push({"id": f.id, "name":f.code.concat(" ").concat(f.name.toString())});
          }
        });

        if(this.selectedDeviation != null){
          if(!this.functionsView.find(x=> x.id === this.selectedDeviation?.functionGroupId)){
            this.deviationForm.controls['function'].setValue("");
            this.deviationForm.controls['function'].clearValidators();
            this.deviationForm.controls['function'].updateValueAndValidity();
          }
          if(!this.functionsView.find(x=> x.id === this.selectedDeviation?.problemDetailId)){
            this.deviationForm.controls['problemDetail'].setValue("");
            this.deviationForm.controls['problemDetail'].clearValidators();
            this.deviationForm.controls['problemDetail'].updateValueAndValidity();
          }
        }
      }
    );
  }

  //TODO check why this.translate.current lang is undefined here
  getProductionLineFunctionList(plId:number){
    if(plId == null || localStorage.getItem('language') == null) { return;}
    
    this.functionsList = [];
    this.lineFunctionsLoaded = false;
    
    this.httpClient.get<FunctionGroupView[]>(this.apiUrl + 'FunctionGroup/ByProductionLineId/' + plId + "/" + localStorage.getItem('language')).subscribe(
      (res: FunctionGroupView[]) => {
        this.lineFunctionsLoaded = true;
        this.functionsViewOfLine = res;
        if(this.deviationForm.value.detectionWorkcell != null && this.deviationForm.value.detectionWorkcell !== ""){
          this.setFunctionList(this.deviationForm.value.detectionWorkcell);
        }
      }
    );
  }

  scrollToFunction(fieldId:string){
    const elementInScrollableDiv = document.getElementById(fieldId);
    const positionFromTopOfScrollableDiv = elementInScrollableDiv?.offsetTop;
    const scrollableDivElement = document.getElementById('deviation-popup-content');

    if(scrollableDivElement && positionFromTopOfScrollableDiv){
      scrollableDivElement.scroll({top: positionFromTopOfScrollableDiv - 80, left:0, behavior:"smooth"});
    }
  }

  getFunctionGroup(id:number):FunctionGroupView{
    return this.functionsView.find((x:FunctionGroupView) => x.id === id)!;
  }

  getResponsibleElement(id:number):Responsible{
    return this.responsibles.find((x:Responsible) => x.id === id)!;
  }

  // getFunctionNameById(functionId:string){
  //   return this.getFunctionGroup(Number(functionId))?.name;
  // }

  // getFunctionNameByIdN(functionId:number){
  //   return this.getFunctionGroup(functionId)?.name;
  // }

  getFunctionFullPath(dev:Deviation){
    if(dev == null) { return "";}
    return (this.getTreeElementName(dev.functionGroupLevel1!) + (dev.functionGroupLevel2 != null ? " > " : "") +
             this.getTreeElementName(dev.functionGroupLevel2!) + (dev.functionGroupLevel3 != null ? " > " : "") +
             this.getTreeElementName(dev.functionGroupLevel3!) + (dev.functionGroupLevel4 !=  null ? " > " : "") +
             this.getTreeElementName(dev.functionGroupLevel4!)).replace(/^ >+/, '').trim();
  }

  getProblemDetailFullPath(dev:Deviation){
    if(dev == null) { return "";}
    return (this.getTreeElementName(dev.problemDetailLevel1!) + (dev.problemDetailLevel2 != null ? " > " : "") +
             this.getTreeElementName(dev.problemDetailLevel2!) + (dev.problemDetailLevel3 != null ? " > " : "") +
             this.getTreeElementName(dev.problemDetailLevel3!) + (dev.problemDetailLevel4 !=  null ? " > " : "") +
             this.getTreeElementName(dev.problemDetailLevel4!)).replace(/^ >+/, '').trim();
  }

  getResponsibleFullPath(dev:Deviation){
    if(dev == null) { return "";}
    return (this.getTreeElementName(dev.responsibleLevel2!) + (dev.responsibleLevel3 !=  null ? " > " : "") +
             this.getTreeElementName(dev.responsibleLevel3!)).replace(/^ >+/, '').trim();
  }

  //LANGUAGE SETTING
  getTreeElementName(f:FunctionGroup | Responsible){
    if(f == null) { return "";}

    if(this.translate.currentLang === Language.French){
      return f?.nameFR;}
    else if(this.translate.currentLang === Language.Dutch){
      return f?.nameNL;}
    else{
      return f?.nameEN;}
  }

  setSelectedFunction(functionId:number){
    this.selectedFunctionId = functionId;
    this.afterTreeInput.nativeElement.focus();
  }

  setSelectedProblemDetail(functionId:number){
    this.selectedProblemDetailId = functionId;
    //to do change this
    this.afterTreeInput.nativeElement.focus();
  }

  setSelectedResponsible(responsibleId:number){
    this.selectedResponsibleId = responsibleId;
    //to do change this
    this.afterTreeInput.nativeElement.focus();
  }

  setWarning(warning:boolean, field:string){
    if(field === "functionField"){
      this.warningFunctionField = warning;}
    if(field === "problemDetailField"){
      this.warningProblemDetailField = warning;}
    if(field === "responsibleField"){
      this.warningResponsibleField = warning;}

    this.isFormValid = !this.warningFunctionField && !this.warningProblemDetailField && !this.warningResponsibleField;
  }

  getValidateFormTooltipText():string{
    if(!this.hasIdentifiedUser()){
      return 'MISSING OPERATOR MESSAGE'}
    if(this.warningFunctionField){
      return 'WRONG FUNCTION FIELD MESSAGE';}
    if(this.warningProblemDetailField){
      return 'WRONG PROBLEM DETAIL FIELD MESSAGE';}
    if(this.warningResponsibleField){
      return 'WRONG RESPONSIBLE FIELD MESSAGE';}

    return '';
  }

  //USER SCANNED
  hasIdentifiedUser():boolean{
    return this.isGenericUser ? this.userScanned !== "" : true;
  }
}
